import * as type from '../type/notificationModalType';

const initialState = {
  isOpen: false,
  notificationType: ''
};
const notificationModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_NOTIFICATION_IS_OPEN:
      return {
        ...state,
        isOpen: action.payload
      };
    case type.RESET_NOTIFICATION_IS_OPEN:
      return {
        ...state,
        isOpen: false
      };
    case type.SET_NOTIFICATION_TYPE:
      return {
        ...state,
        notificationType: action.payload
      };
    case type.RESET_NOTIFICATION_TYPE:
      return {
        ...state,
        notificationType: ''
      };
    default:
      return state;
  }
};

export default notificationModalReducer;

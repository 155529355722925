import * as type from '../type/notificationModalType';

export const setNotificationIsOpen = (data) => {
  return {
    type: type.SET_NOTIFICATION_IS_OPEN,
    payload: data
  };
};

export const setNotificationReset = () => {
  return {
    type: type.RESET_NOTIFICATION_IS_OPEN
  };
};

export const setNotificationType = (data) => {
  return {
    type: type.SET_NOTIFICATION_TYPE,
    payload: data
  };
};

export const setNotificationTypeReset = (data) => {
  return {
    type: type.RESET_NOTIFICATION_TYPE,
    payload: data
  };
};

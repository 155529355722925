import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Form } from 'formik';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { gridSpacing } from 'store/constant';
import { useEffect } from 'react';

const SuperAdminFaqForm = ({
  errors,
  handleBlur,
  handleChange,
  // handleSubmit,
  // isSubmitting,
  touched,
  values,
  setFieldValue,
  isLoadingFaq,
  faqDetails
}) => {
  const theme = useTheme();
  useEffect(() => {
    if (faqDetails) {
      // console.log('🚀 ~ useEffect ~ faqDetails:', faqDetails);
      setFieldValue('ques', faqDetails?.question ? faqDetails?.question : '');
      setFieldValue('ans', faqDetails?.answer ? faqDetails?.answer : '');
    }
  }, [faqDetails, setFieldValue]);
  return (
    <>
      <Form>
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              FAQs Details
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth error={Boolean(touched.ques && errors.ques)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel tmlFor="outlined-adornment-email-login"> Question *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.ques}
                    name="ques"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" Question*"
                    placeholder="Write your question"
                    inputProps={{}}
                    autoComplete="off"
                    fullWidth
                  />
                  {touched.ques && errors.ques && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.ques}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl
                  fullWidth
                  sx={{ ...theme.typography.customInput }}
                  className="ticket_detailstextarea"
                  error={Boolean(touched.ans && errors.ans)}
                >
                  <InputLabel htmlFor="outlined-adornment-email-login"> Answer *</InputLabel>
                  <OutlinedInput
                    multiline
                    rows={2}
                    fullWidth
                    variant="outlined"
                    name="ans"
                    label="Answer *"
                    value={values?.ans}
                    onChange={handleChange}
                    placeholder="Write your answer"
                  />
                  {/* {touched.ans && errors.ans && (
                    <FormHelperText
                      id="standard-weight-helper-text-email-login"
                      error
                      variant="caption"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.ans}
                    </FormHelperText>
                  )} */}
                  {touched.ans && errors.ans && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.ans}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* submit */}

          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isLoadingFaq}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingFaq ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default SuperAdminFaqForm;

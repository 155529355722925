import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useQueryClient } from '@tanstack/react-query';
import LocationAdd from 'views/pages/location/add';

const LocationDialog = ({ open, handleClose }) => {
  const queryClient = useQueryClient();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        // maxWidth={'100% !important'}
        // fullScreen
        className="new-modal-start"
      >
        <DialogContent>
          <LocationAdd open={open} handleClose={handleClose} queryClient={queryClient} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LocationDialog;

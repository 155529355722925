import React from 'react';
import { Form, Formik } from 'formik';

import FaqFilterForm from './FaqFilterForm';

const FaqFilter = ({ setFilterObj, filterObj, isExistingFilter, permissionsList }) => {
  const faqFilters = permissionsList?.view?.filters ?? [];

  return (
    <Formik
      initialValues={{
        status: 'All',
        question: ''
      }}
      onSubmit={(values) => {
        setFilterObj({
          status: values?.status === 'All' ? '' : values?.status,
          question: values?.question || null
        });
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
        <Form>
          <FaqFilterForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            setTouched={setTouched}
            resetForm={resetForm}
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            isExistingFilter={isExistingFilter}
            permissionsList={permissionsList}
            faqFilters={faqFilters}
          />
        </Form>
      )}
    </Formik>
  );
};

export default FaqFilter;

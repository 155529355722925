import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
// import { useNavigate } from 'react-router-dom';
import { endpoints } from 'endpoints/endpoints';

export const useSubscriptionsAdd = async (data) => {
  // const res = await axiosInstance.post(`${endpoints.Subscriptionss}/create`, data);
  const res = await axiosInstance.post(`${endpoints.subscriptions}/save`, data);
  return res;
};

export const useGetSubscriptionsList = ({ limit, page, filters, sort }) => {
  return useQuery({
    queryKey: ['getSubscriptionsData', limit, page, ...Object.values(filters), ...Object.values(sort)],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.subscriptions}/${page}/${limit}`, {
          filters: filters,
          sort: sort
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    staleTime: 0,
    // enabled: Boolean(localStorage.getItem('userJwtToken')),
    //enabled: Boolean(limit),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useGetSubscriptionsAllList = () => {
  return useQuery({
    queryKey: ['getSubscriptionsDataAllList'],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.subscriptions}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// for Editing Organizations

export const useGetSubscriptionsById = ({ id }) => {
  return useQuery({
    queryKey: ['getSubscriptionsDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        // .get(`${endpoints.Subscriptionss}/${id}`)
        .get(`${endpoints.subscriptions}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//Submiting Edited Organisations to List

export const useGetSubscriptionsEditedList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        // .patch(`${endpoints.Subscriptionss}/${data.id}`, data)
        .put(`${endpoints.subscriptions}/${data.id}`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getSubscriptionsDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getSubscriptionsData');
      // queryClient.refetchQueries('getSubscriptionsDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

///Subscriptions update Status

export const useSubscriptionsStatusUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.subscriptions}/${data?.id}`, { status: data?.status })
        // .patch(`${endpoints.Subscriptionss}/status/${data?.id}`, { enabled: data?.enabled })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            // setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries('getSubscriptionsData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

////Subscriptions User List

export const useGetUserSubscriptionsList = ({ limit, sortOrder, sortField, current, previous, first, last, SubscriptionsId }) => {
  return useQuery({
    queryKey: ['getSubscriptionsUserData', limit, sortOrder, sortField, current, previous, first, last, SubscriptionsId],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.users}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          Subscriptions: SubscriptionsId
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log('Error to load user data from Subscriptions', e);
        });
    },
    staleTime: 0,
    // enabled: Boolean(limit) && Boolean(sortOrder),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//// user add

export const useSubscriptionsUserAdd = async (data) => {
  try {
    const res = await axiosInstance.post(`${endpoints.users}/create`, data);
    // console.log('Add enterprice res', data, res);
    return res;
  } catch (error) {
    toastConfig.type = 'error';
    setToast(toastConfig, error?.response?.data?.error);
    // console.log('Error to add user', data, error);
  }
};
///User get By id

export const useGetSubscriptionsUserById = ({ id }) => {
  return useQuery({
    queryKey: ['getSubscriptionsUserDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.users}/findById/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

////user Update

export const useGetSubscriptionsUserUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      // console.log('Incoming Data', data, data?.data, data?.id);
      return await axiosInstance
        .patch(`${endpoints.users}/${data?.id} `, data?.data) //http://localhost:5500/api/v1/users/1AUvcUGNMqamBG2Wjd5Q
        .then((res) => {
          // console.log('User Update Response', res);
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          // console.log('Error to update user', error);
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getSubscriptionsUserDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getSubscriptionsUserData');
      // queryClient.refetchQueries('getDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

export const useGetSubscriptionsMachineLocations = (data) => {
  return useQuery({
    queryKey: ['getSubscriptionsLoactionDetailsDataList', data?.recycler?.id, data?.recycler?.locationId, data?.SubscriptionsId],
    queryFn: async () => {
      return await axiosInstance
        .post(`/${endpoints.subscriptions}/locations`, {
          recycler: {
            id: data?.recycler?.id,
            locationId: data?.recycler?.locationId
          },
          SubscriptionsId: data?.SubscriptionsId
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(data?.recycler?.id) && Boolean(data?.recycler?.locationId) && Boolean(data?.SubscriptionsId),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Subscriptions Machines

export const useSubscriptionsMachines = ({ limit, sortOrder, sortField, current, previous, first, last, Subscriptions }) => {
  return useQuery({
    queryKey: ['getSubscriptionsMachinesData', limit, sortOrder, sortField, current, previous, first, last, Subscriptions],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machines}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          Subscriptions: Subscriptions
        })
        .then((res) => {
          if (res?.status == 200) {
            // console.log('🚀 ~ .then ~ resmmmmmmmmm:', res);
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log('Error to load user data from Subscriptions', e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField),
    refetchOnMount: false,
    staleTime: 0,

    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/// Delete Api
export const useDeleteSubscriptions = async (id) => {
  const res = await axiosInstance.delete(`${endpoints.machineModal}/${id}`);
  return res;
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ModifyPermission from './modify-permission';
import SearchPermissionForm from 'ui-component/search-permission-form';
import { Formik } from 'formik';
import { Divider } from '@mui/material';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useGetLeftPanelList } from 'hooks/useControlPanelHooks';

function SidePanelControl({ userTypes, orgTypes }) {
  const { user, org } = useSelector((state) => state.controlPanelReducer);

  const { data: routingList, isLoading: isLoadingRouting } = useGetLeftPanelList({
    orgType: org === 'Service_Provider' ? 'ServiceProvider' : org !== 'Super_Admin' ? org : 'SuperAdmin',
    userType: user === 'Main POC' ? 'POC' : 'POC'
  });

  const dispatch = useDispatch();

  const initialValues = {
    user: user || '',
    org: org || ''
  };

  const validationSchema = Yup.object().shape({
    user: Yup.string().required('User Type is required'),
    org: Yup.string().required('Organization Type is required')
  });

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch({ type: 'SET_USER', payload: values?.user });
          dispatch({ type: 'SET_ORG', payload: values?.org });
        }}
      >
        {(formikProps) => (
          <SearchPermissionForm
            {...formikProps}
            showUserField={true}
            showOrgaizationField={true}
            userTypes={userTypes}
            orgTypes={orgTypes}
          />
        )}
      </Formik>

      <Divider sx={{ my: 2 }} />

      <ModifyPermission routingList={routingList} isLoadingRouting={isLoadingRouting} />
    </Fragment>
  );
}

SidePanelControl.propTypes = {
  userTypes: PropTypes.array.isRequired, // Array of user types, required
  orgTypes: PropTypes.array.isRequired // Array of organization types, required
};

export default SidePanelControl;

import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, List, ListItemButton, ListItemText, Skeleton, Typography, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
// import MenuCard from './MenuCard';
import { drawerWidth } from 'store/constant';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { MENU_OPEN } from 'store/actions/actions';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window, routingList, isLoadingRouting }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const defaultId = customization.defaultId ? customization.defaultId : 'default';
  // Checking For
  useEffect(() => {
    if (routingList) {
      if (!routingList?.data) {
        // console.log('routingList No data Key : ', customization);
        dispatch({ type: MENU_OPEN, id: defaultId });
        navigate('/dashboard/' + defaultId);
      } else {
        const isOpen = customization?.isOpen.length > 0 ? customization?.isOpen : defaultId;
        if (isOpen === 'default') {
          // dispatch({ type: MENU_OPEN, id: defaultId });
          // navigate('/dashboard/' + isOpen);
        } else {
          if (!routingList?.data?.includes(isOpen[0])) {
            dispatch({ type: MENU_OPEN, id: defaultId });
            navigate('/dashboard/' + defaultId);
          }
        }
      }
    }
  }, [routingList]);

  const drawer = () => {
    return (
      <>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
            <LogoSection />
          </Box>
        </Box>
        <BrowserView>
          {isLoadingRouting ? (
            <>
              {[1, 3, 4, 5, 6].map((i) => (
                <List key={i}>
                  <ListItemButton>
                    <ListItemText
                      primary={
                        <Typography color="inherit">
                          <Skeleton />
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </List>
              ))}
            </>
          ) : (
            <PerfectScrollbar
              component="div"
              style={{
                height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                paddingLeft: '16px',
                paddingRight: '16px'
              }}
            >
              {routingList?.orgType === 'SuperAdmin' ? (
                <MenuList routingList={routingList?.data} />
              ) : routingList?.orgType === 'Enterprise' ? (
                <MenuList routingList={routingList?.data} />
              ) : routingList?.orgType === 'Recycler' ? (
                <MenuList routingList={routingList?.data || []} />
              ) : routingList?.orgType === 'Retailer' ? (
                <MenuList routingList={routingList?.data || []} />
              ) : routingList?.orgType === 'Reseller' ? (
                <MenuList routingList={routingList?.data || []} />
              ) : routingList?.orgType === 'ServiceProvider' ? (
                <MenuList routingList={routingList?.data || []} />
              ) : (
                ''
              )}

              {/* <MenuCard />
              <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
                <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
              </Stack> */}
            </PerfectScrollbar>
          )}
        </BrowserView>
        <MobileView>
          <Box sx={{ px: 2 }}>
            {/* <MenuList /> */}

            {/* <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
            <Chip label={process.env.REACT_APP_VERSION} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
          </Stack> */}
            {isLoadingRouting ? (
              <>
                {[1, 3, 4, 5, 6].map((i) => (
                  <List key={i}>
                    <ListItemButton>
                      <ListItemText
                        primary={
                          <Typography color="inherit">
                            <Skeleton />
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                ))}
              </>
            ) : (
              <>
                {routingList?.orgType === 'SuperAdmin' ? (
                  <MenuList routingList={routingList?.data} />
                ) : routingList?.orgType === 'Enterprise' ? (
                  <MenuList routingList={routingList?.data} />
                ) : routingList?.orgType === 'Recycler' ? (
                  <MenuList routingList={routingList?.data || []} />
                ) : routingList?.orgType === 'Retailer' ? (
                  <MenuList routingList={routingList?.data || []} />
                ) : routingList?.orgType === 'Reseller' ? (
                  <MenuList routingList={routingList?.data || []} />
                ) : routingList?.orgType === 'ServiceProvider' ? (
                  <MenuList routingList={routingList?.data || []} />
                ) : (
                  ''
                )}
              </>
            )}
          </Box>
        </MobileView>
      </>
    );
  };

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px'
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer()}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;

import React from 'react';
import { useEffect, useState } from 'react';

import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';

import { useMutation } from '@tanstack/react-query';
import MainCard from 'ui-component/cards/MainCard';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLocationAdd } from 'hooks/useLocationHooks';
import { setToast, toastConfig } from 'utils/commonUtil';
import ResellerLocationForm from '../form';

const ResellerAddLocation = () => {
  const [isLoading, setLoading] = useState(true);
  const { mutate: addLocation, isPending: isLoadingLocation } = useMutation({
    mutationFn: useLocationAdd
  });
  const [locationLatLng, setLocationLatLng] = useState({
    lat: '',
    lng: '',
    label: ''
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <FormView />
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add Location"
            obj={{ title: 'All Locations', title2: 'Add Location', url: '/dashboard/enterprise/location' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                location_name: '',

                location: '',
                placeId: '',
                latitude: '',
                longitude: '',
                tax: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                location_name: Yup.string()
                  .max(50, 'Location Name must be at most 50 characters')
                  .required('Location Name is required')
                  .trim(),

                location: Yup.string().required('Address is required'),
                tax: Yup.number()
                  .positive('Tax must contain a positive number')
                  .max(100, 'Maximum allowed value is 100')
                  .test('is-decimal', 'Tax must have at most two decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value))
                  .when('areSeatsLimited', {
                    is: 'yes',
                    then: Yup.number().required().typeError('Tax must be a number'),
                    otherwise: Yup.number().notRequired().typeError('Tax must be a number')
                  })
                  .required('Tax is required')
              })}
              onSubmit={async (value, { resetForm }) => {
                if (!value.location || !value.placeId) {
                  toastConfig.type = 'error';
                  setToast(toastConfig, 'Oops! Enter your full address');
                  return;
                }
                const submitData = {
                  name: value?.location_name.trim(),
                  address: {
                    fullAddress: value?.location.trim(),
                    placeId: value?.placeId,
                    coOrdinates: {
                      latitude: value?.latitude,
                      longitude: value?.longitude
                    }
                  },
                  taxAmount: value?.tax
                };

                addLocation(submitData, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.success);

                      resetForm({});
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                <ResellerLocationForm
                  btnName="Add"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  isLoadingLocation={isLoadingLocation}
                  setFieldValue={setFieldValue}
                  id={''}
                  locationLatLng={locationLatLng}
                  setLocationLatLng={setLocationLatLng}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default ResellerAddLocation;

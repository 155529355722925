import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
// import { useNavigate } from 'react-router-dom';
import { endpoints } from 'endpoints/endpoints';

// export const useRetailerAdd = () => {
//   // const navigate = useNavigate();
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: async (data) => {
//       return await axiosInstance
//         .post(`${endpoints.retailers}/create`, data)
//         .then((res) => {
//           if (res?.status == 200) {
//             toastConfig.type = 'success';
//             setToast(toastConfig, res?.data.success);
//           } else {
//             toastConfig.type = 'error';
//             setToast(toastConfig, error?.response?.data?.error);
//           }
//           // navigate(`/dashboard/organizations`);
//           return res;
//         })
//         .catch((error) => {
//           toastConfig.type = 'error';
//           setToast(toastConfig, error?.response?.data?.error);
//         });
//     },
//     onSuccess: ({ data }) => {
//       queryClient.refetchQueries('getRetailerData');
//       return data;
//     },
//     onError: ({ error }) => {
//       return error;
//     },
//     onSettled: ({ data }) => {
//       return data;
//     }
//   });
// };
export const useRetailerAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.retailers}/create`, data);
  return res;
};
export const useGetRetailerList = ({ limit, sortOrder, sortField, current, previous, first, last }) => {
  return useQuery({
    queryKey: ['getRetailerData', limit, sortOrder, sortField, current, previous, first, last],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.retailers}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// for Editing Organizations

export const useGetRetailerById = ({ id }) => {
  return useQuery({
    queryKey: ['getRetailerDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.retailers}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//Submiting Edited Organisations to List

export const useGetRetailerEditedList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.retailers}/${data.id}`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getRetailerData');
      // queryClient.refetchQueries('getRetailerDetailsById');
      queryClient.refetchQueries({ queryKey: ['getRetailerDetailsById'], type: 'active' });
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Retailer update Status

export const useRetailerStatusUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.retailers}/status/${data?.id}`, { enabled: data?.enabled })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries('getRetailerData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

/// Retailer user listing

export const useGetRetailerUserList = ({ limit, sortOrder, sortField, current, previous, type }) => {
  return useQuery({
    queryKey: ['getRetailerUserData', limit, sortOrder, sortField, current, previous, type],
    queryFn: async () => {
      return await axiosInstance
        .get(
          `${endpoints.users}/all?previous=${previous}&current=${current}&sortField=${sortField}&sortOrder=${sortOrder}&limit=${limit}&type=${type}`
        )

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useGetRetailerAllList = () => {
  return useQuery({
    queryKey: ['getRetailerAllData'],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.retailers}`)

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

////

export const useGetRetailerMachineLocations = (data) => {
  return useQuery({
    queryKey: ['getRetailerLoactionDetailsDataList', data?.recycler?.id, data?.recycler?.locationId, data?.retailerId],
    queryFn: async () => {
      return await axiosInstance
        .post(`/${endpoints.retailers}/locations`, {
          recycler: {
            id: data?.recycler?.id,
            locationId: data?.recycler?.locationId
          },
          retailerId: data?.retailerId
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(data?.recycler?.id) && Boolean(data?.recycler?.locationId) && Boolean(data?.retailerId),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

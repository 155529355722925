import { Grid, Typography, Button, Divider, Box, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContactDialog from 'ui-component/contact-support-dialog';
// import ActiveSupportDialog from 'ui-component/active-support-ticket-dialog';
import { useGetFaqAllList } from 'hooks/useSuperAdminFaqHooks';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
// import ActiveSupportDialog from 'ui-component/active-support-ticket-dialog';
// import { borderBottom } from '@mui/system';
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
const Faq = () => {
  // const [showAllTickets, setShowAllTickets] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  // const [activeTicketsModal, setActiveTicketsModal] = useState(false);
  // const [statusType, setStatusType] = useState('');
  // console.log('🚀 ~ Faq ~ statusType:', statusType);
  const { data: allfaqList, isPending: isAllFaqListLoading } = useGetFaqAllList();
  // console.log('🚀 ~ Faq ~ allfaqList:', allfaqList);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const navigate = useNavigate();
  const toggleAccordion = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };
  // const [activeTickets] = useState([
  //   {
  //     id: 1,
  //     title: 'SUP-T-001',
  //     status: 'pending'
  //   },

  //   { id: 2, title: 'SUP-T-002', status: 'resolved' },
  //   { id: 3, title: 'SUP-T-003', status: 'pending' },
  //   { id: 4, title: 'SUP-T-004', status: 'pending' },
  //   { id: 5, title: 'SUP-T-002', status: 'resolved' },
  //   { id: 6, title: 'SUP-T-002', status: 'resolved' },
  //   { id: 7, title: 'SUP-T-002', status: 'resolved' }
  // ]);

  // const displayedTickets = showAllTickets ? activeTickets : activeTickets.slice(0, 2);

  return (
    <>
      <BreadcrumbsForPage
        name="All FAQs"
        obj={{
          title: 'All FAQs',
          title2: '',
          url: ''
        }}
      />

      <MainCard>
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center', marginBottom: '28px' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              FAQ
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: '14px',
                color: '#757575',
                mb: 4,
                marginBottom: 0
              }}
            >
              Need answers? Find them here...
            </Typography>
          </Grid>

          {isAllFaqListLoading ? (
            <>
              {' '}
              <Grid item xs={12} md={12} sx={{ margin: '0 auto' }}>
                <Skeleton variant="rounded" width="100%" height={50} />
              </Grid>
              <Grid item xs={12} md={12} sx={{ margin: '0 auto' }}>
                <Skeleton variant="rounded" width="100%" height={50} />
              </Grid>
              <Grid item xs={12} md={12} sx={{ margin: '0 auto' }}>
                <Skeleton variant="rounded" width="100%" height={50} />
              </Grid>
            </>
          ) : allfaqList?.data === undefined || allfaqList?.data?.length == 0 ? (
            <StyledGridOverlay>
              <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(24 31.67)">
                    <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                    <path
                      className="ant-empty-img-1"
                      d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                    />
                    <path
                      className="ant-empty-img-2"
                      d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                    />
                    <path
                      className="ant-empty-img-3"
                      d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                    />
                  </g>
                  <path
                    className="ant-empty-img-3"
                    d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                  />
                  <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                    <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                    <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                  </g>
                </g>
              </svg>
              <Box sx={{ mt: 1 }}>No data found!</Box>
            </StyledGridOverlay>
          ) : (
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  height: '400px', // Adjust the height as needed
                  overflow: 'auto',
                  border: '1px solid #ccc', // Optional: Add a border for better visuals
                  borderRadius: '4px',
                  padding: '8px',
                  backgroundColor: '#f9f9f9' // Optional background color
                }}
              >
                {allfaqList?.data?.map((item, index) => (
                  /* <Accordion key={index} sx={{ mb: 1 }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                      sx={{
                        // backgroundColor: index % 2 === 0 ? '#eefae6' : '#f9f9f9', // Alternate background for clarity
                        padding: '12px 16px',
                        backgroundColor: '#eefae6'
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        {item.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: '#eefae6' }}>
                      <Typography variant="body2" sx={{ color: '#616161' }}>
                        {item.answer}
                      </Typography>
                    </AccordionDetails>
                    <AccordionDetails sx={{ backgroundColor: '#eefae6', padding: '8px' }}>
                      <Typography variant="body2" sx={{ color: '#616161' }}>
                        {item.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion> */
                  <>
                    <div key={index}>
                      <Box
                        onClick={() => toggleAccordion(index)}
                        style={{
                          padding: '19px 16px',
                          backgroundColor: '#eefae6',
                          borderBottom: '1px solid #ccc',
                          // cursor: 'pointer',
                          // borderBottom: index === allfaqList?.length - 1 ? 'none' : '1px solid #ccc',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between', // Ensures question and icon are spaced apart
                          cursor: 'pointer'
                        }}
                      >
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                          {item.question}
                        </Typography>
                        <span
                          style={{
                            transform: expandedIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <ExpandMoreIcon />
                        </span>
                      </Box>

                      {expandedIndex === index && (
                        <div
                          style={{
                            padding: '24px 16px',
                            backgroundColor: '#fff',
                            border: 'none'
                            // borderTop: '1px solid #ccc'
                            // color: '#616161'
                          }}
                        >
                          {item.answer}
                        </div>
                      )}
                    </div>
                  </>
                ))}
              </Box>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              mt: 0
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Didn&#39;t find what you were looking for?
            </Typography>
            <div className="faq-btn">
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
                style={{
                  color: 'white',
                  minWidth: '200px',
                  margin: '0px auto'
                }}
                onClick={() => {
                  // console.log('alerttt');
                  setContactModal(!contactModal);
                }}
              >
                Contact Support
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider />
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'center',
            mt: 2
          }}
        >
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Find All Your Support Tickets
          </Typography>
          <div className="faq-btn">
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
              style={{
                color: 'white',
                minWidth: '200px',
                margin: '0px auto'
              }}
              onClick={() => {
                navigate('/dashboard/support-ticket');
              }}
            >
              Support Ticket
            </Button>
          </div>
        </Grid>

        {/* <Grid container spacing={gridSpacing}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mt: 2, mb: 2 }}>
              Active Support Tickets
            </Typography>

            <div className="show-ticket">
              {displayedTickets.map((ticket) => (
                <Box
                  key={ticket.id}
                  sx={{
                    padding: '8px',

                    borderRadius: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '180px',
                    position: 'relative',
                    height: '50px'
                  }}
                  onClick={() => {
                    // if (ticket.status === 'pending') {
                    //   setActiveTicketsModal(!activeTicketsModal);
                    // }
                    setStatusType(ticket.status);
                    setActiveTicketsModal(!activeTicketsModal);
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      borderRadius: '10px',
                      border: '1px solid',
                      borderColor: ticket.status === 'pending' ? 'red' : '#6fd74b'
                      // ...(ticket.status === 'pending' && {
                      //   animation: 'blink 1s infinite',
                      //   '@keyframes blink': {
                      //     '0%': { borderColor: 'red' },
                      //     '50%': { borderColor: 'transparent' },
                      //     '100%': { borderColor: 'red' }
                      //   }
                      // })
                    }}
                  ></Box>

                  <Box
                    sx={{
                      position: 'relative',
                      color: '#000',
                      fontSize: '13px'
                    }}
                  >
                    {ticket.title}
                  </Box>
                </Box>
              ))}

              <Button variant="text" onClick={() => setShowAllTickets(!showAllTickets)} sx={{ mt: 0 }}>
                {showAllTickets ? 'Show Less' : 'Show All'}
              </Button>
            </div>
          </Grid>
        </Grid> */}
      </MainCard>
      {contactModal && <ContactDialog open={contactModal} setOpen={setContactModal} title={'Support Type'} />}

      {/* {activeTicketsModal && (
        <ActiveSupportDialog
          open={activeTicketsModal}
          setOpen={setActiveTicketsModal}
          title={'Support Ticket No. :-'}
          statusType={statusType}
        />
      )} */}
    </>
  );
};

export default Faq;

import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';

import { useState } from 'react';
import { useEffect } from 'react';
import FormView from 'ui-component/cards/Skeleton/FormView';

import { useEnterpriseAdd } from 'hooks/useEnterpriseHooks';
import { useMutation } from '@tanstack/react-query';
import { setToast, toastConfig } from 'utils/commonUtil';
import ResellerEnterpriseForm from '../form';

const ResellerAddEnterprise = () => {
  const [isLoading, setLoading] = useState(true);
  // const userData = JSON.parse(localStorage.getItem('userData'));
  // const { mutate: addEnterprise, isPending: isLoadingEnterprise } = useEnterpriseAdd();
  //mutate
  const { mutate: addEnterprise, isPending: isLoadingEnterprise } = useMutation({
    mutationFn: useEnterpriseAdd
  });
  // const queryClient = useQueryClient();
  const phoneRegex = RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);

  //const phoneRegex = RegExp(/^\+?(\d{1,4})?[-.\s]?((\d{1,3})?[-.\s]?\d{1,4})[-.\s]?\d{1,4}[-.\s]?\d{1,4}( ?(ext\.?|x) ?\d{1,5})?$/);

  const validationSchema = {
    EnterpriseName: Yup.string()
      .max(50, 'Enterprise Name must be at most 50 characters')
      .required('Enterprise Name is required')
      .trim()
      .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

      .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Enterprise Name should not be in any special character'),

    EnterpriseWeb: Yup.string().url('Please enter a valid Enterprise Website Address'),

    associateEmpEmail: Yup.string().email('Invalid email address').required('Employee Email is required'),
    associateEmpAdd: Yup.string().max(150, 'Employee Address must be of 30 characters').trim().required('Employee Address is required'),
    associateEmpPhone: Yup.string()
      .required('Employee Phone is required')

      .matches(phoneRegex, 'Employee Phone is not valid')
      .max(15, 'Employee Phone maximum number should be of 15 characters')
      .trim(),
    // phone('US',true,'Must be a valid Employee Phone Number. ex.: +1 212-456-7890'),

    title: Yup.string().max(5, 'Must be of 5 characters').required('Prefix is required').trim(),
    firstName: Yup.string()
      .max(50, 'Employee First Name must be at most 50 characters')
      .required('Employee First Name is required')
      .trim()
      .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

      .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not be in any special character'),

    lastName: Yup.string()
      .max(50, 'Employee Last Name must be at most 50 characters')
      .required('Employee Last Name is required')
      .trim()
      .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

      .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not be in any special character'),

    designation: Yup.string()
      .max(50, ' Employee Title must be of 50 characters')
      .required('Employee Title is required')

      .trim(),

    secondaryTitle: Yup.string().required('Prefix is required').trim(),
    secondaryFirstName: Yup.string()
      .required('Employee First Name is required')
      .max(50, 'Employee First Name must be at most 50 characters')
      //
      .trim()
      .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

      .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not be in any special character'),

    secondaryLastName: Yup.string()
      .required('Employee Last Name is required')
      .max(50, 'Employee Last Name must be at most 50 characters')
      //
      .trim()
      .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

      .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not be in any special character'),

    secondaryDesignation: Yup.string().required('Employee Title is required').max(50, ' Employee Title must be of 50 characters').trim(),

    SecondaryEmpPhone: Yup.string()
      .required('Employee Phone is required')

      .matches(phoneRegex, 'Employee Phone is not valid')
      .max(15, 'Employee Phone maximum number should be of 15 characters')
      .trim(),

    SecondaryEmpAdd: Yup.string().required('Employee Address is required').max(150, 'Employee Address must be of 30 characters').trim(),
    SecondaryEmpEmail: Yup.string().required('Employee Email is required').email('Invalid email address'),
    users: Yup.array().of(
      Yup.object().shape(
        {
          firstName: Yup.string()
            .max(30, 'Must be of 30 characters')
            .when(['rfidCode', 'email', 'lastName'], {
              is: (dep_1, dep_2, dep_3) => dep_1 || dep_2 || dep_3,
              then: Yup.string().required('First Name is required'),
              otherwise: Yup.string().notRequired()
            }),
          lastName: Yup.string()
            .max(30, 'Must be of 30 characters')
            .when(['rfidCode', 'email', 'firstName'], {
              is: (dep_1, dep_2, dep_3) => dep_1 || dep_2 || dep_3,
              then: Yup.string().required('Last Name is required'),
              otherwise: Yup.string().notRequired()
            }),

          email: Yup.string()
            .email('Invalid email address')
            .when(['rfidCode', 'firstName', 'lastName'], {
              is: (dep_1, dep_2, dep_3) => dep_1 || dep_2 || dep_3,
              then: Yup.string().required('Email is required'),
              otherwise: Yup.string().notRequired()
            }),

          rfidCode: Yup.string().when(['email', 'firstName', 'lastName'], {
            is: (dep_1, dep_2, dep_3) => dep_1 || dep_2 || dep_3,
            then: Yup.string().max(30, 'Must be of 30 characters').required('RFID Code is required'),
            otherwise: Yup.string().notRequired()
          })
        },
        [
          ['rfidCode', 'email'],
          ['rfidCode', 'firstName'],
          ['email', 'firstName'],
          ['rfidCode', 'lastName'],
          ['email', 'lastName'],
          ['firstName', 'lastName']
        ]
      )
    ),
    locationPickUp: Yup.array().min(1, 'At least one location is required').required('Select Location is required ')
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <FormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add Enterprise Organization"
            obj={{ title: 'All Enterprise Organizations ', title2: 'Add Enterprise Organization', url: '/dashboard/enterprise' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                associateEmpEmail: '',
                associateEmpPhone: '',

                associateEmpAdd: '',

                EmpFullAddress: '',
                EmpCity: '',
                EmpState: '',
                EmpCountry: '',
                EmpPostal_Code: '',

                SecondaryEmpEmail: '',
                SecondaryEmpPhone: '',

                SecondaryEmpAdd: '',

                EnterpriseName: '',

                EnterpriseWeb: '',

                recycler: '',

                title: '',
                firstName: '',
                lastName: '',
                designation: '',

                secondaryTitle: '',
                secondaryFirstName: '',
                secondaryLastName: '',
                secondaryDesignation: '',
                isChecked: false,
                users: [
                  { firstName: '', email: '', rfidCode: '', lastName: '' },
                  { firstName: '', email: '', rfidCode: '', lastName: '' },
                  { firstName: '', email: '', rfidCode: '', lastName: '' },
                  { firstName: '', email: '', rfidCode: '', lastName: '' }
                ],
                locationPickUp: [],
                placeId: '',
                latitude: '',
                longitude: '',
                rfIdCodePrimary: '',
                rfIdCodeSecondary: '',
                submit: null
              }}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={async (value, { resetForm }) => {
                const _usr = value.users.filter((i) => i.firstName != '');

                const _location = value.locationPickUp
                  .filter((item) => item?.id !== '')
                  .map((item) => ({
                    value: item.id,
                    isPrimary: item?.isPrimary ? item?.isPrimary : false // Mark the first location as primary, adjust this logic if needed
                    // You can include other properties from `item` if necessary
                  }));

                const submitData = {
                  name: value?.EnterpriseName.trim(),
                  website: value?.EnterpriseWeb,
                  isChecked: value?.isChecked,
                  mainPOC: {
                    firstName: value?.firstName.trim(),
                    lastName: value?.lastName.trim(),
                    title: value?.designation,
                    prefix: value?.title,
                    email: value?.associateEmpEmail.trim(),
                    address: value?.associateEmpAdd.trim(),
                    phone: value?.associateEmpPhone.trim(),
                    rfidCode: value?.rfIdCodePrimary.trim()
                  },
                  secondaryPOC: {
                    firstName: value?.secondaryFirstName.trim(),
                    lastName: value?.secondaryLastName.trim(),
                    title: value?.secondaryDesignation,
                    prefix: value?.secondaryTitle,
                    email: value?.SecondaryEmpEmail.trim(),
                    address: value?.SecondaryEmpAdd.trim(),
                    phone: value?.SecondaryEmpPhone.trim(),
                    rfidCode: value?.rfIdCodeSecondary.trim()
                  },
                  users: _usr,

                  locations: _location
                };

                addEnterprise(submitData, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.success);
                      // queryClient.refetchQueries('getEnterpriseData');
                      resetForm({});
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                setValues,
                setFieldError,
                setTouched
              }) => (
                <ResellerEnterpriseForm
                  btnName="Add"
                  errors={errors}
                  setError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  isLoadingEnterprise={isLoadingEnterprise}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  id={''}
                  setTouched={setTouched}
                  // userDetails ={userDetails}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default ResellerAddEnterprise;

import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { Form } from 'formik';
import React from 'react';
import { useEffect } from 'react';
// import { useState } from 'react';
import { gridSpacing } from 'store/constant';
import SettingsView from 'ui-component/cards/Skeleton/SettingsView';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import { useGetAllMachinePrice } from 'hooks/useSettingsHooks';
// import { useGetAllMachinePrice } from 'hooks/useSettingsHooks';
const MachineBasePriceForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  isLoadingMachinePrice

  // isLoadingMachineModel, setFieldValue
}) => {
  // const [loading, setLoading] = useState(false);
  const { data: addMachinePriceDetails, isPending: isMachinePriceDetails } = useGetAllMachinePrice({
    id: '6751558ae312a8253a026097'
  });
  useEffect(() => {
    if (addMachinePriceDetails) {
      setFieldValue('enterpriseMachinePrice', addMachinePriceDetails?.enterpriseBasePrice);
      setFieldValue('retailerMachinePrice', addMachinePriceDetails?.retailerBasePrice);
      setFieldValue('perDriveCost', addMachinePriceDetails?.perDriveCost);
    }
  }, [setFieldValue, addMachinePriceDetails]);

  const theme = useTheme();
  return (
    <>
      {isMachinePriceDetails ? (
        <>
          <SettingsView />
        </>
      ) : (
        <>
          <Form>
            <Grid item xs={12}>
              <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
                Machine Base Price & Per Drive Cost
              </Typography>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography component="h5" variant="h4" sx={{ mb: 2 }}>
                    This option defines the machine base price and per drive cost details.
                  </Typography>
                  <Grid
                    container
                    className="machine-base-price"
                    //   className="settings-row-page"
                  >
                    <Grid container spacing={gridSpacing}>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <FormControl
                          fullWidth
                          error={touched.enterpriseMachinePrice && Boolean(errors.enterpriseMachinePrice)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-email-login">Enterprise Machine Price * </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-secondary-address"
                            type="number"
                            value={values.enterpriseMachinePrice}
                            name="enterpriseMachinePrice"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="enterpriseMachinePrice *"
                            placeholder="Enter your enterprise machine"
                            inputProps={{}}
                            autoComplete="off"
                          />
                          {touched.enterpriseMachinePrice && errors.enterpriseMachinePrice && (
                            <FormHelperText
                              error
                              // id="standard-weight-helper-text-email-login"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.enterpriseMachinePrice}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <FormControl
                          fullWidth
                          error={touched.retailerMachinePrice && Boolean(errors.retailerMachinePrice)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-email-login">Retailer Machine Price * </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-secondary-address"
                            type="number"
                            value={values.retailerMachinePrice}
                            name="retailerMachinePrice"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="retailerMachinePrice *"
                            placeholder="Enter your retailer machine"
                            inputProps={{}}
                            autoComplete="off"
                          />
                          {touched.retailerMachinePrice && errors.retailerMachinePrice && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.retailerMachinePrice}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <FormControl
                          fullWidth
                          error={touched.perDriveCost && Boolean(errors.perDriveCost)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-email-login"> Per Drive Cost * </InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-secondary-address"
                            type="number"
                            value={values.perDriveCost}
                            name="perDriveCost"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="perDriveCost *"
                            placeholder="Enter your drive cost "
                            inputProps={{}}
                            autoComplete="off"
                          />
                          {touched.perDriveCost && errors.perDriveCost && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.perDriveCost}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  mt: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center'
                }}
              >
                <Box sx={{ display: 'inline-block' }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isLoadingMachinePrice}
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{
                        color: 'white',
                        minWidth: '200px',
                        margin: '0px auto'
                      }}
                    >
                      {isLoadingMachinePrice ? (
                        <>
                          <CircularProgress
                            sx={{
                              color: 'white',
                              height: 20,
                              width: 20
                            }}
                          />
                        </>
                      ) : (
                        `Save`
                      )}
                    </Button>
                  </AnimateButton>
                </Box>
              </Box>
            </Grid>
          </Form>
        </>
      )}
    </>
  );
};

export default MachineBasePriceForm;

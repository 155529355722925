import React from 'react';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import PanelTabs from './panel-tabs';

// Main Component
const ControlPanel = () => {
  return (
    <>
      <BreadcrumbsForPage name="Control Panel" obj={{ title: 'Control Panel', url: '' }} />
      <PanelTabs />
    </>
  );
};

export default ControlPanel;

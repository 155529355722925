import { Formik } from 'formik';
import React, { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import SuperAdminFaqForm from '../form';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import { useFaqUpdate, useGetFaqDetailsId } from 'hooks/useSuperAdminFaqHooks';
const FaqEdit = () => {
  const [isLoading, setLoading] = useState(true);
  let { id } = useParams();
  const { data: faqDetails } = useGetFaqDetailsId({
    id: id.slice(1)
  });
  const { mutate: updateFaq, isPending: isLoadingFaq } = useFaqUpdate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <OrganizationFormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name="Edit FAQs"
            obj={{
              title: 'All FAQs',
              title2: 'Edit FAQs',
              url: '/dashboard/faq/super-admin'
            }}
          />
          <MainCard>
            <Formik
              initialValues={{
                ques: '',
                ans: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                ques: Yup.string().required('Question is required').trim(),
                ans: Yup.string().required('Answer is required').trim()
              })}
              onSubmit={async (value) => {
                const submitData = {
                  question: value?.ques,
                  answer: value?.ans,
                  id: id.slice(1)
                };
                updateFaq(submitData);
                // console.log('🚀 ~ onSubmit={ ~ submitData:', submitData);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                setValues,
                setTouched
              }) => (
                <>
                  <SuperAdminFaqForm
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    setTouched={setTouched}
                    faqDetails={faqDetails}
                    isLoadingFaq={isLoadingFaq}
                  />
                </>
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default FaqEdit;

import { Grid, Skeleton } from '@mui/material';
import React from 'react';

const FilterSkeleton = () => {
  return (
    <>
      <Grid key={'machinFilter'} container spacing={2} sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}>
        <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
          <Skeleton variant="rounded" width={210} height={50} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
          <Skeleton variant="rounded" width={210} height={50} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
          <Skeleton variant="rounded" width={210} height={50} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
          <Skeleton variant="rounded" width={210} height={50} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
          <Skeleton variant="rounded" width={210} height={50} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
          <Skeleton variant="rounded" width={210} height={50} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
          <Skeleton variant="rounded" width={210} height={50} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
          <Skeleton variant="rounded" width={210} height={50} />
        </Grid>
      </Grid>
    </>
  );
};

export default FilterSkeleton;

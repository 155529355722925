import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Form } from 'formik';

function SearchPermissionForm({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  handleSubmit,
  showUserField = false,
  showOrgaizationField = false,
  showScreenField = false,
  resetForm,
  routeList = [],
  userTypes = [],
  orgTypes = []
}) {
  const theme = useTheme();

  return (
    <Form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={{
          xs: 1, // Reduced spacing for smaller screens
          sm: 2,
          md: 3,
          lg: 4,
          xl: 4 // Standard spacing for larger screens
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={{
              xs: 1, // Reduced spacing for smaller screens
              sm: 2,
              md: 3,
              lg: 4,
              xl: 4
            }}
          >
            {/* User Type Field */}
            {showUserField && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl sx={{ ...theme.typography.customInput }} error={Boolean(touched.user && errors.user)} fullWidth>
                  <InputLabel htmlFor="outlined-adornment-user">User Type</InputLabel>
                  <Select
                    id="outlined-adornment-user"
                    inputProps={{ 'aria-label': 'User Type' }}
                    name="user"
                    value={values.user}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="User Type"
                  >
                    {userTypes?.map((user, index) => (
                      <MenuItem key={index} value={user}>
                        {user?.replaceAll('_', ' ')}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.user && errors.user && <FormHelperText>{errors.user}</FormHelperText>}
                </FormControl>
              </Grid>
            )}

            {/* Organization Type Field */}
            {showOrgaizationField && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl sx={{ ...theme.typography.customInput }} error={Boolean(touched.org && errors.org)} fullWidth>
                  <InputLabel htmlFor="outlined-adornment-org">Organization Type</InputLabel>
                  <Select
                    id="outlined-adornment-org"
                    inputProps={{ 'aria-label': 'Organization Type' }}
                    name="org"
                    value={values.org}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Organization Type"
                  >
                    {orgTypes?.map((org, index) => (
                      <MenuItem key={index} value={org}>
                        {org?.replaceAll('_', ' ')}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.org && errors.org && <FormHelperText>{errors.org}</FormHelperText>}
                </FormControl>
              </Grid>
            )}

            {/* Screen Type Field */}
            {showScreenField && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl sx={{ ...theme.typography.customInput }} error={Boolean(touched.screen && errors.screen)} fullWidth>
                  <InputLabel htmlFor="outlined-adornment-screen">Screen Type</InputLabel>
                  <Select
                    id="outlined-adornment-screen"
                    inputProps={{ 'aria-label': 'Screen Type' }}
                    name="screen"
                    value={values.screen}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Screen Type"
                  >
                    {routeList?.map((route, index) => (
                      <MenuItem key={index} value={route}>
                        {route?.replaceAll('_', ' ')}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.screen && errors.screen && <FormHelperText>{errors.screen}</FormHelperText>}
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Buttons */}
        <Grid item xs={12}>
          <Grid
            container
            spacing={{
              xs: 1, // Reduced spacing for smaller screens
              sm: 2,
              md: 3,
              lg: 4,
              xl: 4
            }}
            justifyContent="flex-end"
          >
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Button fullWidth variant="outlined" type="reset" color="error" onClick={resetForm}>
                Clear All
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Button fullWidth variant="contained" type="submit" color="primary" sx={{ color: '#fff' }}>
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

SearchPermissionForm.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.object,
  values: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showUserField: PropTypes.bool,
  showOrgaizationField: PropTypes.bool,
  showScreenField: PropTypes.bool,
  resetForm: PropTypes.func.isRequired,
  routeList: PropTypes.array,
  userTypes: PropTypes.array,
  orgTypes: PropTypes.array
};

export default SearchPermissionForm;

import React from 'react';
import { Formik, Form, FieldArray } from 'formik';
import {
  Grid,
  Button,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  CardHeader,
  CardContent,
  Paper
} from '@mui/material';
import * as Yup from 'yup';
import { useTheme } from '@emotion/react';

function ModifyPermission({ addScreenList, editScreenList, filtersScreenList, modalScreenList, isLoadingScreenList }) {
  const theme = useTheme();

  // Define initial values
  const initialValues = {
    add: addScreenList || [],
    edit: editScreenList || [],
    filters: filtersScreenList || [],
    modal: modalScreenList || []
  };

  // Define validation schema
  const validationSchema = Yup.object({
    add: Yup.array().of(
      Yup.object({
        id: Yup.string().required('ID is required'),
        label: Yup.string().required('Label is required')
      })
    ),
    edit: Yup.array().of(
      Yup.object({
        id: Yup.string().required('ID is required'),
        label: Yup.string().required('Label is required')
      })
    ),
    filters: Yup.array().of(
      Yup.object({
        id: Yup.string().required('ID is required'),
        label: Yup.string().required('Label is required')
      })
    ),
    modal: Yup.array().of(
      Yup.object({
        id: Yup.string().required('ID is required'),
        label: Yup.string().required('Label is required')
      })
    )
  });

  // Render a single section (e.g., add, edit, filters, modal)
  const renderSection = (name, values, arrayHelpers, handleBlur, handleChange, touched, errors) => (
    <Paper variant="outlined" sx={{ mb: 2 }}>
      <CardHeader title={`${name.charAt(0).toUpperCase() + name.slice(1)} Options`} />
      <Divider />
      <CardContent
        sx={{
          p: { xs: 1, sm: 2 }
        }}
      >
        <Grid
          container
          spacing={{
            xs: 1, // Reduced spacing for smaller devices
            sm: 2,
            md: 3,
            lg: 4,
            xl: 4
          }}
        >
          {values[name].map((item, index) => (
            <Grid
              container
              item
              spacing={{
                xs: 1, // Reduced spacing for smaller devices
                sm: 2,
                md: 3,
                lg: 4,
                xl: 4
              }}
              key={index}
              sx={{ alignItems: 'center', display: 'flex' }}
            >
              <Grid item xs={12} sm={5} md={5} lg={5.5} xl={5.5}>
                <FormControl
                  sx={{ ...theme.typography.customInput }}
                  error={Boolean(touched[name]?.[index]?.id && errors[name]?.[index]?.id)}
                  fullWidth
                >
                  <InputLabel htmlFor={`${name}-${index}-id`}>ID *</InputLabel>
                  <OutlinedInput
                    id={`${name}-${index}-id`}
                    type="text"
                    value={item.id}
                    onChange={(e) => {
                      const updatedItem = { ...item, id: e.target.value };
                      arrayHelpers.replace(index, updatedItem);
                    }}
                    onBlur={handleBlur}
                    label="ID *"
                  />
                  {touched[name]?.[index]?.id && errors[name]?.[index]?.id && (
                    <FormHelperText error>{errors[name]?.[index]?.id}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={5} lg={5.5} xl={5.5}>
                <FormControl
                  sx={{ ...theme.typography.customInput }}
                  error={Boolean(touched[name]?.[index]?.label && errors[name]?.[index]?.label)}
                  fullWidth
                >
                  <InputLabel htmlFor={`${name}-${index}-label`}>Label *</InputLabel>
                  <OutlinedInput
                    id={`${name}-${index}-label`}
                    type="text"
                    value={item.label}
                    onChange={(e) => {
                      const updatedItem = { ...item, label: e.target.value };
                      arrayHelpers.replace(index, updatedItem);
                    }}
                    onBlur={handleBlur}
                    label="Label *"
                  />
                  {touched[name]?.[index]?.label && errors[name]?.[index]?.label && (
                    <FormHelperText error>{errors[name]?.[index]?.label}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={1} xl={1}>
                <Button disabled={index === 0} fullWidth color="error" onClick={() => arrayHelpers.remove(index)} variant="outlined">
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="outlined" onClick={() => arrayHelpers.push({ id: '', label: '' })}>
              Add More
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Paper>
  );

  // Handle form submission
  const handleSubmit = (values) => {
    console.log('Submitted Values:', values);
    // Add API call or save logic here
  };

  return isLoadingScreenList ? (
    <div>Loading...</div>
  ) : (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, handleBlur, handleChange, touched, errors }) => (
        <Form>
          {['add', 'edit', 'filters', 'modal'].map((section) => (
            <FieldArray
              key={section}
              name={section}
              render={(arrayHelpers) => renderSection(section, values, arrayHelpers, handleBlur, handleChange, touched, errors)}
            />
          ))}
          {/* Calibrate Button */}
          <div style={{ marginTop: '1rem', textAlign: 'center' }}>
            <Button type="submit" variant="contained" color="primary" sx={{ color: '#fff' }}>
              Calibrate
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ModifyPermission;

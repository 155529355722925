// import { useRouter } from 'next/navigation'

import { useMutation, useQuery } from '@tanstack/react-query';
import axiosInstance from 'utils/axiosInstance';
import { endpoints } from 'endpoints/endpoints';

import { setToast, toastConfig } from 'utils/commonUtil';
// import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

// import { toast } from 'react-toastify'

// import axiosInstance from '@/utils/axiosInstance'

// export const useGetOrganizationList = () => {
//   return useQuery({
//     queryKey: ['getAllOrganizationList'],
//     queryFn: async () => {
//       return await axiosInstance

//         // .get(`/trucks-trailers`)
//         .then((res) => {
//           if (res?.status === 200) {
//             return res?.data;
//           } else {
//             return [];
//           }
//         })
//         .catch((e) => {
//           console.log(e)
//         });
//     },

//     onSuccess: ({ data }) => {
//       return data;
//     }
//   });
// };

//// All Location List

export const useGetLocationAllLists = () => {
  return useQuery({
    queryKey: ['getLocationAllData'],
    queryFn: async () => {
      return await axiosInstance
        // .post(`${endpoints.locations}`)
        .post(`${endpoints.location}`)

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/////

// export const useFileUpload = () => {
//   return useMutation({
//     mutationFn: async (data) => {
//       return await axiosInstance
//         .post(`v1/files/upload`, data, {
//           headers: {
//             'Content-Type': 'multipart/form-data' // This is optional; Axios sets it automatically for FormData
//           }
//         })
//         .then((res) => {
//           if (res?.status == 200) {
//             toastConfig.type = 'success';
//             setToast(toastConfig, res?.data.message, {
//               autoClose: 3000
//             });
//           } else {
//             setToast.error(res?.data.error, {
//               autoClose: 3000
//             });
//           }

//           return res;
//         })
//         .catch((error) => {
//           setToast.error(error?.response.data.error, {
//             autoClose: 3000
//           });
//         });
//     },
//     onSuccess: ({ data }) => {
//       return data;
//     },
//     onError: ({ error }) => {
//       return error;
//     },
//     onSettled: ({ data }) => {
//       return data;
//     }
//   });
// };

export const useFileUpload = () => {
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .post(
          `v1/files/upload`,
          data,

          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data?.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, 'Something went wrong!');
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

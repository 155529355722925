import { Dialog, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
// import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import TicketReasonFilterForm from './TicketReasonFilterForm';
const TicketReason = ({ open, handleCloseFilter, setFilterObj, filterObj, isExistingFilter }) => {
  return (
    <>
      <Dialog
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        onClose={handleCloseFilter}
        open={open}
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          Apply your filter
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseFilter}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>
        <Formik
          initialValues={{
            ticketreason: '',
            ticketstatus: ''
          }}
          // validationSchema={Yup.object().shape({})}
          onSubmit={async (value) => {
            // console.log('dsd', value);

            setFilterObj({
              ticketreason: value?.ticketreason,
              ticketstatus: value?.ticketstatus
            });

            handleCloseFilter();
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
            <Form>
              <TicketReasonFilterForm
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                setTouched={setTouched}
                resetForm={resetForm}
                filterObj={filterObj}
                handleCloseFilter={handleCloseFilter}
                setFilterObj={setFilterObj}
                isExistingFilter={isExistingFilter}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default TicketReason;

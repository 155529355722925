import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import userReducer from './userReducer';
import errorReducer from './errorReducer';
import headerUserTypeReducer from './headerUserTypeReducer';
import notificationModalReducer from './notificationModalReducer';
import loadingReducer from './loadingReducer';
import controlPanelReducer from './constrolPanelReducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  userRoot: userReducer,
  errorRoot: errorReducer,
  customization: customizationReducer,
  headerUserType: headerUserTypeReducer,
  notificationModal: notificationModalReducer,
  loadingReducer: loadingReducer,
  controlPanelReducer: controlPanelReducer
});

export default reducer;

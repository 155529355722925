import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

/**
 * This function is used to add an invoice by a super admin using a POST request to a specific
 * endpoint.
 * @param data - The `data` parameter in the `useInvoiceAddBySuperAdmin` function likely contains the
 * information needed to create a new invoice. This could include details such as the invoice number,
 * customer information, items/services provided, amounts, dates, and any other relevant data required
 * for generating the invoice.
 * @returns The function `useInvoiceAddBySuperAdmin` is returning the response object `res` from the
 * POST request made to the endpoint `${endpoints.invoice}/create` using the data provided.
 */
export const useInvoiceAddBySuperAdmin = async (data) => {
  const res = await axiosInstance.post(`${endpoints.invoice}/create`, data);
  return res;
};

/**
 * The function `useGetInvoiceListBySuperAdmin` fetches a list of invoices based on specified
 * parameters for a super admin user.
 * @returns The `useGetInvoiceListBySuperAdmin` function returns a custom hook that uses `useQuery`
 * from a library (possibly React Query) to fetch invoice data by a super admin. The function takes in
 * parameters like limit, sortOrder, sortField, current, previous, first, last, and event to construct
 * the queryKey and make a POST request to the specified endpoint using axiosInstance.
 */
export const useGetInvoiceListBySuperAdmin = ({
  limit,
  sortOrder,
  sortField,
  current,
  previous,
  first,
  last,
  event,
  status,
  fromMonth,
  toMonth,
  inv_num,
  ticket_num,
  raised_org,
  raised_org_type,
  amt_value,
  amt_type
}) => {
  return useQuery({
    queryKey: [
      'getInvoiceDataBySuperAdmin',
      limit,
      sortOrder,
      sortField,
      current,
      previous,
      first,
      last,
      event,

      status,
      fromMonth,
      toMonth,
      inv_num,
      ticket_num,
      raised_org,
      raised_org_type,
      amt_value,
      amt_type
    ],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.invoice}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          event: event,
          searchParams: {
            status: status ? status : null,
            raisedDate: {
              from: fromMonth ? fromMonth : null,
              to: toMonth ? toMonth : null
            },
            amount: {
              value: amt_value ? amt_value : null,
              type: amt_type ? amt_type : null
            },
            invoiceNumber: inv_num ? inv_num : null,
            raisedToOrg: {
              type: raised_org_type ? raised_org_type : null,
              id: raised_org ? raised_org : null
            },
            ticket: ticket_num ? ticket_num : null
          }
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField) && Boolean(event),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useGetInvoiceDetailsByIdSuperAdmin` fetches invoice details by ID for super admins
 * using a query hook.
 * @returns The `useGetInvoiceDetailsByIdSuperAdmin` function returns a query object that fetches
 * invoice details by ID for a super admin user. It uses the `useQuery` hook from an unspecified
 * library and makes an asynchronous request to the server using Axios. If the request is successful
 * (status code 200), it returns the data from the response. If there is an error or the status code is
 */
export const useGetInvoiceDetailsByIdSuperAdmin = ({ id }) => {
  return useQuery({
    queryKey: ['getInvoiceByIdSuperAdmin', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.invoice}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useInvoiceUpdateBySuperAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      // console.log('🚀 ~ mutationFnddddddd: ~ data:', data);
      return await axiosInstance
        .patch(`${endpoints.invoice}/update/${data.id} `, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getInvoiceByIdSuperAdmin'], type: 'active' });
      // queryClient.refetchQueries('getRecycleData');
      // queryClient.refetchQueries('getRecycleDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};
/**
 * This function updates the status of an invoice by a super admin using a PATCH request.
 * @param data - The `data` parameter is an object that contains the following properties:
 * @returns The function `useInvoiceStatusUpdateBySuperAdmin` is returning the response from the PATCH
 * request made to update the invoice status.
 */
export const useInvoiceStatusUpdateBySuperAdmin = async (data) => {
  const res = await axiosInstance.patch(`${endpoints.invoice}/${data?.id}`, {
    status: data?.status
  });
  return res;
};

/**
 * The function `useGetInvoiceListByRecycler` fetches invoice data based on specified parameters for a
 * specific recycler.
 * @returns The function `useGetInvoiceListByRecycler` returns a custom hook that uses `useQuery` from
 * a library like React Query. This hook fetches invoice data by sending a POST request to a specific
 * endpoint with the provided parameters such as limit, sortOrder, sortField, current, previous, first,
 * last, event, recyclerId, and type. The function returns the data received from
 */
export const useGetInvoiceListByRecycler = ({
  limit,
  sortOrder,
  sortField,
  current,
  previous,
  first,
  last,
  event,
  recyclerId,
  type,
  status,
  fromMonth,
  toMonth,
  inv_num,
  ticket_num,
  raised_org,
  raised_org_type,
  amt_value,
  amt_type
}) => {
  return useQuery({
    queryKey: [
      'getInvoiceDataByRecycler',
      limit,
      sortOrder,
      sortField,
      current,
      previous,
      first,
      last,
      event,
      recyclerId,
      type,
      status,
      fromMonth,
      toMonth,
      inv_num,
      ticket_num,
      raised_org,
      raised_org_type,
      amt_value,
      amt_type
    ],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.invoice}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          event: event,
          recyclerId: recyclerId,
          type: type,
          searchParams: {
            status: status ? status : null,
            raisedDate: {
              from: fromMonth ? fromMonth : null,
              to: toMonth ? toMonth : null
            },
            amount: {
              value: amt_value ? amt_value : null,
              type: amt_type ? amt_type : null
            },
            invoiceNumber: inv_num ? inv_num : null,
            raisedToOrg: {
              type: raised_org_type ? raised_org_type : null,
              id: raised_org ? raised_org : null
            },
            ticket: ticket_num ? ticket_num : null
          }
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField) && Boolean(event),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useGetInvoiceDetailsByIdRecycler` fetches invoice details by ID using axios and
 * returns the data.
 * @returns The `useGetInvoiceDetailsByIdRecycler` custom hook is being returned. This hook uses
 * `useQuery` from a library like React Query to fetch invoice details by ID from an API endpoint. It
 * takes an `id` parameter, constructs a query key based on the ID, and defines a query function that
 * makes a GET request to the API endpoint to fetch the invoice details.
 */
export const useGetInvoiceDetailsByIdRecycler = ({ id }) => {
  return useQuery({
    queryKey: ['getInvoiceByIdRecycler', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.invoice}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * This function updates the status and payment details of an invoice by sending a PATCH request to the
 * server.
 * @param data - The `data` parameter in the `useInvoiceStatusUpdateByRecycler` function contains the
 * following properties:
 * @returns The function `useInvoiceStatusUpdateByRecycler` is returning the response from the PATCH
 * request made using axiosInstance to update the invoice status and payment details based on the data
 * provided.
 */
export const useInvoiceStatusUpdateByRecycler = async (data) => {
  const res = await axiosInstance.patch(`${endpoints.invoice}/${data?.id}`, {
    status: data?.status,
    payment: {
      method: data?.paymentMethod,
      notes: data?.paymentNote,
      date: data?.date
    }
  });
  return res;
};

/**
 * This function updates the status of an invoice for a specific organization using a PATCH request.
 * @param data - The `data` parameter is an object that contains the following properties:
 * @returns The function `useInvoiceStatusUpdateByRecyclerForOrg` is returning the response from the
 * PATCH request made to update the invoice status.
 */
export const useInvoiceStatusUpdateByRecyclerForOrg = async (data) => {
  const res = await axiosInstance.patch(`${endpoints.invoice}/${data?.id}`, {
    status: data?.status
  });
  return res;
};
/**
 * The function `useGetInvoiceListByRecyclerForOrg` fetches invoice data by recycler for an
 * organization using axios and returns the data.
 * @returns The `useGetInvoiceListByRecyclerForOrg` custom hook is being returned. This hook uses
 * `useQuery` from an unspecified library to fetch invoice data by recycler for an organization. It
 * makes a GET request to the specified endpoint, processes the response, and returns the data if the
 * status is 200. If there is an error, it logs the error to the console. The
 */
export const useGetInvoiceListByRecyclerForOrg = ({ id }) => {
  return useQuery({
    queryKey: ['getInvoiceDataByRecyclerForOrg', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.invoice}/organizations/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useGetRecyclerOrgDetailsByIDWithType` fetches details of a recycler organization by ID
 * and type using a query key and axiosInstance.
 * @returns The `useGetRecyclerOrgDetailsByIDWithType` custom hook is being returned. This hook uses
 * `useQuery` from an unspecified library to fetch details of a recycler organization by ID and type.
 * It makes an asynchronous request to an API endpoint based on the type provided ('Retailers' or
 * 'Enterprises') and the ID. If the request is successful (status code 200), it
 */
export const useGetRecyclerOrgDetailsByIDWithType = ({ id, type }) => {
  // console.log('🚀 ~ useGetRecyclerOrgDetailsByIDWithType ~ type:', type);
  return useQuery({
    queryKey: ['getRecyclerOrgDetailsByIDWithType', id, type],
    queryFn: async () => {
      return await axiosInstance
        .get(`${type === 'Retailers' ? endpoints.retailers : endpoints.enterprises}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};
export const useGetRecyclerOrgGroupByWithDateRange = ({ from, to }) => {
  return useQuery({
    queryKey: ['getRecyclerOrgGroupByWithDateRange', from, to],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.invoice}/groupOptions`, {
          from: from,
          to: to
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(from) && Boolean(to),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};
/**
 * The function `useGetRecyclerOrgItemsByLocation` fetches recyclable items from a specific location
 * using a query key and axios POST request.
 * @returns The `useGetRecyclerOrgItemsByLocation` function returns a query object that fetches
 * recycler organization items based on a specified location ID. The query object includes a query key,
 * query function that makes a POST request to retrieve items for the given location ID, and additional
 * configurations such as enabling the query based on the presence of the ID and handling the success
 * response.
 */
export const useGetRecyclerOrgItemsByLocation = ({ id, from, to, groupBy }) => {
  return useQuery({
    queryKey: ['getRecyclerOrgItemsByLocation', id, from, to, groupBy],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.invoice}/items`, {
          locationId: id,
          period: {
            from: from,
            to: to
          },
          groupBy: groupBy
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id) && Boolean(from) && Boolean(to) && Boolean(groupBy),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};
/**
 * The function `useInvoiceAddByRecyclerForOrg` asynchronously sends a POST request to create a new
 * invoice using the provided data.
 * @param data - The `data` parameter in the `useInvoiceAddByRecyclerForOrg` function likely contains
 * the information needed to create a new invoice for a recycler within an organization. This data
 * could include details such as the invoice number, date, items/services provided, amounts, and any
 * other relevant information
 * @returns The function `useInvoiceAddByRecyclerForOrg` is returning the response from the POST
 * request made to the `${endpoints.invoice}/create` endpoint using the `axiosInstance`.
 */
export const useInvoiceAddByRecyclerForOrg = async (data) => {
  const res = await axiosInstance.post(`${endpoints.invoice}/create`, data);
  return res;
};
export const useInvoiceUpdateRecyclerForOrg = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      // console.log('🚀 ~ mutationFnddddddd: ~ data:', data);
      return await axiosInstance
        .patch(`${endpoints.invoice}/update/${data.id} `, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getInvoiceByIdRecycler'], type: 'active' });
      // queryClient.refetchQueries('getRecycleData');
      // queryClient.refetchQueries('getRecycleDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};
/**
 * The function `useGetInvoiceListByEnterprise` fetches invoice data based on specified parameters for
 * a specific enterprise.
 * @returns The `useGetInvoiceListByEnterprise` function returns a custom hook that uses `useQuery`
 * from a library like React Query to fetch invoice data by enterprise based on the provided parameters
 * such as limit, sortOrder, sortField, current, previous, first, last, event, enterpriseId, and type.
 * The function makes a POST request to an endpoint using axiosInstance and returns the data if the
 */
export const useGetInvoiceListByEnterprise = ({
  limit,
  sortOrder,
  sortField,
  current,
  previous,
  first,
  last,
  event,
  enterpriseId,
  type,
  status,
  fromMonth,
  toMonth,
  inv_num,
  ticket_num,
  raised_org,
  raised_org_type,
  amt_value,
  amt_type
}) => {
  return useQuery({
    queryKey: [
      'getInvoiceDataByEnterprise',
      limit,
      sortOrder,
      sortField,
      current,
      previous,
      first,
      last,
      event,
      enterpriseId,
      type,
      status,
      fromMonth,
      toMonth,
      inv_num,
      ticket_num,
      raised_org,
      raised_org_type,
      amt_value,
      amt_type
    ],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.invoice}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          event: event,
          enterpriseId: enterpriseId,
          type: type,
          searchParams: {
            status: status ? status : null,
            raisedDate: {
              from: fromMonth ? fromMonth : null,
              to: toMonth ? toMonth : null
            },
            amount: {
              value: amt_value ? amt_value : null,
              type: amt_type ? amt_type : null
            },
            invoiceNumber: inv_num ? inv_num : null,
            raisedToOrg: {
              type: raised_org_type ? raised_org_type : null,
              id: raised_org ? raised_org : null
            },
            ticket: ticket_num ? ticket_num : null
          }
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField) && Boolean(event),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useGetInvoiceDetailsByIdEnterprise` fetches invoice details by enterprise ID using
 * axios and returns the data.
 * @returns The `useGetInvoiceDetailsByIdEnterprise` custom hook is being returned. This hook uses
 * `useQuery` from a library like React Query to fetch invoice details by ID for an enterprise. It
 * makes an asynchronous request to the server using Axios to get the invoice data based on the
 * provided ID. The `onSuccess` callback is returning the data received from the server.
 */
export const useGetInvoiceDetailsByIdEnterprise = ({ id }) => {
  return useQuery({
    queryKey: ['getInvoiceByIdEnterprise', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.invoice}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * This function updates the status and payment details of an invoice for a specific enterprise.
 * @param data - The `data` parameter in the `useInvoiceStatusUpdateByEnterprise` function contains the
 * following properties:
 * @returns The function `useInvoiceStatusUpdateByEnterprise` is returning the response from the PATCH
 * request made using axiosInstance to update the invoice status and payment details based on the data
 * provided.
 */
export const useInvoiceStatusUpdateByEnterprise = async (data) => {
  const res = await axiosInstance.patch(`${endpoints.invoice}/${data?.id}`, {
    status: data?.status,
    payment: {
      method: data?.paymentMethod,
      notes: data?.paymentNote,
      date: data?.date
    }
  });
  return res;
};

/**
 * The function `useGetInvoiceListByRetailer` fetches invoice data by retailer using specified
 * parameters and returns the data.
 * @returns The function `useGetInvoiceListByRetailer` returns a custom hook that uses `useQuery` from
 * a library (possibly React Query) to fetch invoice data by retailer. The hook takes in parameters
 * such as limit, sortOrder, sortField, current, previous, first, last, event, retailerId, and type to
 * construct a query key and perform an asynchronous POST request to retrieve the data
 */
export const useGetInvoiceListByRetailer = ({
  limit,
  sortOrder,
  sortField,
  current,
  previous,
  first,
  last,
  event,
  retailerId,
  // type,
  status,
  fromMonth,
  toMonth,
  inv_num,
  ticket_num,
  raised_org,
  raised_org_type,
  amt_value,
  amt_type
}) => {
  return useQuery({
    queryKey: [
      'getInvoiceDataByRetailer',
      limit,
      sortOrder,
      sortField,
      current,
      previous,
      first,
      last,
      event,
      retailerId,
      status,
      // type,
      fromMonth,
      toMonth,
      inv_num,
      ticket_num,
      raised_org,
      raised_org_type,
      amt_value,
      amt_type
    ],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.invoice}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          event: event,
          retailerId: retailerId,
          // type: type,
          searchParams: {
            status: status ? status : null,
            raisedDate: {
              from: fromMonth ? fromMonth : null,
              to: toMonth ? toMonth : null
            },
            amount: {
              value: amt_value ? amt_value : null,
              type: amt_type ? amt_type : null
            },
            invoiceNumber: inv_num ? inv_num : null,
            raisedToOrg: {
              type: raised_org_type ? raised_org_type : null,
              id: raised_org ? raised_org : null
            },
            ticket: ticket_num ? ticket_num : null
          }
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField) && Boolean(event),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useGetInvoiceDetailsByIdRetailer` fetches invoice details by ID for a retailer using a
 * query key and axios.
 * @returns The `useGetInvoiceDetailsByIdRetailer` custom hook is being returned. This hook uses
 * `useQuery` from a library like React Query to fetch invoice details by ID for a retailer. It makes
 * an asynchronous request to the server using Axios to get the invoice data based on the provided ID.
 * The `onSuccess` callback is used to return the data once the request is successful. The hook
 */
export const useGetInvoiceDetailsByIdRetailer = ({ id }) => {
  return useQuery({
    queryKey: ['getInvoiceByIdRetailer', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.invoice}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * This function updates the status and payment details of an invoice for a retailer using a PATCH
 * request.
 * @param data - The `data` parameter in the `useInvoiceStatusUpdateByRetailer` function seems to
 * contain the following properties:
 * @returns The function `useInvoiceStatusUpdateByRetailer` is returning the response from the PATCH
 * request made to update the invoice status and payment details.
 */
export const useInvoiceStatusUpdateByRetailer = async (data) => {
  const res = await axiosInstance.patch(`${endpoints.invoice}/${data?.id}`, {
    status: data?.status,
    payment: {
      method: data?.paymentMethod,
      notes: data?.paymentNote,
      date: data?.date
    }
  });
  return res;
};

import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import img from 'assets/images/ShredBox.png';
import { useGetJobUrlId } from 'hooks/useJobUrlHooks';
import { Skeleton } from '@mui/material';
const JobUrL = () => {
  let { id } = useParams();

  const { data: jobDetails, isPending: isLoadingJob } = useGetJobUrlId({
    id: id
  });
  // console.log('🚀 ~ JobUrL ~ jobDetails:', jobDetails);
  return (
    <>
      {isLoadingJob ? (
        <>
          <img src={img} className="srd-logo-new" alt="img" />
          <div className="shredbox-start shredbox-start2">
            <div className="shredboxmiddle">
              <div className="shredboxmiddlenew">
                <div className="main-srd-bx-start">
                  <div className="main-srd-bx-start-new">
                    <div className="main-srd-bx-start-new1">
                      <div className="table-responsive">
                        <table>
                          <tr>
                            <td>
                              <Skeleton width={50} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width={50} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width={50} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width={50} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Skeleton width={50} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div className="main-srd-bx-start-new2">
                      <h4>
                        <Skeleton width={100} />
                      </h4>
                      <div className="table-responsive">
                        <table>
                          <tr>
                            <th>
                              {' '}
                              <Skeleton width={100} />
                            </th>
                            <th>
                              {' '}
                              <Skeleton width={100} />
                            </th>
                            <th>
                              {' '}
                              <Skeleton width={100} />
                            </th>
                          </tr>

                          <tr>
                            {[0, 1, 3]?.map((i) => (
                              <td key={i}>
                                {' '}
                                <Skeleton width={100} />
                              </td>
                            ))}
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="srdlastbox">
                  <div className="srdlastbox1">
                    <p>
                      <Skeleton />
                    </p>
                    <p>
                      <Skeleton />
                    </p>
                  </div>
                  <div className="srdlastbox2">
                    <p>
                      <Skeleton />
                    </p>
                  </div>
                </div>
                <div className="download-btn-start">
                  <Skeleton width={200} height={80} />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <img src={img} className="srd-logo-new" alt="img" />
          <div className="shredbox-start shredbox-start2">
            <div className="shredboxmiddle">
              <div className="shredboxmiddlenew">
                <div className="main-srd-bx-start">
                  <div className="main-srd-bx-start-new">
                    <div className="main-srd-bx-start-new1">
                      <div className="table-responsive">
                        <table>
                          <tr>
                            <td>JOB:</td>
                            {/* <td>{jobDetails?.jobId ? jobDetails?.jobId : 'N.A'}</td> */}
                            <td>{jobDetails?.jobId}</td>
                          </tr>
                          <tr>
                            <td>Name:</td>
                            <td>
                              {jobDetails?.user?.firstName} {jobDetails?.user?.lastName}
                            </td>
                          </tr>
                          <tr>
                            <td>E-mail:</td>
                            <td>{jobDetails?.user?.email}</td>
                          </tr>
                          <tr>
                            <td>Address:</td>
                            <td>{jobDetails?.user?.address}</td>
                          </tr>
                          <tr>
                            <td>Phone:</td>
                            <td>{jobDetails?.user?.phone}</td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div className="main-srd-bx-start-new2">
                      <h4>DESTROYED</h4>
                      <div className="table-responsive">
                        <table>
                          <tr>
                            <th>Make</th>
                            <th>Model</th>
                            <th>Serial</th>
                          </tr>

                          {/* {jobDetails?.drives?.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>{item.jobId.toString()}</td>
                            <td>
                              <img
                                src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ718nztPNJfCbDJjZG8fOkejBnBAeQw5eAUA&s'}
                                alt=""
                                width={80}
                                height={80}
                                style={{ borderRadius: 10, margin: '20px auto 0' }}
                              />
                            </td>
                            <td>{item.make.toString()}</td>
                            <td>{item.model.toString()}</td>
                            <td>{item.serialId.toString()}</td>
                          </tr>
                        );
                      })} */}

                          {jobDetails?.drives?.map((item, i) => (
                            <>
                              <tr key={i}>
                                <td>{item?.make?.[0]}</td>
                                <td>{item?.model?.[0]}</td>
                                <td>{item?.serialId?.[0]}</td>
                              </tr>
                            </>
                          ))}

                          {/* {jobDetails?.drives?.map((item, i) => (
                              <td key={i}>{item?.model?.[0]}</td>
                            ))}
                            {jobDetails?.drives?.map((item, i) => (
                              <td key={i}>{item?.serialId?.[0]}</td>
                            ))} */}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="srdlastbox">
                  <div className="srdlastbox1">
                    <p>
                      <span>Machine ID:</span> {jobDetails?.machine?.machineId}
                    </p>
                    <p>
                      <span>Location: </span> {jobDetails?.location?.name}
                    </p>
                  </div>
                  <div className="srdlastbox2">
                    <p>
                      <span>Date:</span>{' '}
                      {moment(jobDetails?.jobDate).format('Do MMMM YYYY, h:mm a')
                        ? moment(jobDetails?.jobDate).format('Do MMMM YYYY, h:mm a')
                        : jobDetails?.jobDate}
                    </p>
                  </div>
                </div>
                <div className="download-btn-start">
                  <a href={`${process.env.REACT_APP_API_BASE_URL}/v1/drive-crush/certificate/${jobDetails?.jobId}`} download="Certificate">
                    <button className="download-btn">Save Certificate</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default JobUrL;

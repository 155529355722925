import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Switch,
  FormControlLabel,
  Button,
  Divider,
  CircularProgress,
  CardHeader,
  Paper,
  IconButton,
  Box
} from '@mui/material';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AddNewTableHeader from './add-new-table-header';

function ModifyPermission({
  addPermissionList = [],
  editPermissionList = [],
  filtersPermissionList = [],
  modalPermissionList = [],
  tableHeadersPermissionList = [],
  showAdd = false,
  showEdit = false,
  showView = false,
  isLoadingPermissionList = false
}) {
  const [addTableHeader, setAddTableHeader] = useState(false);
  const [editableData, setEditableData] = useState(null);

  const initialValues = {
    showAdd,
    showEdit,
    showView,
    add: addPermissionList,
    edit: editPermissionList,
    filters: filtersPermissionList,
    tableHeaders: tableHeadersPermissionList,
    modal: modalPermissionList,
    newTableHeader: {
      id: '',
      label: '',
      changeState: false,
      sortField: false
    }
  };

  const renderPermissionCards = (sectionName, values, arrayHelpers) => (
    <Grid
      container
      spacing={{
        xs: 1, // Reduced spacing for smaller devices
        sm: 2,
        md: 3,
        lg: 4,
        xl: 4
      }}
    >
      {values[sectionName].map((item, index) => (
        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
          <Card variant="outlined">
            <CardContent
              sx={{
                p: { xs: 1, sm: 2 }
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6">ID: {item.id}</Typography>
                <Typography variant="body1">Label: {item.label}</Typography>
              </Box>
              <FormControlLabel
                control={<Switch checked={item.show} onChange={() => arrayHelpers.replace(index, { ...item, show: !item.show })} />}
                label="Show"
              />
              {sectionName === 'add' || sectionName === 'edit' ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={item.isRequired}
                      onChange={() => arrayHelpers.replace(index, { ...item, isRequired: !item.isRequired })}
                    />
                  }
                  label="Required"
                />
              ) : null}
              {sectionName === 'edit' ? (
                <FormControlLabel
                  control={
                    <Switch checked={item.isStatic} onChange={() => arrayHelpers.replace(index, { ...item, isStatic: !item.isStatic })} />
                  }
                  label="Static"
                />
              ) : null}
              {sectionName === 'tableHeaders' ? (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item.changeState}
                        onChange={() => arrayHelpers.replace(index, { ...item, changeState: !item.changeState })}
                      />
                    }
                    label="Change State"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item.sortField}
                        onChange={() => arrayHelpers.replace(index, { ...item, sortField: !item.sortField })}
                      />
                    }
                    label="Sort Field"
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton
                      color="info"
                      onClick={() => {
                        setEditableData(item);
                        setAddTableHeader(true);
                      }}
                      title="Edit"
                    >
                      <DriveFileRenameOutlineIcon />
                    </IconButton>
                    <IconButton color="error" onClick={() => arrayHelpers.remove(index)} title="Remove">
                      <RemoveCircleIcon />
                    </IconButton>
                  </Box>
                </>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );

  if (isLoadingPermissionList) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log('Submitted Values:', values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* Filters Section */}
            <Paper variant="outlined" sx={{ mb: 2 }}>
              <CardHeader title="Filters" />
              <Divider />
              <CardContent>
                <FieldArray name="filters" render={(arrayHelpers) => renderPermissionCards('filters', values, arrayHelpers)} />
              </CardContent>
            </Paper>

            {/* Table Headers Section */}
            <Paper variant="outlined" sx={{ mb: 2 }}>
              <CardHeader
                title="Table Headers"
                action={
                  <IconButton onClick={() => setAddTableHeader(true)}>
                    <PlaylistAddIcon color="primary" />
                  </IconButton>
                }
              />
              <Divider />
              <CardContent>
                <FieldArray name="tableHeaders" render={(arrayHelpers) => renderPermissionCards('tableHeaders', values, arrayHelpers)} />
                <AddNewTableHeader
                  editableData={editableData}
                  setEditableData={setEditableData}
                  setFieldValue={setFieldValue}
                  values={values}
                  open={addTableHeader}
                  setOpen={setAddTableHeader}
                />
              </CardContent>
            </Paper>

            {/* Add Options Section */}
            <Paper variant="outlined" sx={{ mb: 2 }}>
              <CardHeader
                title="Add Options"
                action={
                  <FormControlLabel
                    control={<Switch checked={values.showAdd} onChange={() => setFieldValue('showAdd', !values.showAdd)} />}
                    label="Show Add"
                  />
                }
              />
              <Divider />
              <CardContent>
                <FieldArray name="add" render={(arrayHelpers) => renderPermissionCards('add', values, arrayHelpers)} />
              </CardContent>
            </Paper>

            {/* Edit Options Section */}
            <Paper variant="outlined" sx={{ mb: 2 }}>
              <CardHeader
                title="Edit Options"
                action={
                  <FormControlLabel
                    control={<Switch checked={values.showEdit} onChange={() => setFieldValue('showEdit', !values.showEdit)} />}
                    label="Show Edit"
                  />
                }
              />
              <Divider />
              <CardContent>
                <FieldArray name="edit" render={(arrayHelpers) => renderPermissionCards('edit', values, arrayHelpers)} />
              </CardContent>
            </Paper>

            {/* Modal Section */}
            <Paper variant="outlined" sx={{ mb: 2 }}>
              <CardHeader
                title="Modal"
                action={
                  <FormControlLabel
                    control={<Switch checked={values.showView} onChange={() => setFieldValue('showView', !values.showView)} />}
                    label="Show Modal"
                  />
                }
              />
              <Divider />
              <CardContent>
                <FieldArray name="modal" render={(arrayHelpers) => renderPermissionCards('modal', values, arrayHelpers)} />
              </CardContent>
            </Paper>

            {/* Submit Button */}
            <Grid container justifyContent="center" sx={{ mt: 3 }}>
              <Button type="submit" variant="contained" color="primary" sx={{ color: '#fff' }}>
                Calibrate
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

ModifyPermission.propTypes = {
  addPermissionList: PropTypes.array,
  editPermissionList: PropTypes.array,
  filtersPermissionList: PropTypes.array,
  modalPermissionList: PropTypes.array,
  tableHeadersPermissionList: PropTypes.array,
  showAdd: PropTypes.bool,
  showEdit: PropTypes.bool,
  showView: PropTypes.bool,
  isLoadingPermissionList: PropTypes.bool
};

export default ModifyPermission;

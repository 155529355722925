import { useQuery } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

export const useGetRoutingList = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getRoutingList', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.permissions}/panel/${usersType}/${organizationType}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType) && Boolean(usersType),
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import EmployeeForm from '../form';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';
const EmployeeAdd = () => {
  return (
    <>
      <BreadcrumbsForPage
        name="Add Employee"
        obj={{
          title: 'All Employees',
          title2: 'Add Employee',
          url: '/dashboard/employees'
        }}
      />
      <MainCard>
        <Formik
          initialValues={{
            prefix: '',
            firstName: '',
            lastName: '',
            title: '',
            email: '',
            phone: '',
            address: '',
            rfid: '',
            authPin: '',
            documents: [],
            isAdmin: '',
            assignOrg: '',
            assignMachine: '',
            assign_org: '',
            assign_machine: '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            prefix: Yup.string().required('Prefix is required'),
            firstName: Yup.string()
              .max(50, 'First Name must be at most 50 characters')
              .required('First Name is required')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[a-zA-Z\s]*$/, 'First Name should only contain letters and spaces'),
            lastName: Yup.string().max(50, 'Last Name must be at most 50 characters').required('Last Name is required').trim(),
            title: Yup.string().required('Title is required'),
            email: Yup.string().email('Invalid email').required('Email is required'),
            phone: Yup.string()
              .phone('IN', true, 'Invalid phone number') // Use yup-phone if necessary
              .required('Phone number is required'),
            address: Yup.string().max(255, 'Address can be at most 255 characters'),
            rfid: Yup.string().required('RFID is required'),
            authPin: Yup.string().required('Auth Pin is required')
          })}
          onSubmit={async (value) => {
            const submitData = {
              prefix: value?.prefix,
              firstName: value?.firstName,
              lastName: value?.lastName,
              title: value?.title,
              email: value?.email,
              phone: value?.phone,
              address: value?.address,
              rfid: value?.rfid,
              authPin: value?.authPin
            };
            console.log('🚀 ~ onSubmit={ ~ submitData:', submitData);
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setValues, setTouched }) => (
            <>
              <EmployeeForm
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                values={values}
                setFieldValue={setFieldValue}
                setValues={setValues}
                setTouched={setTouched}
              />
            </>
          )}
        </Formik>
      </MainCard>
    </>
  );
};

export default EmployeeAdd;

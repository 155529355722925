import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Material-UI
import { useTheme } from '@mui/material/styles';
import { Box, Chip, ClickAwayListener, List, ListItemButton, ListItemText, Paper, Popper, Typography } from '@mui/material';
// import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';

// Third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// Project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import { SET_ORG_TYPE } from 'store/actions/actions';

const NotificationTypeDropDown = ({ data }) => {
  const theme = useTheme();
  // const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);

  // Always set the first item as the default selection if available
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Notification Type');

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  // Handle menu toggle
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Handle menu close
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // Handle menu item click
  const handleListItemClick = (event, index, optionName) => {
    setSelectedIndex(index);
    handleClose(event);
    setSelectedOption(optionName);
    // dispatch({ type: SET_ORG_TYPE, orgType: optionName });
  };

  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  // useEffect(() => {
  //   if (data) {
  //     setSelectedIndex(0);
  //     setSelectedOption(data[0]);
  //     dispatch({ type: SET_ORG_TYPE, orgType: data[0] });
  //   }
  // }, [data, dispatch]);
  return (
    <>
      {/* Chip Button */}
      <Chip
        sx={{
          height: '38px',
          alignItems: 'left',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={
          <NotificationsIcon
            sx={{ cursor: 'pointer', fontSize: 22 }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
          />
        }
        label={
          <Typography variant="h5" sx={{ color: 'black' }}>
            {selectedOption}
          </Typography>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />

      {/* Popper Menu */}
      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
        style={{ zIndex: 1300 }} // Adjust z-index here
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 1 }}>
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        {data?.map((val, index) => (
                          <ListItemButton
                            key={index}
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(event, index, val)}
                          >
                            <ListItemText
                              primary={
                                <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                                  {val}
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationTypeDropDown;

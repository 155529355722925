import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { Formik } from 'formik';
import * as Yup from 'yup';

import ReportTicketModalForm from './form';
const ReportTicketModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const validationSchema = Yup.object({
    reportReason: Yup.string().trim()
  });
  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          Report Reason Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>

        <DialogContent dividers>
          {/* <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
            <Grid item xs={12}>
              <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
                Ticket Reason Details
              </Typography>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth error={Boolean(touched.reason && errors.reason)} sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-email-login">Report Reason * </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-secondary-address"
                      type="text"
                      value={values.reason}
                      name="reason"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Report Reason *"
                      placeholder="Enter your reason"
                      inputProps={{}}
                      autoComplete="off"
                    />
                    {touched.reason && errors.reason && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{
                          fontSize: '13.8px'
                        }}
                      >
                        {errors.reason}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
          <Formik
            initialValues={{
              reportReason: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const submitData = {
                reportReason: values?.reportReason
              };
              console.log('🚀 ~ ReportTicketModal ~ submitData:', submitData);
            }}
          >
            {({ errors, touched, handleChange, values, handleSubmit, setFieldValue, handleBlur }) => (
              <>
                <ReportTicketModalForm
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  values={values}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  handleClose={handleClose}
                  handleBlur={handleBlur}
                />
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReportTicketModal;

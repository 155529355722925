// import axiosInstance from 'utils/axiosInstance';
// import setAuthToken from 'store/helper/setAuthToken';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
import { endpoints } from 'endpoints/endpoints';
// import { useQueryClient } from '@tanstack/react-query';

export const userLoginHelper = (data) => {
  return {
    type: 'SET_USER_AUTH',
    payload: data
  };
};

const userLogoutHelper = (data) => {
  return {
    type: 'DELETE_USERS_DATA',
    payload: data
  };
};

export const userLogin = (loginCredentials, history, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);

      /**
       * when login api work on this code
       *
       */
      await axiosInstance
        .post(`${endpoints.users}/login`, loginCredentials)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);

            localStorage.setItem('userJwtToken', res?.data?.data?.token);
            dispatch(userLoginHelper(res.data?.user));
            localStorage.setItem('userData', JSON.stringify(res?.data?.data?.user));
            localStorage.setItem('userOrgId', JSON.stringify(res?.data?.data?.user?.id));
            localStorage.setItem('userOrgName', JSON.stringify(res?.data?.data?.user?.organizationName));

            toastConfig.type = 'success';
            setToast(toastConfig, 'Successfully login!');
            history('/dashboard/default');
          }
        })
        .catch(() => {
          setIsLoading(false);
          toastConfig.type = 'error';
          setToast(toastConfig, 'Opps! Invalid Credentials !');
        });
      /**
       * when login api work on this code
       *
       */
    } catch (err) {
      if (err.response) {
        const message = { message: err.response.data.message };
        dispatch({
          type: 'SET_LOGIN_ERRORS',
          payload: message
        });
      } else {
        const message = { message: err };

        return message;
      }
    }
  };
};

export const userLogout = (history) => {
  // const queryClient = useQueryClient();
  return (dispatch) => {
    localStorage.removeItem('userJwtToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('userOrgId');
    localStorage.removeItem('userOrgName');
    // setAuthToken(false);
    dispatch(userLogoutHelper({}));
    history('/');
    // queryClient.clear();
  };
};

import { Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import EnterpriseUserForm from '../enterprise-user-form';
import { useEffect } from 'react';
import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
// import { useGetProfileById } from 'hooks/useProfileHooks';
import { useEnterpriseUserAdd } from 'hooks/useEnterpriseHooks';
import {
  useMutation
  // useQueryClient
} from '@tanstack/react-query';

import { setToast, toastConfig } from 'utils/commonUtil';
const EnterpriseUserAdd = () => {
  const [isLoading, setLoading] = useState(true);

  // const { data: profileDetails } = useGetProfileById();
  // const { mutate: addEnterUser, isLoading: isLoadingEnterprise } = useEnterpriseUserAdd();

  const { mutate: addEnterUser, isPending: isLoadingEnterprise } = useMutation({
    mutationFn: useEnterpriseUserAdd
  });

  // const queryClient = useQueryClient();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <FormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage name="Add User" obj={{ title: 'All  Users ', title2: 'Add  User', url: '/dashboard/enterprise/user' }} />
          <MainCard>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                rfidCode: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .max(50, ' First Name must be at most 50 characters')
                  .required(' First Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\[\]{}:';"\\|,.<>\\?]*$/, ' First Name should not be in any special character'),

                lastName: Yup.string()
                  .max(50, ' Last Name must be at most 50 characters')
                  .required(' Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\[\]{}:';"\\|,.<>\\?]*$/, ' Last Name should not be in any special character'),
                email: Yup.string().email('Invalid email address').required(' Email is required')
                // rfidCode: Yup.string().max(30, 'Must be of 30 characters').required('RFID Code is required')
              })}
              onSubmit={async (value, { resetForm }) => {
                let { enterprise } = JSON.parse(localStorage.getItem('userData'));

                if (!enterprise?.id) {
                  toastConfig.type = 'error';
                  setToast(toastConfig, 'Enterprise ID not found!');
                  return;
                }

                // const submitData = {
                //   firstName: value?.firstName.trim(),
                //   lastName: value?.lastName.trim(),
                //   email: value?.email.trim(),
                //   rfidCode: value?.rfidCode.trim(),
                //   enterpriseId: enterprise?.id
                // };

                const submitData2 = {
                  firstName: value?.firstName.trim(),
                  lastName: value?.lastName.trim(),
                  email: value?.email.trim(),
                  rfidCode: value?.rfidCode.trim(),
                  enterprise: enterprise?.id
                };

                // addEnterUser(submitData);
                // resetForm({});

                addEnterUser(submitData2, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.success);
                      // queryClient.refetchQueries('getEnterpriseUserData');
                      resetForm({});
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                setValues,
                setFieldError
              }) => (
                <EnterpriseUserForm
                  btnName="Add"
                  errors={errors}
                  setError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  isLoadingEnterprise={isLoadingEnterprise}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  id={''}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default EnterpriseUserAdd;

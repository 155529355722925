import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

// Ticket Add
export const useTicketReasonAdd = async (data) => {
  // const res = await axiosInstance.post(`v1/ticketReasons/create`, data);
  const res = await axiosInstance.post(`${endpoints.ticket}/save`, data);

  return res;
};

// Ticket All List
// export const useGetTicketReasonList = ({ limit, sortOrder, sortField, current, previous, first, last }) => {
export const useGetTicketReasonList = ({ limit, sortOrder, sortField, page, reason, status }) => {
  // console.log('useGetTicketReasonList : ', limit , page)
  return useQuery({
    queryKey: ['getTicketReasonData', limit, sortOrder, sortField, page, reason, status],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.ticket}/${page}/${limit}`, {
          // previous: previous,
          // current: current,
          // limit: limit,
          // first: first,
          // last: last,
          // field: sortField,
          // order: sortOrder
          sortParams: {
            sortField: sortField,
            sortOrder: sortOrder
          },
          searchParams: {
            enabled: status || null,
            reason: reason || null
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    cacheTime: 0, // This prevents React Query from caching the data
    staleTime: 0, // This ensures that the data is always considered "stale" and refetched on each request
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/// Status
export const useTicketReasonStatusUpdate = () => {
  // const queryClient = useQueryClient();
  // .patch(`v1/ticketReasons/status/${data?.id}`, { enabled: data?.enabled })
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.ticket}/${data?.id}`, { enabled: data?.enabled })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getTicketReasonData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

///// details Api
export const useGetTicketReasonById = ({ id }) => {
  //.get(`v1/ticketReasons/${id}`)
  return useQuery({
    queryKey: ['getTicketReasonDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.ticket}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

///Edit Ticket Reason
export const useGetTicketReasonEditedList = () => {
  const queryClient = useQueryClient();
  //.patch(`v1/ticketReasons/${data.id}`, data)
  return useMutation({
    mutationFn: async (data) => {
      let reqData = {
        reason: data.reason,
        enabled: data.enabled
      };
      return await axiosInstance
        .put(`${endpoints.ticket}/${data.id}`, reqData)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getTicketReasonDetailsById'], type: 'active' });
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
// import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';
import { useGetPermissionsLocationsList, useLocationAdd } from 'hooks/useLocationHooks';
import LocationForm from '../location-form';
import { useMutation } from '@tanstack/react-query';
import { setToast, toastConfig } from 'utils/commonUtil';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LocationAdd = ({ open = false, handleClose, queryClient }) => {
  const [isLoading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract values from the state
  const {
    // permissionsList,
    locationToggledButton
  } = location.state || {};
  // const { mutate: addLocation, isPending: isLoadingLocation } = useLocationAdd();
  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const {
    data: permissionsList,

    isLoading: isLoadingPermissionsList
  } = useGetPermissionsLocationsList({
    organizationType:
      organizationType === 'Service Provider' ? 'ServiceProvider' : organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
    usersType: usersType === 'Main POC' ? 'POC' : 'POC'
  });

  const addPermissionsList = permissionsList?.add || [];
  console.log('🚀 ~ addPermissionsList:', addPermissionsList, 'isLoadingPermissionsList', isLoadingPermissionsList);

  const { mutate: addLocation, isPending: isLoadingLocation } = useMutation({
    mutationFn: useLocationAdd
  });
  const [locationLatLng, setLocationLatLng] = useState({
    lat: '',
    lng: '',
    label: ''
  });
  // const queryClient = useQueryClient();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const initials = {
    location_name: '',
    // address: '',
    // city: '',
    // state: '',
    // country: '',
    // zip: '',
    location: '',
    placeId: '',
    latitude: '',
    longitude: '',
    tax: '',
    submit: null
  };
  const dynamicalidationSchema = Yup.object(
    addPermissionsList.reduce((schema, filter) => {
      if (filter?.show) {
        switch (filter.id) {
          case 'locationName':
            schema['location_name'] = Yup.string().max(50, 'Location Name must be at most 50 characters').trim();
            if (filter.isRequired) {
              schema['location_name'] = schema['location_name'].required('Location Name is required');
            }
            break;
          case 'address':
            schema['location'] = Yup.string();
            if (filter.isRequired) {
              schema['location'] = schema['location'].required('Address is required');
            }
            break;
          case 'tax':
            schema['tax'] = Yup.number()
              .positive('Tax must contain a positive number')
              .max(100, 'Maximum allowed value is 100')
              .test('is-decimal', 'Tax must have at most two decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value))
              .typeError('Tax must be a number');
            if (filter.isRequired) {
              schema['tax'] = schema['tax']
                .when('areSeatsLimited', {
                  is: 'yes',
                  then: Yup.number().required().typeError('Tax must be a number'),
                  otherwise: Yup.number().notRequired().typeError('Tax must be a number')
                })
                .required('Tax is required');
            }
            break;
          default:
            break;
        }
      }
      return schema;
    }, {})
  );

  return (
    <>
      {isLoading ? (
        <>
          <OrganizationFormView />
        </>
      ) : (
        <>
          {!open && (
            <BreadcrumbsForPage
              name={`Add Location ${locationToggledButton ? `(${locationToggledButton.split(' ')?.[0]})` : ''}`}
              obj={{ title: 'All Locations', title2: 'Add Location', url: '/dashboard/location' }}
            />
          )}
          <MainCard>
            <Formik
              initialValues={initials}
              validationSchema={dynamicalidationSchema}
              onSubmit={async (value, { resetForm }) => {
                if (!value.location || !value.placeId) {
                  toastConfig.type = 'error';
                  setToast(toastConfig, 'Oops! Enter your full address');
                  return;
                }
                const submitData = {
                  enabled: true,
                  name: value?.location_name.trim(),
                  taxAmount: value?.tax,
                  address: {
                    fullAddress: value?.location.trim(),
                    placeId: value?.placeId,
                    coOrdinates: {
                      latitude: value?.latitude,
                      longitude: value?.longitude
                    }
                  }
                };

                addLocation(submitData, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.message);
                      if (open) {
                        queryClient.refetchQueries({ queryKey: ['getLocationAllData'], type: 'active' });
                        handleClose();
                      } else {
                        navigate(-1);
                      }
                      resetForm({});
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                <LocationForm
                  btnName="Add"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  isLoadingLocation={isLoadingLocation}
                  setFieldValue={setFieldValue}
                  id={''}
                  locationLatLng={locationLatLng}
                  setLocationLatLng={setLocationLatLng}
                  permissionsList={addPermissionsList}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default LocationAdd;

import { Formik } from 'formik';
import React from 'react';
import { Fragment } from 'react';
import SearchPermisionForm from 'ui-component/search-permission-form';
import ModifyPermission from './modify-permission';
import { Divider } from '@mui/material';
import * as Yup from 'yup'; // Import Yup for validation
import { useSelector, useDispatch } from 'react-redux';
import { useGetScreenData } from 'hooks/useControlPanelHooks';

function ScreenMasterControl({ routeList }) {
  const { route } = useSelector((state) => state.controlPanelReducer); // Access screen from Redux state
  const { data: screenListList, isLoading: isLoadingScreenList } = useGetScreenData({
    screen: route
  });
  const dispatch = useDispatch();
  const initialValues = {
    screen: route || '' // Initialize the screen field with value from Redux
  };
  // Validation schema for the screen field
  const validationSchema = Yup.object().shape({
    screen: Yup.string().required('Screen Type is required') // Add required validation for the screen field
  });

  const handleFormSubmit = (values) => {
    // console.log('Submitted values:', values); // Log the submitted values
    dispatch({ type: 'SET_ROUTE', payload: values?.screen }); // Dispatch the SET_ROUTE action to update screen in Redux
  };

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema} // Attach validation schema to Formik
        onSubmit={handleFormSubmit}
      >
        {(args) => (
          <SearchPermisionForm
            {...args}
            showScreenField={true} // Show the screen field in the form
            routeList={routeList} // Pass the current screen value as the routeList prop
          />
        )}
      </Formik>

      <Divider sx={{ my: 2 }} />

      <ModifyPermission
        addScreenList={screenListList?.data?.data?.add || []}
        editScreenList={screenListList?.data?.data?.edit || []}
        filtersScreenList={screenListList?.data?.data?.filters || []}
        modalScreenList={screenListList?.data?.data?.modal || []}
        isLoadingScreenList={isLoadingScreenList}
      />
    </Fragment>
  );
}

export default ScreenMasterControl;

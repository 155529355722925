import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
// import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useGetJobId, useGetJobUpdateList } from 'hooks/useJobHooks';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  // TableSortLabel,
  // Tooltip,
  Typography
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import moment from 'moment';
import { useTheme } from '@mui/system';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useState } from 'react';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import { roundOffTwo2 } from 'utils/commonUtil';
// import { toastConfig } from 'utils/commonUtil';
// import { useEffect } from 'react';
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: '100%',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  })
);

const CustomChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: '#6fd74b',
  color: '#fff',
  '& .MuiChip-deleteIcon': {
    color: '#fff'
  }
}));

function EnhancedTableHead() {
  const columns = [
    { id: '#', label: '#' },
    {
      id: 'Image',
      label: 'Image',
      minWidth: 150,
      align: 'center',
      format: (value) => value.toLocaleString('en-US')
    },
    // { id: 'Drive_Number', label: 'Drive Number', minWidth: 150, align: 'center' },

    {
      id: 'Make',
      label: 'Make',
      minWidth: 150,
      align: 'center',
      format: (value) => value.toLocaleString('en-US')
    },
    {
      id: 'Model',
      label: 'Model',
      minWidth: 150,
      align: 'center',
      format: (value) => value.toLocaleString('en-US')
    },
    {
      id: 'Serial',
      label: 'Serial Id',
      minWidth: 150,
      align: 'center',
      format: (value) => value.toLocaleString('en-US')
    }
  ];
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell key={headCell.id} align="center">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const EnterpriseJobView = () => {
  let { id } = useParams();
  const theme = useTheme();
  const [openRow, setOpenRow] = useState(false);
  const { data: jobDetails, isPending } = useGetJobId({
    id: id.slice(1)
  });
  const { mutate: jobDriveUpdate, isPending: isLoadingJobDetails } = useGetJobUpdateList();

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index);
  };
  // const validationSchema = Yup.object().shape({
  //   make: Yup.array().of(Yup.string().required('Make is required')),
  //   model: Yup.array().of(Yup.string().required('Model is required')),
  //   serialId: Yup.array().of(Yup.string().required('Serial No. is required'))
  // });

  return (
    <>
      <BreadcrumbsForPage obj={{ title: 'All Jobs', title2: `View Jobs ${id}`, url: '/dashboard/enterprise/job' }} />
      <MainCard title={`View Jobs ${id}`}>
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Job Details
            </Typography>
            <Divider />
            <div className="shredbox-start">
              <div className="shredboxmiddlenew">
                <div className="main-srd-bx-start">
                  <div className="main-srd-bx-start-new">
                    <div className="main-srd-bx-start-new1 main-srd-bx-start-newmachine1">
                      <div className="table-responsive">
                        <table>
                          <tr>
                            <td>JOB:</td>
                            {/* <td>{jobDetails?.jobId ? jobDetails?.jobId : 'N.A'}</td> */}
                            <td>{jobDetails?.jobId}</td>
                          </tr>
                          <tr>
                            <td>Name:</td>
                            <td>
                              {jobDetails?.user?.firstName} {jobDetails?.user?.lastName}
                            </td>
                          </tr>
                          <tr>
                            <td>E-mail:</td>
                            <td>{jobDetails?.user?.email}</td>
                          </tr>
                          <tr>
                            <td>Address:</td>
                            <td>{jobDetails?.user?.address}</td>
                          </tr>
                          <tr>
                            <td>Phone:</td>
                            <td>{jobDetails?.user?.phone}</td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div className="main-srd-bx-start-new2 main-srd-bx-start-newmachine">
                      <div className="table-responsive">
                        <table>
                          <tr>
                            <td>
                              <span>Machine ID:</span>
                            </td>
                            <td>{jobDetails?.machine?.machineId}</td>
                          </tr>
                          <tr>
                            <td>
                              <span>Location:</span>
                            </td>
                            <td>{jobDetails?.location?.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <span>Date:</span>{' '}
                            </td>
                            <td>
                              {' '}
                              {moment(jobDetails?.jobDate).format('Do MMMM YYYY, h:mm a')
                                ? moment(jobDetails?.jobDate).format('Do MMMM YYYY, h:mm a')
                                : jobDetails?.jobDate}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span>Price:</span>
                            </td>
                            <td>$ {jobDetails?.price ? roundOffTwo2(jobDetails?.price) : 0}</td>
                          </tr>
                          <tr>
                            <td>
                              <span>Tax:</span>
                            </td>
                            <td>$ {jobDetails?.tax ? roundOffTwo2(jobDetails?.tax) : 0}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider />

                <div className="main-srd-bx-start-new2 main-srd-bx-start-new-drive-process main-srd-bx-start-new3">
                  <h4>Drives Processed</h4>
                  <div className="table-responsive">
                    <TableContainer sx={{ maxHeight: 440 }}>
                      {isPending ? (
                        <TableBody>
                          {[1, 2, 3, 4].map((i) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                              <TableCell align="center">
                                <Skeleton variant="text" width={200} />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" width={200} />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" width={200} />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" width={200} />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" width={200} />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton variant="text" width={200} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      ) : (
                        <>
                          {jobDetails?.drives?.length > 0 ? (
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              fullWidth
                              sx={{ ...theme.typography.customInput }}
                              className="new-table-classs"
                            >
                              <EnhancedTableHead />
                              <TableBody>
                                {jobDetails?.drives?.map((row, i) => (
                                  <>
                                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                      {/* <TableCell align="center">
                                        <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(i)}>
                                          {openRow === i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </IconButton>
                                      </TableCell> */}
                                      <TableCell align="center">
                                        {row?.finalMake || row?.finalModel || row?.finalSerial ? (
                                          <DoNotDisturbAltRoundedIcon color="error" />
                                        ) : (
                                          <IconButton aria-label="expand row" size="small" onClick={() => handleRowClick(i)}>
                                            {openRow === i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                          </IconButton>
                                        )}
                                      </TableCell>
                                      <TableCell align="center">
                                        <HtmlTooltip
                                          title={
                                            <>
                                              <img
                                                src={row?.imageUrl}
                                                alt=""
                                                // width={'100%'}
                                                // height={100}
                                                style={{
                                                  borderRadius: 10,
                                                  width: '300px',
                                                  height: '300px'
                                                }}
                                              />
                                            </>
                                          }
                                        >
                                          <img
                                            src={row?.imageUrl}
                                            alt=""
                                            // width={'100%'}
                                            // height={100}
                                            style={{
                                              borderRadius: 10,
                                              height: '80px',
                                              width: '80px',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                              top: '5px',
                                              position: 'relative'
                                            }}
                                          />
                                        </HtmlTooltip>
                                      </TableCell>

                                      <TableCell align="center">{row?.finalMake ? row?.finalMake : row?.make?.[0]}</TableCell>
                                      <TableCell align="center">{row?.finalModel ? row?.finalModel : row?.model?.[0]}</TableCell>
                                      <TableCell align="center">{row?.finalSerial ? row?.finalSerial : row?.serialId?.[0]}</TableCell>
                                    </TableRow>

                                    <TableRow>
                                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                        <Collapse in={openRow === i} timeout="auto" unmountOnExit>
                                          <Formik
                                            initialValues={{
                                              make: '',
                                              model: '',
                                              serialId: '',
                                              submit: null
                                            }}
                                            // validationSchema={validationSchema}
                                            onSubmit={(values) => {
                                              const submitData = {
                                                finalMake: values?.make,
                                                finalModel: values?.model,
                                                finalSerial: values?.serialId,
                                                jobId: id.slice(1),
                                                driveId: row?.id
                                              };

                                              jobDriveUpdate(submitData, {
                                                onSuccess: (res) => {
                                                  if (res?.status == 200) {
                                                    setOpenRow(false);
                                                  } else {
                                                    toastConfig.type = 'error';
                                                    setOpenRow(true);
                                                  }
                                                }
                                              });
                                              // console.log('sUpdate drive', submitData);
                                            }}
                                          >
                                            {({ handleSubmit, setFieldValue, errors, touched }) => (
                                              <Form
                                                // onSubmit={handleSubmit}
                                                onSubmit={(e) => {
                                                  e.preventDefault();
                                                  handleSubmit();
                                                }}
                                              >
                                                <Grid container spacing={gridSpacing}>
                                                  <Grid item xs={12}>
                                                    <Typography
                                                      component="h4"
                                                      variant="h2"
                                                      sx={{ mb: 2, color: '#000!important' }}
                                                      className="main-srd-bx-start-new2 main-srd-bx-start-new-drive-process main-srd-bx-start-new3"
                                                    >
                                                      Drive Details
                                                    </Typography>
                                                    <Grid container spacing={6} className="newgridpadding">
                                                      <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <img
                                                          src={row?.imageUrl}
                                                          alt=""
                                                          className="drivedtailimg"
                                                          style={{
                                                            borderRadius: 10,
                                                            height: '400px',
                                                            width: '100%',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            top: '-13px',
                                                            position: 'relative',
                                                            objectFit: 'cover'
                                                          }}
                                                        />
                                                      </Grid>

                                                      <Grid item lg={6} md={6} sm={12} xs={12}>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                          <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                                            <Autocomplete
                                                              // multiple
                                                              id="autocomplete"
                                                              limitTags={2}
                                                              options={row?.make?.map((option) => option)}
                                                              freeSolo
                                                              onChange={(event, value) => setFieldValue('make', value)}
                                                              renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => {
                                                                  const { key, ...tagProps } = getTagProps({ index });
                                                                  return (
                                                                    <CustomChip key={key} label={option} variant="outlined" {...tagProps} />
                                                                  );
                                                                })
                                                              }
                                                              renderInput={(params) => (
                                                                <TextField
                                                                  className="enterprise-location"
                                                                  {...params}
                                                                  variant="outlined"
                                                                  label="Make"
                                                                  placeholder="Select Make"
                                                                  onBlur={(e) => {
                                                                    setFieldValue('make', e.target.value);
                                                                  }}
                                                                />
                                                              )}
                                                            />
                                                            {touched.make && errors.make && (
                                                              <FormHelperText
                                                                error
                                                                id="standard-weight-helper-text-email-login"
                                                                sx={{
                                                                  fontSize: '13.8px'
                                                                }}
                                                              >
                                                                {errors.make}
                                                              </FormHelperText>
                                                            )}
                                                          </FormControl>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                          <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                                            <Autocomplete
                                                              // multiple
                                                              id="autocomplete"
                                                              limitTags={2}
                                                              options={row?.model?.map((option) => option)}
                                                              freeSolo
                                                              onChange={(event, value) => setFieldValue('model', value)}
                                                              renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => {
                                                                  const { key, ...tagProps } = getTagProps({ index });
                                                                  return (
                                                                    <CustomChip key={key} label={option} variant="outlined" {...tagProps} />
                                                                  );
                                                                })
                                                              }
                                                              renderInput={(params) => (
                                                                <TextField
                                                                  className="enterprise-location"
                                                                  {...params}
                                                                  variant="outlined"
                                                                  label="Model"
                                                                  placeholder="Select Model"
                                                                  onBlur={(e) => {
                                                                    setFieldValue('model', e.target.value);
                                                                  }}
                                                                />
                                                              )}
                                                            />
                                                            {touched.model && errors.model && (
                                                              <FormHelperText
                                                                error
                                                                id="standard-weight-helper-text-email-login"
                                                                sx={{
                                                                  fontSize: '13.8px'
                                                                }}
                                                              >
                                                                {errors.model}
                                                              </FormHelperText>
                                                            )}
                                                          </FormControl>
                                                        </Grid>
                                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                                          <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                                                            <Autocomplete
                                                              // multiple
                                                              id="autocomplete"
                                                              limitTags={2}
                                                              options={row?.serialId?.map((option) => option)}
                                                              freeSolo
                                                              onChange={(event, value) => setFieldValue('serialId', value)}
                                                              renderTags={(value, getTagProps) =>
                                                                value.map((option, index) => {
                                                                  const { key, ...tagProps } = getTagProps({ index });
                                                                  return (
                                                                    <CustomChip key={key} label={option} variant="outlined" {...tagProps} />
                                                                  );
                                                                })
                                                              }
                                                              renderInput={(params) => (
                                                                <TextField
                                                                  className="enterprise-location"
                                                                  {...params}
                                                                  variant="outlined"
                                                                  label="Serial No."
                                                                  placeholder="Select Serial No."
                                                                  onBlur={(e) => {
                                                                    setFieldValue('serialId', e.target.value);
                                                                  }}
                                                                />
                                                              )}
                                                            />
                                                            {touched.serialId && errors.serialId && (
                                                              <FormHelperText
                                                                error
                                                                id="standard-weight-helper-text-email-login"
                                                                sx={{
                                                                  fontSize: '13.8px'
                                                                }}
                                                              >
                                                                {errors.serialId}
                                                              </FormHelperText>
                                                            )}
                                                          </FormControl>
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                          {errors.submit && (
                                                            <Box sx={{ mt: 3 }}>
                                                              <FormHelperText error>{errors.submit}</FormHelperText>
                                                            </Box>
                                                          )}
                                                          <Box
                                                            sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}
                                                          >
                                                            <Box sx={{ display: 'inline-block' }}>
                                                              <AnimateButton>
                                                                <Button
                                                                  className="updtdrvebtn"
                                                                  disableElevation
                                                                  disabled={isLoadingJobDetails}
                                                                  size="large"
                                                                  type="submit"
                                                                  variant="contained"
                                                                  color="secondary"
                                                                  style={{
                                                                    color: 'white',
                                                                    minWidth: '200px',
                                                                    margin: '0px auto'
                                                                  }}
                                                                  // onClick={setOpenRow(false)}
                                                                >
                                                                  {isLoadingJobDetails ? (
                                                                    <>
                                                                      <CircularProgress
                                                                        sx={{
                                                                          color: 'white',
                                                                          height: 20,
                                                                          width: 20
                                                                        }}
                                                                      />
                                                                    </>
                                                                  ) : (
                                                                    ` Update Drive `
                                                                  )}
                                                                </Button>
                                                              </AnimateButton>
                                                            </Box>
                                                          </Box>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Form>
                                            )}
                                          </Formik>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))}
                              </TableBody>
                            </Table>
                          ) : (
                            <StyledGridOverlay>
                              <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                                <g fill="none" fillRule="evenodd">
                                  <g transform="translate(24 31.67)">
                                    <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                                    <path
                                      className="ant-empty-img-1"
                                      d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                    />
                                    <path
                                      className="ant-empty-img-2"
                                      d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                    />
                                    <path
                                      className="ant-empty-img-3"
                                      d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                    />
                                  </g>
                                  <path
                                    className="ant-empty-img-3"
                                    d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                                  />
                                  <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                                    <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                                    <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                                  </g>
                                </g>
                              </svg>
                              <Box sx={{ mt: 1 }}>No data found!</Box>
                            </StyledGridOverlay>
                          )}
                        </>
                      )}
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
};

export default EnterpriseJobView;

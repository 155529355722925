import // useState
'react';

// MUI Imports
import Dialog from '@mui/material/Dialog';
// import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
// import { styled } from '@mui/material/styles';
// import UploadFileIcon from '@mui/icons-material/UploadFile';
import * as Yup from 'yup';
import {
  // CircularProgress,
  DialogContentText,
  IconButton
  // Select,
  // MenuItem,
  // FormControl,
  // InputLabel,
  // FormHelperText,
  // Grid,
  // OutlinedInput,
  // Typography,
  // Select,
  // MenuItem,
  // ListItem,
  // FormHelperText,
  // TextField
} from '@mui/material';
// import { Box } from '@mui/system';
// import { gridSpacing } from 'store/constant';
// import { useState } from 'react';
// import DescriptionIcon from '@mui/icons-material/Description';
import { Formik } from 'formik';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useGetSupportAdd } from 'hooks/useGetSupportTicket';
import { useMutation } from '@tanstack/react-query';
import SupportForm from './form';
const ContactDialog = ({
  open,
  setOpen,
  title,

  // isDeleteLoading,
  subTitle
}) => {
  const { mutate: addGetSupport, isPending: isLoadingGetSupport } = useMutation({
    mutationFn: useGetSupportAdd
  });

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
    // supportType: Yup.string().required('Please select a support type')
    supportType: Yup.string()
      .notOneOf(['Select Support Type'], 'Please select a valid support type')
      .required('Please select a support type')
  });
  return (
    <>
      <Dialog
        open={open}
        fullWidth={false}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        // className="dlte_file_mdlnw"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize',
            borderBottom: '1px solid #ccc',
            padding: '20px 24px',
            whiteSpace: 'pre-wrap'
          }}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>

        <DialogContent className="text-wrap-auto">
          <DialogContentText id="alert-dialog-description">{subTitle}</DialogContentText>
          <Formik
            initialValues={{
              subject: '',
              message: '',
              supportType: 'Select Support Type',
              files: [],
              submit: null
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const submitData = {
                subject: values?.subject,
                message: values?.message,
                supportType: values?.supportType,
                files: values?.files
              };
              addGetSupport(submitData, {
                onSuccess: (res) => {
                  if (res?.status == 200) {
                    toastConfig.type = 'success';
                    setToast(toastConfig, res?.data.message);
                    setOpen(false);
                  } else {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                    setOpen(false);
                  }
                },
                onError: (error) => {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              });
              // console.log('🚀 ~ ContactDialog ~ values:', submitData);
            }}
          >
            {({ errors, touched, handleChange, values, handleSubmit, setFieldValue }) => (
              <SupportForm
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                values={values}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                isLoadingGetSupport={isLoadingGetSupport}
                handleClose={handleClose}
              />
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactDialog;

import { useQuery } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';

export const useGetSupportAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.getsupportTicket}/save`, data);
  return res;
};

// List Api
export const useGetSupportTicketList = ({
  limit,
  sortOrder,
  sortField,
  page,
  status,
  supportType,
  ticketNo,
  subject,
  message,
  organizeName
}) => {
  return useQuery({
    queryKey: [
      'getSupportTicketListData',
      limit,
      sortOrder,
      sortField,
      page,
      status,
      supportType,
      ticketNo,
      subject,
      message,
      organizeName
    ],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.getsupportTicket}/${page}/${limit}`, {
          filters: {
            status: status ? status : null,
            supportType: supportType ? supportType : null,
            subject: subject || null,
            // message: message || null,
            organizationName: organizeName ? organizeName : null
          },
          sort: {
            sortField: sortField,
            sortOrder: sortOrder
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    staleTime: 0,
    enabled: Boolean(localStorage.getItem('userJwtToken')),
    // enabled: Boolean(limit) && Boolean(sortField),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//PermissionList

export const useGetPermissionsGetSupportList = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getPermissionsGetSupport', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance

        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/GetSupport`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType === 'SuperAdmin') && Boolean(usersType),
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

/// Details Api
export const useGetSupportTicketDetailsId = ({ id }) => {
  return useQuery({
    queryKey: ['getSupportTicketDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.getsupportTicket}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

///////
export const useGetPermissionsGetSupportTicketList = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getPermissionsGetSupport', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance

        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/SupportTickets`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    // enabled: Boolean(organizationType !== 'SuperAdmin') && Boolean(usersType),
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  CircularProgress,
  InputLabel,
  OutlinedInput,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  FormLabel,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  List
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';
import { Form, FieldArray } from 'formik';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckIcon from '@mui/icons-material/Check';

import {
  ColorPicker
  // useColor
} from 'react-color-palette';
import 'react-color-palette/css';
import { Fragment } from 'react';

const SubscriptionForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  // isSubmitting,
  touched,
  values,
  setFieldValue,
  isSubscriptionsLoading
}) => {
  const theme = useTheme();
  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              spacing={gridSpacing}
              sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', sm: 'row', md: 'row', lg: 'row', xl: 'row' } }}
            >
              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Typography variant="h4" component={'span'}>
                  Basic Details:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormGroup row>
                  <FormControlLabel
                    control={<Switch name="isPublic" checked={values?.isPublic} onChange={handleChange} onBlur={handleBlur} />}
                    label="Make this public"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormControl sx={{ ...theme.typography.customInput }} error={Boolean(touched.title && errors.title)} fullWidth>
              <InputLabel htmlFor="outlined-title">Title *</InputLabel>
              <OutlinedInput
                id="outlined-title"
                type="text"
                name="title"
                label="Title *"
                onBlur={handleBlur}
                value={values.title}
                onChange={handleChange}
              />
              {touched.title && errors.title && <FormHelperText error>{errors.title}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <FormControl sx={{ ...theme.typography.customInput }} error={Boolean(touched.price && errors.price)} fullWidth>
              <InputLabel htmlFor="outlined-price">Price *</InputLabel>
              <OutlinedInput
                id="outlined-price"
                type="number"
                name="price"
                label="Price *"
                onBlur={handleBlur}
                value={values.price}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start" sx={{ mt: 2 }}>
                    <span
                      style={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        color: theme.palette.text.secondary
                      }}
                    >
                      $
                    </span>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" sx={{ mt: 2 }}>
                    <span
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: 400,
                        color: theme.palette.text.secondary
                      }}
                    >
                      /user per month
                    </span>
                  </InputAdornment>
                }
              />
              {touched.price && errors.price && <FormHelperText error>{errors.price}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <FormControl sx={{ ...theme.typography.customInput }} error={Boolean(touched.interval && errors.interval)} fullWidth>
              <InputLabel htmlFor="outlined-interval">Interval *</InputLabel>
              <Select
                id="outlined-interval"
                name="interval"
                value={values.interval}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Interval *"
              >
                <MenuItem value="1 Month">1 Month</MenuItem>
                <MenuItem value="4 Months">4 Months</MenuItem>
                <MenuItem value="6 Months">6 Months</MenuItem>
              </Select>
              {touched.interval && errors.interval && <FormHelperText error>{errors.interval}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormControl
              sx={{ ...theme.typography.customInput }}
              className="ticket_detailstextarea"
              error={Boolean(touched.description && errors.description)}
              fullWidth
            >
              <InputLabel htmlFor="outlined-description">Description *</InputLabel>
              <OutlinedInput
                id="outlined-description"
                type="text"
                name="description"
                label="Description *"
                onBlur={handleBlur}
                // multiline
                // rows={2}
                value={values.description}
                onChange={handleChange}
              />
              {touched.description && errors.description && <FormHelperText error>{errors.description}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <FormControl sx={{ ...theme.typography.customInput }} error={Boolean(touched.localUser && errors.localUser)} fullWidth>
              <InputLabel htmlFor="outlined-local-user">Machine Specific User Count *</InputLabel>
              <OutlinedInput
                id="outlined-local-user"
                type="number"
                name="localUser"
                label="Machine Specific User Count *"
                onBlur={handleBlur}
                value={values.localUser}
                onChange={handleChange}
              />
              {touched.localUser && errors.localUser && <FormHelperText error>{errors.localUser}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <FormControl sx={{ ...theme.typography.customInput }} error={Boolean(touched.globalUser && errors.globalUser)} fullWidth>
              <InputLabel htmlFor="outlined-global-user">Global User Count *</InputLabel>
              <OutlinedInput
                id="outlined-global-user"
                type="number"
                name="globalUser"
                label="Global User Count *"
                onBlur={handleBlur}
                value={values.globalUser}
                onChange={handleChange}
              />
              {touched.globalUser && errors.globalUser && <FormHelperText error>{errors.globalUser}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
            <FormControl sx={{ ...theme.typography.customInput }} error={Boolean(touched.noOfAds && errors.noOfAds)} fullWidth>
              <InputLabel htmlFor="outlined-noOfAds">No. of Advertisements *</InputLabel>
              <OutlinedInput
                id="outlined-noOfAds"
                type="number"
                name="noOfAds"
                label="No. of Advertisements *"
                onBlur={handleBlur}
                value={values.noOfAds}
                onChange={handleChange}
              />
              {touched.noOfAds && errors.noOfAds && <FormHelperText error>{errors.noOfAds}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h4" component={'span'}>
              Add Features:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FieldArray
              name="features"
              render={(arrayHelpers) => (
                <Grid container spacing={{ xs: 1, sm: 1, md: 1, lg: gridSpacing, xl: gridSpacing }} alignItems="center">
                  {values.features.map((feature, index) => (
                    <Fragment key={index}>
                      <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                        <FormControl
                          sx={{ ...theme.typography.customInput }}
                          error={Boolean(touched.features?.[index] && errors.features?.[index])}
                          fullWidth
                        >
                          <InputLabel htmlFor={`feature-${index}`}>Feature *</InputLabel>
                          <OutlinedInput
                            id={`feature-${index}`}
                            type="text"
                            name={`features[${index}]`}
                            label={`Feature *`}
                            value={feature}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {touched.features?.[index] && errors.features?.[index] && (
                            <FormHelperText error>{errors.features?.[index]}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Grid
                          container
                          spacing={{ xs: 1, sm: 1, md: 1, lg: gridSpacing, xl: gridSpacing }}
                          alignItems="center"
                          sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                          {/* Show "Add" button for the last element */}
                          {index === values.features.length - 1 && (
                            <Grid item xs={6} sm={4} md={3} lg={6} xl={6}>
                              <Button
                                color="success"
                                fullWidth
                                variant="outlined"
                                onClick={() => arrayHelpers.push('')}
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                endIcon={<ControlPointIcon color="success" />}
                              >
                                Add
                              </Button>
                            </Grid>
                          )}

                          <Grid item xs={6} sm={4} md={3} lg={6} xl={6}>
                            <Button
                              disabled={values.features.length < 2}
                              fullWidth
                              variant="outlined"
                              color="error"
                              onClick={() => arrayHelpers.remove(index)}
                              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              endIcon={<RemoveCircleOutlineIcon color={values.features.length < 2 ? 'disabled' : 'error'} />}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      {values.features.length > 1 && (
                        <Grid item xs={12} sm={12}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  ))}
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormLabel>Add Plan Color</FormLabel>
            <div style={{ marginTop: 18, width: '100%', height: '100%' }}>
              <ColorPicker
                // height={150}
                // hideInput={['rgb', 'hsv']}
                color={values?.planColor}
                onChange={(color) => setFieldValue('planColor', color)}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <FormLabel>Preview</FormLabel>
            <Paper sx={{ p: 6, borderRadius: 2, boxShadow: 3, height: '100%' }} style={{ marginTop: 18 }}>
              <div className="subcription-content">
                <Typography variant="h2" align="left" sx={{ fontWeight: '600' }}>
                  {values?.title || 'The Plan Title'}
                </Typography>
                <Typography variant="body2" align="left" sx={{ mb: 2, pt: 2 }}>
                  {values?.description || 'The Plan Description'}
                </Typography>
                <Typography variant="h1" align="left" sx={{ fontWeight: '700', mb: 2 }}>
                  ${values?.price || '0.00'}{' '}
                  <span style={{ fontSize: '12.5px', color: '#a4a4a4', fontWeight: '500' }}>/{values?.interval}</span>
                </Typography>
                <Typography variant="h4" align="left" sx={{ fontWeight: '600' }}>
                  {values?.localUser || 0} Machine Specific User(s) and {values?.globalUser || 0} Global User(s) with{' '}
                  {values?.noOfAds ? values?.noOfAds + ' Advertisements' : 'No Advertisements'}
                </Typography>

                {Array.isArray(values?.features) && values.features.some((feature) => feature.trim()) ? (
                  <List sx={{ paddingTop: 5 }}>
                    {values.features
                      .filter((feature) => typeof feature === 'string' && feature.trim()) // Ensure valid, non-empty strings
                      .map((feature, index) => (
                        <ListItem key={index} sx={{ paddingLeft: 0 }}>
                          <ListItemIcon>
                            <CheckIcon
                              sx={{
                                color: values?.planColor?.hex
                                // fontWeight: '800'
                              }}
                              // color="secondary"
                            />
                          </ListItemIcon>
                          <ListItemText
                            sx={{
                              // color: '#FFBF00',
                              fontWeight: '800'
                            }}
                            primary={feature}
                          />
                          {/* <Typography variant="h5" component={'span'} style={{ fontWeight: 'bold' }}>
                          {feature}
                        </Typography> */}
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <List sx={{ paddingTop: 5 }}>
                    {[1, 2, 3, 4].map((index) => (
                      <ListItem key={index} sx={{ paddingLeft: 0 }}>
                        <ListItemIcon>
                          <CheckIcon
                            sx={{
                              color: values?.planColor?.hex
                              // fontWeight: '500'
                            }}
                            // color="secondary"
                          />
                        </ListItemIcon>
                        {/* <ListItemText
                        sx={{
                          // color: '#FFBF00',
                          fontWeight: '800'
                        }}
                        primary={'Listed Feature ' + index}
                      /> */}
                        <Typography variant="h5" component={'span'} style={{ fontWeight: '600' }}>
                          {'Listed Feature ' + index}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                )}
              </div>
              <Button
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 4,
                  p: 2,
                  color: '#FFF',
                  backgroundColor: values?.planColor?.hex,
                  '&:hover': {
                    backgroundColor: values?.planColor?.hex
                  },
                  fontSize: '1rem',
                  boxShadow: 'none'
                }}
                // onClick={callInvoiceScreen}
              >
                Select Plan
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, justifyContent: 'center', textAlign: 'center' }}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubscriptionsLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{
                    color: 'white',
                    minWidth: '200px',
                    margin: '0px auto'
                  }}
                >
                  {isSubscriptionsLoading ? (
                    <CircularProgress
                      sx={{
                        color: 'white',
                        height: 20,
                        width: 20
                      }}
                    />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </AnimateButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

SubscriptionForm.propTypes = {
  touched: PropTypes.object,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  setFieldValue: PropTypes.func
};

export default SubscriptionForm;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  IconButton,
  Skeleton,
  // Tooltip,
  Box,
  Chip
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import SkeletonTableView from 'ui-component/cards/Skeleton/TableView';
import {
  useGetOrganizationNewList,
  useGetPermissionsOrganizationNewList,
  useOrganizationNewStatusUpdate
} from 'hooks/useOrganizationNewHooks';
import { TooltipStatus } from 'ui-component/fields/tooltip.status';
import { getDomainName } from 'utils/commonUtil';
import ViewOrganization from './view-organization';
// import ViewTicket from '../recycler/recycler-user/recycler-tickets/view-ticket';

// Styled Grid Overlay for empty state
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));

// Comparator functions
const descendingComparator = (a, b, orderBy) => (b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0);

const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

const stableSort = (array, comparator) =>
  array
    ?.map((el, index) => [el, index])
    ?.sort((a, b) => comparator(a[0], b[0]) || a[1] - b[1])
    ?.map((el) => el[0]);

// Enhanced Table Head component
function EnhancedTableHead({ order, orderBy, onRequestSort, headersObj }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headersObj?.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} align="center">
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  })
);

// Row Renderer
function CollapsibleRow({ row, headersObj, permissionsList, openViewOrganization, setOpenViewOrganization, setAllOrgDetails }) {
  const navigate = useNavigate();
  const { mutate: updatedStatus, isPending: isLoadingStatus } = useOrganizationNewStatusUpdate();
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
      {headersObj &&
        headersObj.length > 0 &&
        headersObj.map((header) => (
          <TableCell align="center" key={header.id + row.id}>
            {header.id === 'actionUpdate' ? (
              <>
                {permissionsList?.showEdit && (
                  <>
                    <HtmlTooltip title={'Edit' + ' ' + row?.name}>
                      <IconButton onClick={() => navigate(`/dashboard/organizations/edit-${row.id}`)}>
                        <ModeEditOutlinedIcon />
                      </IconButton>
                    </HtmlTooltip>
                  </>
                )}

                {permissionsList?.showView && (
                  <HtmlTooltip title={'View' + '  ' + row?.name}>
                    <IconButton
                      onClick={() => {
                        setOpenViewOrganization(!openViewOrganization);
                        setAllOrgDetails(row);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </HtmlTooltip>
                )}
              </>
            ) : header.id === 'createdAt' ? (
              moment(row.createdAt).format('Do MMMM YYYY')
            ) : header.id === 'status' ? (
              header.changeState ? (
                <TooltipStatus
                  title={`Are you sure you want to ${!row?.enabled ? 'activate' : 'deactivate'} the ${row?.name}?`}
                  label={row?.enabled ? 'Active' : 'Inactive'}
                  backgroundColor={row?.enabled ? '#6fd74b' : '#f44336'}
                  onStatusUpdate={() => {
                    updatedStatus({
                      id: row?.id,
                      status: !row?.enabled
                    });
                  }}
                  isLoading={isLoadingStatus}
                />
              ) : (
                <Chip
                  label={row?.enabled ? 'Active' : 'Inactive'}
                  //color={row?.enabled ? 'success' : 'error'}
                  style={{
                    color: '#fff',
                    backgroundColor: row?.enabled === true ? '#6fd74b' : '#f44336'
                  }}
                  size="small"
                />
              )
            ) : header.id === 'types' ? (
              <> {row?.types && Array.isArray(row.types) && row.types.length > 0 ? row.types.join(', ') : 'N.A'}</>
            ) : header.id === 'website' ? (
              <>
                {row?.website ? (
                  <a href={row?.website} target="_blank" rel="noopener noreferrer">
                    {getDomainName(row?.website)}
                  </a>
                ) : (
                  'N.A.'
                )}
              </>
            ) : header.id === 'isConfirmed' ? (
              <>
                {' '}
                <Chip
                  label={row?.isConfirmed ? 'Verified' : 'Unverified'}
                  style={{
                    color: '#fff',
                    backgroundColor: row?.isConfirmed === true ? '#6fd74b' : '#f44336'
                  }}
                  size="small"
                />
              </>
            ) : (
              row[header.id] || 'N.A.'
            )}
          </TableCell>
        ))}
    </TableRow>
  );
}

// Main Component

const OrganizationNewList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  const [openViewOrganization, setOpenViewOrganization] = useState(false);
  const [allOrgDetails, setAllOrgDetails] = useState({});
  // const [firstPageVal, setFirstPageVal] = useState('');
  // const [lastPageVal, setLastPageVal] = useState('');
  // const userData = JSON.parse(localStorage.getItem('userData'));
  const [filterObj, setFilterObj] = useState({
    status: '',
    orgName: '',
    orgTypes: ''
  });
  const [openFilterModal, setOpenFilterModal] = useState(true);

  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const {
    data: permissionsList,

    isLoading: isLoadingPermissionsList
  } = useGetPermissionsOrganizationNewList({
    organizationType:
      organizationType === 'Service Provider' ? 'ServiceProvider' : organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
    usersType: usersType === 'Main POC' ? 'POC' : 'POC'
  });

  const { data: allOrgList, isLoading: isAllOrgDataLoading } = useGetOrganizationNewList({
    limit: rowsPerPage,
    sortOrder: order,
    sortField: orderBy,
    page: currentPage ? currentPage : previousPage,
    orgName: filterObj?.orgName || '',
    status: filterObj?.status || '',
    orgTypes: filterObj?.orgTypes || ''

    // previous: previousPage,
    // current: currentPage,
    // first: firstPageVal,
    // last: lastPageVal
  });
  // tableHeaders;
  const [headers, setHeaders] = useState([]);
  useEffect(() => {
    if (permissionsList) {
      let tableHeaders = permissionsList?.view?.tableHeaders;
      const actionObj = {
        id: 'actionUpdate',
        label: 'Action'
      };
      setHeaders([...tableHeaders, actionObj]);
    }
  }, [permissionsList]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setFirstPageVal(allOrgList?.data?.first);
    // setLastPageVal(allOrgList?.data?.last);
    setCurrentPage(newPage + 1);
    setPreviousPage(currentPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {isLoading && isLoadingPermissionsList ? (
        <>
          <SkeletonTableView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage name="All Organizations" obj={{ title: 'All Organizations', title2: '', url: '' }} />

          <MainCard
            title=""
            isButton={true}
            url="/dashboard/organizations/add"
            // isFilter={true}
            isFilter={
              permissionsList === null
                ? false
                : permissionsList?.view?.filters === undefined
                ? true
                : permissionsList?.view?.filters?.length > 0
                ? true
                : false
            }
            isOrgFilter={
              permissionsList === null
                ? false
                : permissionsList?.view?.filters === undefined
                ? true
                : permissionsList?.view?.filters?.length > 0
                ? true
                : false
            }
            openFilterAllModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
            isEnable={true}
            setFilterObj={setFilterObj}
            permissionsList={permissionsList}
            isLoadingPermissionsList={isLoadingPermissionsList}
          >
            <TableContainer>
              {isAllOrgDataLoading ? (
                <TableBody>
                  {[1, 2, 3, 4].map((i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton>
                          <Skeleton variant="circular" width={40} height={40} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : allOrgList?.data?.organizations === undefined || allOrgList?.data?.organizations.length == 0 ? (
                <StyledGridOverlay>
                  <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                    <g fill="none" fillRule="evenodd">
                      <g transform="translate(24 31.67)">
                        <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                        <path
                          className="ant-empty-img-1"
                          d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                          className="ant-empty-img-2"
                          d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                          className="ant-empty-img-3"
                          d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                      </g>
                      <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                      />
                      <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                      </g>
                    </g>
                  </svg>
                  <Box sx={{ mt: 1 }}>No data found!</Box>
                </StyledGridOverlay>
              ) : (
                <Table stickyHeader aria-label="sticky table" size="small">
                  <EnhancedTableHead headersObj={headers} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                  <TableBody>
                    {stableSort(allOrgList?.data?.organizations, getComparator(order, orderBy))?.map((row, i) => {
                      return (
                        <CollapsibleRow
                          headersObj={headers}
                          row={row}
                          key={i}
                          permissionsList={permissionsList}
                          openViewOrganization={openViewOrganization}
                          setOpenViewOrganization={setOpenViewOrganization}
                          setAllOrgDetails={setAllOrgDetails}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={allOrgList?.data?.rowPerPage}
              component="div"
              // rowsPerPageOptions={[20, 50, 100]}

              count={allOrgList?.data?.totalCount ? allOrgList?.data?.totalCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // getItemAriaLabel={getItemAriaLabel}
            />
            <ViewOrganization open={openViewOrganization} setOpen={setOpenViewOrganization} details={allOrgDetails} />
          </MainCard>
        </>
      )}
    </>
  );
};
EnhancedTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.any
};
export default OrganizationNewList;

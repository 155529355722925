import React from 'react';
import { Formik } from 'formik';

import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';

import { useParams } from 'react-router-dom';
import FormView from 'ui-component/cards/Skeleton/FormView';
import { useState, useEffect } from 'react';
// import { useGetOrgById, useGetOrgEditedList } from 'hooks/useOrganizationHooks';

import { UseGetRecycleEditedList, useGetRecycleId } from 'hooks/useRecycleHooks';
import ResellerRecyclerForm from '../form';

const ResellerEditRecycler = () => {
  let { id } = useParams();
  const { data: recycleDetails } = useGetRecycleId({
    id: id.slice(1)
  });
  const { mutate: updatedRecycle, isPending: isLoadingUpdateRecycle } = UseGetRecycleEditedList();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const phoneRegex = RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
  return (
    <>
      {isLoading ? (
        <>
          <FormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name={`Edit Recycler Organization`}
            obj={{ title: 'All Recyclers', title2: `Edit Recycler ${id}`, url: '/dashboard/reseller/recycler' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                associateEmpEmail: '',
                associateEmpPhone: '',
                // associateEmpOfficePhone: '',

                associateEmpAdd: '',

                EmpFullAddress: '',
                EmpCity: '',
                EmpState: '',
                EmpCountry: '',
                EmpPostal_Code: '',

                // SecondaryEmpName: '',
                SecondaryEmpEmail: '',
                SecondaryEmpPhone: '',
                // SecondaryEmpOfficePhone: '',
                SecondaryEmpAdd: '',

                RecyclerName: '',
                RecyclerWeb: '',

                title: '',
                firstName: '',
                lastName: '',
                designation: '',

                secondaryTitle: '',
                secondaryFirstName: '',
                secondaryLastName: '',
                secondaryDesignation: '',

                // RecycleNotes: '',

                // RecyclerCode: '',
                recycler_radius: '',
                isChecked: false,
                submit: null,
                primaryId: '',
                SecondaryId: '',
                addressId: '',
                serviceRadiusUnit: 'Mi',
                locationPickUp: []
              }}
              validationSchema={Yup.object().shape({
                RecyclerName: Yup.string()
                  .max(50, 'Recycler Name must be at most 50 characters')
                  .required('Recycler Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Recycler Name should not be in any special character'),
                // RecyclerCode: Yup.string().max(30, 'Must be of 30 characters').trim().required('Recycler Code is required'),

                RecyclerWeb: Yup.string().url('Please enter a valid Recycler Website Address'),
                // RecycleNotes: Yup.string().max(150, 'Must be of 150 characters').trim(),

                // associateEmpName: Yup.string().max(30, ' Must be of 30 characters').trim().required('Primary contact name is required '),
                associateEmpEmail: Yup.string().email('Invalid email address').required('Employee Email is required'),
                associateEmpPhone: Yup.string()
                  .required('Employee Phone is required')

                  .matches(phoneRegex, 'Employee Phone is not valid')
                  .max(15, 'Employee Phone maximum number should be of 15 characters')
                  .trim(),

                associateEmpAdd: Yup.string()
                  .max(150, 'Employee Address must be of 150 characters')
                  .trim()
                  .required(' Employee Address is required'),

                SecondaryEmpPhone: Yup.string()
                  .required('Employee Phone is required')

                  .matches(phoneRegex, 'Employee Phone is not valid')
                  .max(15, 'Employee Phone maximum number should be of 15 characters')
                  .trim(),
                // .phone('US', true, 'Must be a valid Employee Phone Number. ex.: +1 212-456-7890'),

                SecondaryEmpAdd: Yup.string()
                  .required('Employee Address is required')
                  .max(150, 'Employee Address must be of 30 characters')
                  .trim(),

                title: Yup.string().required('Prefix is required').trim(),
                firstName: Yup.string()
                  .max(50, 'Employee First Name must be at most 50 characters')
                  .required('Employee First Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not be in any special character'),

                lastName: Yup.string()
                  .max(50, 'Employee Last Name must be at most 50 characters')
                  .required('Employee Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not be in any special character'),

                designation: Yup.string()
                  .max(50, 'Employee Title must be of 50 characters')
                  .required('Employee Title  is required')

                  .trim(),

                secondaryTitle: Yup.string().required('Prefix is required').trim(),
                secondaryFirstName: Yup.string()
                  .required('Employee First Name is required')
                  .max(50, 'Employee First Name  must be at most 50 characters')
                  // .required('First Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name  should not be in any special character'),

                secondaryLastName: Yup.string()
                  .required('Employee Last Name is required')
                  .max(50, 'Employee Last Name must be at most 50 characters')
                  // .required('Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Nameshould not be in any special character'),

                secondaryDesignation: Yup.string()
                  .required('Employee Title is required')
                  .max(50, 'Employee Title must be of 50 characters')
                  .trim(),

                SecondaryEmpEmail: Yup.string().required('Employee Email is required').email('Invalid email address'),
                recycler_radius: Yup.number()
                  .positive('Recycler Service Radius  must contain a positive number')
                  // .integer('Recycler Service Radius  should contain an integer')
                  .when('areSeatsLimited', {
                    is: 'yes',
                    then: Yup.number().required().typeError('Recycler Service Radius  must be a number'),
                    otherwise: Yup.number().notRequired().typeError('Recycler Service Radius  must be a number')
                  })
                  .required('Recycler Service Radius is required'),
                locationPickUp: Yup.array().min(1, 'At least one location is required').required('Select Location is required ')
                // Yup.number()
                //   .positive('Recycler service radius must be a positive number')
                //   .integer('Recycler service radius must be a number')
              })}
              onSubmit={async (value) => {
                const _location = value.locationPickUp
                  .filter((item) => item?.id !== '')
                  .map((item) => ({
                    value: item.id,
                    isPrimary: item?.isPrimary ? item?.isPrimary : false // Mark the first location as primary, adjust this logic if needed
                    // You can include other properties from `item` if necessary
                  }));
                const submitData = {
                  name: value?.RecyclerName.trim(),
                  website: value?.RecyclerWeb.trim(),
                  id: id.slice(1),
                  isChecked: value?.isChecked,
                  serviceRadius: {
                    value: value?.recycler_radius,
                    unit: value?.serviceRadiusUnit
                  },

                  mainPOC: {
                    firstName: value?.firstName.trim(),
                    lastName: value?.lastName.trim(),
                    title: value?.designation,
                    prefix: value?.title,
                    email: value?.associateEmpEmail.trim(),
                    address: value?.associateEmpAdd.trim(),
                    phone: value?.associateEmpPhone.trim(),
                    id: value.primaryId
                  },
                  secondaryPOC: {
                    firstName: value?.secondaryFirstName.trim(),
                    lastName: value?.secondaryLastName.trim(),
                    title: value?.secondaryDesignation,
                    prefix: value?.secondaryTitle,
                    email: value?.SecondaryEmpEmail.trim(),
                    address: value?.SecondaryEmpAdd.trim(),
                    phone: value?.SecondaryEmpPhone.trim(),
                    id: value.SecondaryId
                  },
                  locations: _location
                };

                updatedRecycle(submitData);
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                <ResellerRecyclerForm
                  btnName="Update"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  recycleDetails={recycleDetails}
                  isLoadingRecycle={isLoadingUpdateRecycle}
                  id={id}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default ResellerEditRecycler;

// import { Menu } from '@mui/icons-material';
import {
  Box,
  Button,
  // DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  // InputLabel,
  // InputLabel,
  MenuItem,
  // Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
// import { textAlign } from '@mui/system';
import React from 'react';
// import { useEffect } from 'react';
// import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';

const FaqFilterForm = ({
  errors,
  faqFilters,
  //   handleBlur,
  handleChange,
  // handleSubmit,

  values,
  // setFieldValue,
  // setTouched,
  resetForm,
  // filterObj,
  // handleCloseFilter,
  setFilterObj
}) => {
  // console.log('🚀 ~ faqFilters:', faqFilters);
  // console.log('🚀 ~ errors:', errors);
  // console.log('🚀 ~ values:', values);
  // useEffect(() => {
  //   if (filterObj) {
  //     console.log('🚀 ~ useEffect ~ filterObj:', filterObj);
  //     setFieldValue('question', filterObj?.question ? filterObj?.question : '');
  //     setFieldValue('status', filterObj?.status ? filterObj?.status : '');
  //   }
  // }, [filterObj, setFieldValue]);
  const sowField = (filter, index) => {
    if (filter?.show)
      if (filter?.id == 'question')
        return (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine"
            // className="select-job-enterprise select_separate2"
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Question
            </Typography>
            <FormControl fullWidth variant="outlined">
              <TextField
                disabled={filter?.isStatic}
                // label="Organization Name"
                placeholder="Search Question"
                variant="outlined"
                type="text"
                fullWidth
                name="question"
                value={values?.question}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        );
      else if (filter?.id == 'status')
        return (
          <Grid
            key={index}
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-status-filter"

            // select-job-enterprise"
            // className="select-job-enterprise select_separate2"
            // className="select-job-enterprise "
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Status
            </Typography>
            <FormControl fullWidth variant="outlined" placeholder="Select Status">
              <Select
                disabled={filter?.isStatic}
                value={values?.status}
                onChange={handleChange}
                name="status"
                inputProps={{
                  'aria-label': 'Without label'
                }}
              >
                <MenuItem value="All">All</MenuItem>

                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      else return null;
    else return null;
  };
  return (
    <>
      <Grid container key={'faqFilter'} spacing={gridSpacing} sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}>
        {faqFilters?.map((filter, index) => sowField(filter, index))}
      </Grid>

      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              status: '',
              question: ''
            });
            // handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {errors?.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors?.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default FaqFilterForm;

// import { Menu } from '@mui/icons-material';
import {
  Box,
  Button,
  // DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  // InputLabel,
  // InputLabel,
  MenuItem,
  // Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
// import { textAlign } from '@mui/system';
import React from 'react';
// import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';

const EmployeeFilterForm = ({
  errors,
  //   handleBlur,
  handleChange,
  // handleSubmit,

  values,
  // setFieldValue,
  // setTouched,
  resetForm,
  // filterObj,
  // handleCloseFilter,
  setFilterObj
}) => {
  // console.log('🚀 ~ errors:', errors);
  console.log('🚀 ~ values:', values);
  // useEffect(() => {
  //   if (filterObj) {
  //     // console.log('🚀 ~ useEffect ~ filterObj:', filterObj);
  //     setFieldValue('orgName', filterObj?.orgName ? filterObj?.orgName : '');
  //     setFieldValue('status', filterObj?.status ? filterObj?.status : '');
  //     setFieldValue('orgTypes', filterObj?.orgTypes ? filterObj?.orgTypes : '');
  //   }
  // }, [filterObj, setFieldValue]);
  return (
    <>
      <Grid container spacing={gridSpacing} sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}>
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="select-machine-id inv-number select-machine"
          // className="select-job-enterprise select_separate2"
          alignItems={'center'}
        >
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Name
          </Typography>
          <FormControl fullWidth variant="outlined">
            <TextField
              // label="Organization Name"
              placeholder="Search Name"
              variant="outlined"
              type="text"
              fullWidth
              name="name"
              value={values?.name}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="select-status-filter"

          // select-job-enterprise"
          // className="select-job-enterprise select_separate2"
          // className="select-job-enterprise "
        >
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Status
          </Typography>
          <FormControl fullWidth variant="outlined" placeholder="Select Status">
            <Select
              value={values?.status}
              onChange={handleChange}
              name="status"
              inputProps={{
                'aria-label': 'Without label'
              }}
            >
              <MenuItem value="Active & Inactive">Active & Inactive</MenuItem>
              {/* <MenuItem value="">Select Status</MenuItem> */}
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="select-machine-id inv-number select-machine"
          // className="select-job-enterprise select_separate2"
          alignItems={'center'}
        >
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Email
          </Typography>
          <FormControl fullWidth variant="outlined">
            <TextField
              // label="Organization Name"
              placeholder="Search Email"
              variant="outlined"
              type="email"
              fullWidth
              name="email"
              value={values?.email}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="select-machine-id inv-number select-machine"
          // className="select-job-enterprise select_separate2"
          alignItems={'center'}
        >
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Title
          </Typography>
          <FormControl fullWidth variant="outlined">
            <TextField
              // label="Organization Name"
              placeholder="Search Title"
              variant="outlined"
              type="text"
              fullWidth
              name="title"
              value={values?.title}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="select-machine-id inv-number select-machine"
          // className="select-job-enterprise select_separate2"
          alignItems={'center'}
        >
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            RFID
          </Typography>
          <FormControl fullWidth variant="outlined">
            <TextField
              // label="Organization Name"
              placeholder="Search RFID"
              variant="outlined"
              type="text"
              fullWidth
              name="rfid"
              value={values?.rfid}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="select-machine-id inv-number select-machine"
          // className="select-job-enterprise select_separate2"
          alignItems={'center'}
        >
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Auth Pin
          </Typography>
          <FormControl fullWidth variant="outlined">
            <TextField
              // label="Organization Name"
              placeholder="Search Auth Pin"
              variant="outlined"
              type="number"
              fullWidth
              name="authPin"
              value={values?.authPin}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} className="select-status-filter">
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            View Scope
          </Typography>
          <FormControl fullWidth variant="outlined">
            <Select
              value={values?.showAdmin}
              onChange={handleChange}
              name="showAdmin"
              inputProps={{
                'aria-label': 'Without label'
              }}
            >
              <MenuItem value="All Employees">All Employees</MenuItem>
              <MenuItem value="Only Admin">Only Admin</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              status: '',
              name: '',
              email: '',
              title: '',
              rfid: '',
              authPin: '',
              showAdmin: ''
            });
            // handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {errors?.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors?.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default EmployeeFilterForm;

const initialState = {
  // isLoadingGlobalLoader: false,
  isLoadingGlobalLoader: false
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_STATUS':
      return {
        ...state,
        isLoadingGlobalLoader: action.payload // Expect boolean payload
      };
    default:
      return state;
  }
};

export default loadingReducer;

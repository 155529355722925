// import { Menu } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  // DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  // InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useGetAllOrganizationList } from 'hooks/useOrganizationHooks';
import { useGetAllMachineSettings, useGetAllMachineVersion } from 'hooks/useSettingsHooks';
// import { textAlign } from '@mui/system';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
// import { AutocompleteSelect } from 'ui-component/formUtil';

const MachineFilterForm = ({
  errors,
  //   handleBlur,
  handleChange,
  // handleSubmit,
  touched,
  values,
  setFieldValue,
  // setTouched,
  resetForm,
  filterObj,
  // handleCloseFilter,
  setFilterObj,
  permissionsList
}) => {
  useEffect(() => {
    if (filterObj) {
      // console.log('🚀 ~ useEffect ~ filterObj:', filterObj);
      setFieldValue('orgName', filterObj?.orgName ? filterObj?.orgName : '');
      setFieldValue('status', filterObj?.status ? filterObj?.status : '');
      setFieldValue('orgTypes', filterObj?.orgTypes ? filterObj?.orgTypes : '');
    }
  }, [filterObj, setFieldValue]);

  const { data: machineVersionList, isPending: isMachineVersionListLoading } = useGetAllMachineVersion({
    isActive: true
  });
  //, isPending: isMachineSettingsListLoading
  const { data: machineSettingsList } = useGetAllMachineSettings({
    isActive: true
  });
  // const { View } = permissionsList;
  // let { Filters } = View || [];

  let Filters;
  try {
    if (permissionsList && permissionsList.view) {
      ({ filters: Filters } = permissionsList.view);
    } else {
      Filters = [];
      // throw new Error('permissionsList or permissionsList.View is undefined');
      console.log('permissionsList or permissionsList.View is undefined');
    }
  } catch (error) {
    console.log('Error destructuring Filters:', error.message);
    Filters = []; // or some default value if needed
  }

  console.log('Filters Machine permissionsList', Filters);
  //, isLoading: orgListIsLoading
  const { data: orgBuyerList } = useGetAllOrganizationList({ filters: { onlyBuyers: true } });
  const { data: orgSellerList } = useGetAllOrganizationList({ filters: { onlySellers: true } });
  const [versionData, setVersionData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [buyerData, setBuyerData] = useState([]);
  const [sellerData, setSellerData] = useState([]);
  useEffect(() => {
    const versionData = machineVersionList?.map((item) => item?.version);
    // console.log('versionData', versionData);
    setVersionData(versionData);
    // setFieldValue('softwareVersion', versionData);
  }, [machineVersionList]);

  useEffect(() => {
    const modelData1 = machineSettingsList?.map((item) => item?.model);
    console.log('modelData Machine filter : ', modelData1);
    setModelData(modelData1);
    // setFieldValue('softwareVersion', versionData);
  }, [machineSettingsList]);

  useEffect(() => {
    const buyerData = orgBuyerList?.data?.map((item) => ({ label: item?.name, id: item?.id }));
    console.log('buyerData', buyerData);
    setBuyerData(buyerData);
    // setFieldValue('softwareVersion', versionData);
  }, [orgBuyerList]);

  useEffect(() => {
    const sellerData = orgSellerList?.data?.map((item) => ({ label: item?.name, id: item?.id }));
    console.log('sellerData', sellerData, orgSellerList);
    setSellerData(sellerData);
    // setFieldValue('softwareVersion', versionData);
  }, [orgSellerList]);

  return (
    <>
      <Grid key={'machinFilter'} container spacing={gridSpacing} sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}>
        {Filters &&
          Filters.length > 0 &&
          Filters.map((filter) => {
            if (filter) {
              if (filter?.id === 'buyer' && filter?.show) {
                {
                  /* Buyer Organization */
                }
                return (
                  <>
                    <Grid key={filter?._id} item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
                      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                        {filter.label}
                      </Typography>
                      <Autocomplete
                        // multiple
                        // disableClearable
                        options={buyerData || []}
                        getOptionLabel={(option) => option.label || ''}
                        value={values?.buyer || []} // Ensure default is an array
                        // onChange={(event, newValue) => setFieldValue('status', newValue)} // Use Formik's setFieldValue
                        onChange={(event, newValue) => handleChange({ target: { name: 'buyer', value: newValue } })}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                            console.log('option Buyers : ', option);
                            return (
                              <span
                                key={option}
                                {...tagProps}
                                style={{
                                  backgroundColor: '#6fd74b',
                                  color: '#fff',
                                  padding: '2px 8px',
                                  borderRadius: '4px',
                                  marginRight: '5px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {option}
                                <button
                                  onClick={onDelete} // Call the delete function for this tag
                                  style={{
                                    marginLeft: '5px',
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    cursor: 'pointer'
                                  }}
                                >
                                  ✖
                                </button>
                              </span>
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select Buyer Organization"
                            {...params}
                            variant="outlined"
                            // label="Types"
                            // placeholder="Select types"
                            InputLabelProps={
                              {
                                // shrink: true
                              }
                            }
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            style={{
                              // maxHeight: 200, // Adjust as needed
                              overflowY: 'auto'
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                );
              }

              if (filter?.id === 'seller' && filter?.show) {
                {
                  /* Seller Organization  */
                }
                return (
                  <>
                    <Grid key={filter?._id} item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
                      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                        {filter?.label}
                      </Typography>
                      <Autocomplete
                        // multiple
                        // disableClearable
                        options={sellerData || []}
                        getOptionLabel={(option) => option.label || ''}
                        value={values?.seller || []} // Ensure default is an array
                        // onChange={(event, newValue) => setFieldValue('status', newValue)} // Use Formik's setFieldValue
                        onChange={(event, newValue) => handleChange({ target: { name: 'seller', value: newValue } })}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                            return (
                              <span
                                key={option}
                                {...tagProps}
                                style={{
                                  backgroundColor: '#6fd74b',
                                  color: '#fff',
                                  padding: '2px 8px',
                                  borderRadius: '4px',
                                  marginRight: '5px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {option}
                                <button
                                  onClick={onDelete} // Call the delete function for this tag
                                  style={{
                                    marginLeft: '5px',
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    cursor: 'pointer'
                                  }}
                                >
                                  ✖
                                </button>
                              </span>
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select Seller Organization"
                            {...params}
                            variant="outlined"
                            // label="Types"
                            // placeholder="Select types"
                            InputLabelProps={
                              {
                                // shrink: true
                              }
                            }
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            style={{
                              // maxHeight: 200, // Adjust as needed
                              overflowY: 'auto'
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                );
              }

              if (filter?.id === 'client' && filter?.show) {
                {
                  /* Client Organization */
                }
                return (
                  <>
                    <Grid key={filter?._id} item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
                      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                        {filter?.label}
                      </Typography>
                      <Autocomplete
                        // multiple
                        // disableClearable
                        options={buyerData || []}
                        getOptionLabel={(option) => option.label || ''}
                        value={values?.client || []} // Ensure default is an array
                        // onChange={(event, newValue) => setFieldValue('status', newValue)} // Use Formik's setFieldValue
                        onChange={(event, newValue) => handleChange({ target: { name: 'client', value: newValue } })}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                            // console.log('option Buyers : ', option);
                            return (
                              <span
                                key={option}
                                {...tagProps}
                                style={{
                                  backgroundColor: '#6fd74b',
                                  color: '#fff',
                                  padding: '2px 8px',
                                  borderRadius: '4px',
                                  marginRight: '5px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {option}
                                <button
                                  onClick={onDelete} // Call the delete function for this tag
                                  style={{
                                    marginLeft: '5px',
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    cursor: 'pointer'
                                  }}
                                >
                                  ✖
                                </button>
                              </span>
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select Client Organization"
                            {...params}
                            variant="outlined"
                            // label="Types"
                            // placeholder="Select types"
                            InputLabelProps={
                              {
                                // shrink: true
                              }
                            }
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            style={{
                              // maxHeight: 200, // Adjust as needed
                              overflowY: 'auto'
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                );
              }

              if (filter?.id === 'rental' && filter?.show) {
                {
                  /* Rental Organization Organization */
                }
                return (
                  <>
                    <Grid key={filter?._id} item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
                      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                        {filter?.label}
                      </Typography>
                      <Autocomplete
                        // multiple
                        // disableClearable
                        options={buyerData || []}
                        value={values?.rental || []} // Ensure default is an array
                        getOptionLabel={(option) => option.label || ''}
                        // onChange={(event, newValue) => setFieldValue('status', newValue)} // Use Formik's setFieldValue
                        onChange={(event, newValue) => handleChange({ target: { name: 'rental', value: newValue } })}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                            // console.log('option Buyers : ', option);
                            return (
                              <span
                                key={option}
                                {...tagProps}
                                style={{
                                  backgroundColor: '#6fd74b',
                                  color: '#fff',
                                  padding: '2px 8px',
                                  borderRadius: '4px',
                                  marginRight: '5px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {option}
                                <button
                                  onClick={onDelete} // Call the delete function for this tag
                                  style={{
                                    marginLeft: '5px',
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    cursor: 'pointer'
                                  }}
                                >
                                  ✖
                                </button>
                              </span>
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select Rental Organization"
                            {...params}
                            variant="outlined"
                            // label="Types"
                            // placeholder="Select types"
                            InputLabelProps={
                              {
                                // shrink: true
                              }
                            }
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            style={{
                              // maxHeight: 200, // Adjust as needed
                              overflowY: 'auto'
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                );
              }

              // if (filter?.id === 'boughtRentedAssigned' && filter?.show) {
              //   {
              //     /* Bought or Rented Machines Organization */
              //   }
              //   return (
              //     <>
              //       <Grid key={filter?._id} item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
              //         <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              //           {filter?.label}
              //         </Typography>
              //         <Autocomplete
              //           multiple
              //           // disableClearable
              //           // options={buyerData || []}
              //           options={['Bought', 'Rented', 'Assigned']}
              //           value={values?.rented || []} // Ensure default is an array
              //           getOptionLabel={(option) => option.label || option}
              //           // onChange={(event, newValue) => setFieldValue('status', newValue)} // Use Formik's setFieldValue
              //           onChange={(event, newValue) => handleChange({ target: { name: 'rented', value: newValue } })}
              //           renderTags={(value, getTagProps) =>
              //             value.map((option, index) => {
              //               const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
              //               // console.log('option Buyers : ', option);
              //               return (
              //                 <span
              //                   key={option}
              //                   {...tagProps}
              //                   style={{
              //                     backgroundColor: '#6fd74b',
              //                     color: '#fff',
              //                     padding: '2px 8px',
              //                     borderRadius: '4px',
              //                     marginRight: '5px',
              //                     display: 'flex',
              //                     alignItems: 'center'
              //                   }}
              //                 >
              //                   {option}
              //                   <button
              //                     onClick={onDelete} // Call the delete function for this tag
              //                     style={{
              //                       marginLeft: '5px',
              //                       background: 'none',
              //                       border: 'none',
              //                       color: '#fff',
              //                       cursor: 'pointer'
              //                     }}
              //                   >
              //                     ✖
              //                   </button>
              //                 </span>
              //               );
              //             })
              //           }
              //           renderInput={(params) => (
              //             <TextField
              //               placeholder="Select Rental Organization"
              //               {...params}
              //               variant="outlined"
              //               // label="Types"
              //               // placeholder="Select types"
              //               InputLabelProps={
              //                 {
              //                   // shrink: true
              //                 }
              //               }
              //             />
              //           )}
              //           PaperComponent={(props) => (
              //             <Paper
              //               {...props}
              //               style={{
              //                 // maxHeight: 200, // Adjust as needed
              //                 overflowY: 'auto'
              //               }}
              //             />
              //           )}
              //         />
              //       </Grid>
              //     </>
              //   );
              // }

              if (filter?.id === 'status' && filter?.show) {
                {
                  /* Status  */
                }
                return (
                  <>
                    <Grid key={filter?._id} item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
                      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                        {filter?.label}
                      </Typography>
                      <Autocomplete
                        multiple
                        // disableClearable
                        options={['Build', 'Preparation', 'Online', 'Offline', 'Maintenance']}
                        getOptionLabel={(option) => option.label || option}
                        value={values?.status || []} // Ensure default is an array
                        onChange={(event, newValue) => setFieldValue('status', newValue)} // Use Formik's setFieldValue
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                            return (
                              <span
                                key={option}
                                {...tagProps}
                                style={{
                                  backgroundColor: '#6fd74b',
                                  color: '#fff',
                                  padding: '2px 8px',
                                  borderRadius: '4px',
                                  marginRight: '5px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {option}
                                <button
                                  onClick={onDelete} // Call the delete function for this tag
                                  style={{
                                    marginLeft: '5px',
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    cursor: 'pointer'
                                  }}
                                >
                                  ✖
                                </button>
                              </span>
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select Status"
                            {...params}
                            variant="outlined"
                            // label="Types"
                            // placeholder="Select types"
                            InputLabelProps={
                              {
                                // shrink: true
                              }
                            }
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            style={{
                              // maxHeight: 200, // Adjust as needed
                              overflowY: 'auto'
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                );
              }

              if (filter?.id === 'machineType' && filter?.show) {
                {
                  /* Machine Type  */
                }
                return (
                  <>
                    <Grid
                      key={filter?._id}
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      className="select-status-filter"
                      // className="select-job-enterprise select_separate2"
                      // className="select-job-enterprise "
                    >
                      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                        {filter?.label}
                      </Typography>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        placeholder="Select Machine Type"
                        // error={Boolean(touched.status && errors.status)}
                      >
                        {/* <InputLabel>Status</InputLabel> */}
                        <Select
                          value={values?.type || 'all'}
                          onChange={handleChange}
                          name="type"
                          inputProps={{ 'aria-label': 'Without label' }}
                          labelId="retailer-primary-machine-label"
                          // label="Select Machine Type"
                        >
                          <MenuItem value="all">All</MenuItem>
                          {/* <MenuItem value="">Select Status</MenuItem> */}
                          <MenuItem value="E">Enterprise (E)</MenuItem>
                          <MenuItem value="R">Retailer (R)</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                );
              }

              if (filter?.id === 'nickname' && filter?.show) {
                {
                  /* Nickname */
                }
                return (
                  <>
                    <Grid
                      key={filter?._id}
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      className="select-machine-id inv-number select-machine"
                      // className="select-job-enterprise select_separate2"
                      alignItems={'center'}
                    >
                      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                        {filter?.label}
                      </Typography>
                      <FormControl fullWidth variant="outlined" error={Boolean(touched.nickname && errors.nickname)}>
                        <TextField
                          // label="Organization Name"
                          placeholder="Select Nickname"
                          variant="outlined"
                          type="text"
                          fullWidth
                          name="nickname"
                          value={values?.nickname}
                          onChange={handleChange}
                          error={Boolean(touched.nickname && errors.nickname)}
                        />
                        {touched.nickname && errors.nickname && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.nickname}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                );
              }

              if (filter?.id === 'machineModel' && filter?.show) {
                {
                  /* Machine Model  */
                }
                return (
                  <>
                    <Grid key={filter?._id} item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
                      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                        {filter?.label}
                      </Typography>
                      <Autocomplete
                        // multiple
                        // disableClearable
                        options={modelData || []}
                        value={values?.model || []} // Ensure default is an array
                        getOptionLabel={(option) => (option.length > 0 ? option : '')}
                        // onChange={(event, newValue) => setFieldValue('status', newValue)} // Use Formik's setFieldValue
                        onChange={(event, newValue) => handleChange({ target: { name: 'model', value: newValue } })}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                            return (
                              <span
                                key={option}
                                {...tagProps}
                                style={{
                                  backgroundColor: '#6fd74b',
                                  color: '#fff',
                                  padding: '2px 8px',
                                  borderRadius: '4px',
                                  marginRight: '5px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {option}
                                <button
                                  onClick={onDelete} // Call the delete function for this tag
                                  style={{
                                    marginLeft: '5px',
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    cursor: 'pointer'
                                  }}
                                >
                                  ✖
                                </button>
                              </span>
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select Machine Model"
                            {...params}
                            variant="outlined"
                            // label="Types"
                            // placeholder="Select types"
                            InputLabelProps={
                              {
                                // shrink: true
                              }
                            }
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            style={{
                              // maxHeight: 200, // Adjust as needed
                              overflowY: 'auto'
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                );
              }

              if (filter?.id === 'softwareVersions' && filter?.show) {
                {
                  /* Software Version  */
                }
                return (
                  <>
                    <Grid key={filter?._id} item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
                      <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                        {filter?.label}
                      </Typography>
                      <Autocomplete
                        multiple
                        options={versionData || []}
                        getOptionLabel={(option) => option.label || option}
                        value={values?.softwareVersion || []} // Ensure default is an array
                        onChange={(event, newValue) => setFieldValue('softwareVersion', newValue)} // Use Formik's setFieldValue
                        loading={isMachineVersionListLoading}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => {
                            const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                            return (
                              <span
                                key={option}
                                {...tagProps}
                                style={{
                                  backgroundColor: '#6fd74b',
                                  color: '#fff',
                                  padding: '2px 8px',
                                  borderRadius: '4px',
                                  marginRight: '5px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {option}
                                <button
                                  onClick={onDelete} // Call the delete function for this tag
                                  style={{
                                    marginLeft: '5px',
                                    background: 'none',
                                    border: 'none',
                                    color: '#fff',
                                    cursor: 'pointer'
                                  }}
                                >
                                  ✖
                                </button>
                              </span>
                            );
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            placeholder="Select Software Version"
                            {...params}
                            variant="outlined"
                            // label="Types"
                            // placeholder="Select types"
                            InputLabelProps={
                              {
                                // shrink: true
                              }
                            }
                          />
                        )}
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            style={{
                              // maxHeight: 200, // Adjust as needed
                              overflowY: 'auto'
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                );
              }
            }
          })}
      </Grid>

      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              status: '',
              orgName: '',
              orgTypes: ''
            });
            // handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              //   disabled={isLoadingUpdateInvoiceStatus}
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default MachineFilterForm;

import { useTheme } from '@emotion/react';
import { Box, Button, FormControl, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { gridSpacing } from 'store/constant';

const LocationFilterForm = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  setFieldValue,
  resetForm,
  filterObj,
  setFilterObj,
  locFilters
}) => {
  const exceptThisSymbols = ['+', '-', '#', '%', '^', '.'];
  const theme = useTheme();
  useEffect(() => {
    if (filterObj) {
      setFieldValue('location_name', filterObj?.location_name || '');
      setFieldValue('location_status', filterObj?.location_status || '');
    }
  }, [filterObj, setFieldValue]);

  return (
    <>
      <Grid container spacing={gridSpacing} sx={{ marginBottom: 2, alignItems: 'center', padding: '0px 20px' }}>
        {locFilters?.map((filter, index) => {
          if (filter.id === 'locationName' && filter?.show)
            return (
              <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={6} className="select-machine-id inv-number select-machine">
                <FormControl fullWidth variant="outlined" sx={{ ...theme.typography.customInput }}>
                  <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 600, paddingBottom: 1 }}>
                    Location Name
                  </Typography>
                  <TextField
                    id="location-name"
                    // label="Location Name"
                    placeholder="Search Location Name"
                    variant="outlined"
                    type="text"
                    name="location_name"
                    value={values?.location_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.location_name && errors?.location_name)}
                    helperText={touched?.location_name && errors?.location_name}
                    inputProps={{
                      onPaste: (e) => e.preventDefault(),
                      onCopy: (e) => e.preventDefault(),
                      onKeyDown: (e) => {
                        if (exceptThisSymbols.includes(e.key)) e.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            );
          else if (filter.id === 'status' && filter?.show)
            return (
              <Grid key={index} item xs={12} sm={12} md={6} lg={6} xl={6} className="select-status-filter">
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={Boolean(touched.location_status && errors.location_status)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 600, paddingBottom: 1 }}>
                    Status
                  </Typography>
                  {/* <InputLabel id="location-status-label">Status</InputLabel> */}
                  <Select
                    // labelId="location-status-label"
                    // id="location-status"
                    name="location_status"
                    value={values?.location_status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Status"
                    inputProps={{
                      'aria-label': 'Without label'
                    }}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="true">Active</MenuItem>
                    <MenuItem value="false">Inactive</MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText>{touched?.location_status && errors?.location_status}</FormHelperText>
              </Grid>
            );
          else null;
        })}

        {/* Status Field */}
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0px 20px 20px 20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              location_name: '',
              location_status: ''
            });
          }}
          sx={{ marginRight: 1 }}
        >
          Clear All
        </Button>
        <Button variant="contained" type="submit" color="primary" sx={{ color: '#fff' }}>
          Search
        </Button>
      </Box>
    </>
  );
};

export default LocationFilterForm;

import React from 'react';
import { Form, Formik } from 'formik';
import EmployeeFilterForm from './EmployeeFilterForm';

const EmployeeFilter = ({ setFilterObj, filterObj, isExistingFilter }) => {
  return (
    <Formik
      initialValues={{
        status: 'Active & Inactive',
        name: '',
        email: '',
        title: '',
        rfid: '',
        authPin: '',
        showAdmin: 'All Employees'
      }}
      onSubmit={(values) => {
        console.log('Submitted values:', values);
        setFilterObj({
          status: values?.status === 'Active & Inactive' ? '' : values?.status,
          name: values?.name,
          email: values?.email,
          title: values?.title,
          rfid: values?.rfid,
          authPin: values?.authPin,
          showAdmin: values?.showAdmin
        });
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
        <Form>
          <EmployeeFilterForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            setTouched={setTouched}
            resetForm={resetForm}
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            isExistingFilter={isExistingFilter}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EmployeeFilter;

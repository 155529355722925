import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router-dom';
import Location from 'views/pages/location';
import LocationAdd from 'views/pages/location/add';
import LocationEdit from 'views/pages/location/edit';
import JobUrL from 'views/job-url';
import EnterpriseUserAdd from 'views/pages/enterprise/enterprise-user/add';
import EnterpriseUserEdit from 'views/pages/enterprise/enterprise-user/edit';
import EnterpriseUserLocationList from 'views/pages/enterprise/enterprise-user/enterprise-user-location';

import EnterpriseUserTicketList from 'views/pages/enterprise/enterprise-user/enterprise-tickets';
import EnterpriseUserTicketAdd from 'views/pages/enterprise/enterprise-user/enterprise-tickets/add';
import EnterpriseUserMachines from 'views/pages/enterprise/enterprise-user/enterprise-machines';
import EnterpriseLocationAdd from 'views/pages/enterprise/enterprise-user/enterprise-user-location/add';
import EnterpriseLocationEdit from 'views/pages/enterprise/enterprise-user/enterprise-user-location/edit';
import Ticket from 'views/pages/ticket';
import TicketAdd from 'views/pages/ticket/add';
import TicketEdit from 'views/pages/ticket/edit';
import EnterpriseJobList from 'views/pages/enterprise/enterprise-user/enterprise-jobs/EnterpriseJobList';
import EnterpriseJobView from 'views/pages/enterprise/enterprise-user/enterprise-jobs/enterprise-job-edit';
import UpdateOrganization from 'views/accounts/update-organization';
import UpdateRetailerOrganization from 'views/accounts/profile/update-retailer-organization';
import UpdateEnterpriseOrganization from 'views/accounts/profile/update-enterprise-organization';
import RecyclerCreateInvoice from 'views/pages/recycler/recycler-user/recycler-create-invoice';
import SuperAdminSettings from 'views/pages/superAdminSettings';
import Subscriptions from 'views/pages/subscriptions';
import SubscriptionsAdd from 'views/pages/subscriptions/add';
import SubscriptionsEdit from 'views/pages/subscriptions/edit';
import ControlPanel from 'views/pages/control-panel';

import ResellerMachines from 'views/pages/reseller/reseller-user/reseller-machines';
import ResellerRecyclerList from 'views/pages/reseller/reseller-user/reseller-recycler';
import ResellerEnterpriseList from 'views/pages/reseller/reseller-user/reseller-enterprise';
import ResellerLocationList from 'views/pages/reseller/reseller-user/reseller-location';
import ResellerAddLocation from 'views/pages/reseller/reseller-user/reseller-location/add';
import ResellerEditLocation from 'views/pages/reseller/reseller-user/reseller-location/edit';
import ResellerAddEnterprise from 'views/pages/reseller/reseller-user/reseller-enterprise/add';
import ResellerEditEnterprise from 'views/pages/reseller/reseller-user/reseller-enterprise/edit';
import ResellerRetailerList from 'views/pages/reseller/reseller-user/reseller-retailer';
import ResellerAddRetailer from 'views/pages/reseller/reseller-user/reseller-retailer/add';
import ResellerEditRetailer from 'views/pages/reseller/reseller-user/reseller-retailer/edit';
import ResellerAddRecycler from 'views/pages/reseller/reseller-user/reseller-recycler/add';
import ResellerEditRecycler from 'views/pages/reseller/reseller-user/reseller-recycler/edit';
// import OrganizationNew from 'views/pages/organizations-new';
import OrganizationNewAdd from 'views/pages/organizations-new/add';
import OrganizationNewList from 'views/pages/organizations-new';
import OrganizationsNewEdit from 'views/pages/organizations-new/edit';
import EmployeeList from 'views/pages/employees';
import EmployeeAdd from 'views/pages/employees/add';
import EmployeeEdit from 'views/pages/employees/edit';
import GetSupport from 'views/pages/get-support';
import Faq from 'views/accounts/faqs';
import SuperAdminFaq from 'views/pages/super-admin-faq';
import FaqAdd from 'views/pages/super-admin-faq/add';
import FaqEdit from 'views/pages/super-admin-faq/edit';
import SupportTicketList from 'views/pages/support-ticket';

// import RetailerUserAdd from 'views/pages/retailer/retailer-user/user/add';
// import RetailerUserEdit from 'views/pages/retailer/retailer-user/user/edit';
// import RetailerUserMachines from 'views/pages/retailer/retailer-user/retailer-machines';
// import RetailerJobList from 'views/pages/retailer/retailer-jobs';
// import RetailerJobView from 'views/pages/retailer/retailer-jobs/retailer-jobs-edit';
// import RetailerUserLocationList from 'views/pages/retailer/retailer-user/retailer-locations';
// import EnterpriseMachine from 'views/pages/enterprise/enterprise-machines';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));

//  page routing
const MachinePage = Loadable(lazy(() => import('views/pages/machines')));
const MachineEditPage = Loadable(lazy(() => import('views/pages/machines/edit')));
const MachineAddPage = Loadable(lazy(() => import('views/pages/machines/add')));
// const MachineAddBatchPage = Loadable(lazy(() => import('views/pages/machines/add/MachinesAddBatch')));
const MachineCreateInvoicePage = Loadable(lazy(() => import('views/pages/machines/add/invoice')));
const MachineSubscribePage = Loadable(lazy(() => import('views/pages/machines/subcription')));
const RentMachineAddPage = Loadable(lazy(() => import('views/pages/machines/add/RentMachineAdd')));

// const OrganizationPage = Loadable(lazy(() => import('views/pages/organizations')));
// const OrganizationEditPage = Loadable(lazy(() => import('views/pages/organizations/edit')));
// const OrganizationAddPage = Loadable(lazy(() => import('views/pages/organizations/add')));
const UserPage = Loadable(lazy(() => import('views/pages/user')));
const UserAddPage = Loadable(lazy(() => import('views/pages/user/add')));
const UserEditPage = Loadable(lazy(() => import('views/pages/user/edit')));
// const RoleManagementPage = Loadable(lazy(() => import('views/pages/role-management')));
// const RoleManagementEditPage = Loadable(lazy(() => import('views/pages/role-management/edit')));
// const RoleManagementAddPage = Loadable(lazy(() => import('views/pages/role-management/add')));
const ProfilePage = Loadable(lazy(() => import('views/accounts/profile')));
// const UserAddUnderOrg = Loadable(lazy(() => import('views/pages/user-org/add')));
// const UserUnderOrg = Loadable(lazy(() => import('views/pages/user-org')));
// const UserEditUnderOrg = Loadable(lazy(() => import('views/pages/user-org/edit')));
const RedirectingEmailPage = Loadable(lazy(() => import('views/pages/redirecting-emailpage')));
const ForgotPasswordPage = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword')));
const RecyclerList = Loadable(lazy(() => import('views/pages/recycler')));
const EnterpriseList = Loadable(lazy(() => import('views/pages/enterprise')));
const RetailerList = Loadable(lazy(() => import('views/pages/retailer')));
// const PublicUserList = Loadable(lazy(() => import('views/pages/retailer/retailer-user/user')));
const RecyclerAddPage = Loadable(lazy(() => import('views/pages/recycler/add')));
const RecyclerEditPage = Loadable(lazy(() => import('views/pages/recycler/edit')));

const RecyclerUserList = Loadable(lazy(() => import('views/pages/recycler/recycler-user/user')));
const RecyclerUserMachine = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-machine')));
const RecyclerUserJobs = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-jobs')));
const RecyclerUserLocation = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-locations')));
const RecyclerUserTicket = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-tickets')));
const RecyclerUserLocationAdd = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-locations/add')));
const RecyclerUserLocationEdit = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-locations/edit')));
const RecyclerUserJobView = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-jobs/view-details')));
const RecyclerExistingInvoices = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-existing-invoices')));
const RecyclerInvoices = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-invoices')));
const RecyclerInvoicesAdd = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-invoices/add')));
const RecyclerInvoicesEdit = Loadable(lazy(() => import('views/pages/recycler/recycler-user/recycler-invoices/edit')));
// import RecyclerExistingInvoices from 'views/pages/recycler/';
// import RecyclerInvoices from 'views/pages/recycler/recycler-user/recycler-invoices';
const EnterpriseUserList = Loadable(lazy(() => import('views/pages/enterprise/enterprise-user')));
const EnterpriseAddPage = Loadable(lazy(() => import('views/pages/enterprise/add')));
const EnterpriseEditPage = Loadable(lazy(() => import('views/pages/enterprise/edit')));
const EnterpriseExistingInvoice = Loadable(lazy(() => import('views/pages/enterprise/enterprise-user/existing-invoices')));
const RetailerAddPage = Loadable(lazy(() => import('views/pages/retailer/add')));
const RetailerEditPage = Loadable(lazy(() => import('views/pages/retailer/edit')));
const ChangePasswordPage = Loadable(lazy(() => import('views/accounts/change-password')));
const ResetPasswordPage = Loadable(lazy(() => import('views/accounts/reset-password')));
const JobListPage = Loadable(lazy(() => import('views/pages/job')));
// const JobAddPage = Loadable(lazy(() => import('views/pages/job/add')));
const JobEditPage = Loadable(lazy(() => import('views/pages/job/edit')));

// const ProfilePage = Loadable(lazy(() => import('views/accounts/profile/index')));

const RetailerUserJobsList = Loadable(lazy(() => import('views/pages/retailer/retailer-user/retailer-jobs')));
const RetailerUserJobView = Loadable(lazy(() => import('views/pages/retailer/retailer-user/retailer-jobs/jobs-views')));
const RetailerUserMachineList = Loadable(lazy(() => import('views/pages/retailer/retailer-user/retailer-machines')));
// const RetailerUserList = Loadable(lazy(() => import('views/pages/retailer/retailer-user/user')));
const RetailerUserLocationsList = Loadable(lazy(() => import('views/pages/retailer/retailer-user/retailer-locations')));
const RetailerUserLocationsAdd = Loadable(lazy(() => import('views/pages/retailer/retailer-user/retailer-locations/add')));
const RetailerUserLocationsEdit = Loadable(lazy(() => import('views/pages/retailer/retailer-user/retailer-locations/edit')));
const RetailerUserTicketsList = Loadable(lazy(() => import('views/pages/retailer/retailer-user/retailer-tickets')));
const RetailerUserTicketsAdd = Loadable(lazy(() => import('views/pages/retailer/retailer-user/retailer-tickets/add')));
const RetailerUserInvoice = Loadable(lazy(() => import('views/pages/retailer/retailer-user/existing-invoices')));
// invoices
const InvoiceList = Loadable(lazy(() => import('views/pages/invoices')));
const InvoiceAdd = Loadable(lazy(() => import('views/pages/invoices/add')));
const InvoiceEdit = Loadable(lazy(() => import('views/pages/invoices/edit')));

///Reseller

const ResellerList = Loadable(lazy(() => import('views/pages/reseller')));
const ResellerAdd = Loadable(lazy(() => import('views/pages/reseller/add')));
const ResellerEdit = Loadable(lazy(() => import('views/pages/reseller/edit')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (userRoot) => [
  {
    path: '/',
    element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <Navigate to="/dashboard/default" /> : <MinimalLayout />,
    children: [
      {
        path: '/',
        element: <AuthLogin3 />
      },
      {
        path: '/confirm-password',
        element: <RedirectingEmailPage />
      },

      {
        path: '/forgot-password',
        element: <ForgotPasswordPage />
      },

      {
        path: '/reset-password',
        element: <ResetPasswordPage />
      },

      // {
      //   path: '/pages/register/register3',
      //   element: <AuthRegister3 />
      // }
      {
        path: 'job/:id',
        element: <JobUrL />
      }
    ]
  },

  {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
      {
        path: '/dashboard/default',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <DashboardDefault /> : <Navigate to="/" />
      },
      {
        path: 'dashboard',
        children: [
          {
            path: 'default',
            element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <DashboardDefault /> : <Navigate to="/" />
          }
        ]
      },
      // {
      //   path: orgType === 'enterprise' ? 'enterprise/machines' : 'machines',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <MachinePage /> : <Navigate to="/" />
      // },
      {
        path: 'machines',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <MachinePage /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/machines',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserMachines /> : <Navigate to="/" />
      },
      {
        path: 'machines/add/:machineType',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <MachineAddPage /> : <Navigate to="/" />
      },
      {
        path: 'machines/subcription',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <MachineSubscribePage /> : <Navigate to="/" />
      },
      {
        path: 'machines/rent-machine',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RentMachineAddPage /> : <Navigate to="/" />
      },
      {
        path: 'machines/create-invoice',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <MachineCreateInvoicePage /> : <Navigate to="/" />
      },
      {
        path: 'machines/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <MachineEditPage /> : <Navigate to="/" />
      },
      // {
      //   path: 'organizations',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <OrganizationPage /> : <Navigate to="/" />
      // },
      // {
      //   path: 'organizations/edit:id',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <OrganizationEditPage /> : <Navigate to="/" />
      // },
      // {
      //   path: 'organizations/add',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <OrganizationAddPage /> : <Navigate to="/" />
      // },
      // //Users under Org
      // {
      //   path: 'organizations/user-org',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserUnderOrg /> : <Navigate to="/" />
      // },

      // {
      //   path: 'organizations/user-org/add',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserAddUnderOrg /> : <Navigate to="/" />
      // },
      // {
      //   path: 'organizations/user-org/edit:id',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserEditUnderOrg /> : <Navigate to="/" />
      // },
      {
        path: 'users',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserPage /> : <Navigate to="/" />
      },
      {
        path: 'user/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserAddPage /> : <Navigate to="/" />
      },
      {
        path: 'user/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UserEditPage /> : <Navigate to="/" />
      },

      {
        path: 'recycler',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerList /> : <Navigate to="/" />
      },
      {
        path: 'recycler/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerAddPage /> : <Navigate to="/" />
      },

      {
        path: 'recycler/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerEditPage /> : <Navigate to="/" />
      },
      {
        path: 'recycler/user',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserList /> : <Navigate to="/" />
      },
      {
        path: 'recycler/machines',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserMachine /> : <Navigate to="/" />
      },
      {
        path: 'recycler/jobs',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserJobs /> : <Navigate to="/" />
      },
      {
        path: 'recycler/jobs/view-details:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserJobView /> : <Navigate to="/" />
      },
      {
        path: 'recycler/locations',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserLocation /> : <Navigate to="/" />
      },
      {
        path: 'recycler/tickets',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserTicket /> : <Navigate to="/" />
      },
      {
        path: 'recycler/locations/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserLocationAdd /> : <Navigate to="/" />
      },
      {
        path: 'recycler/locations/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerUserLocationEdit /> : <Navigate to="/" />
      },

      {
        path: 'recycler/existing/invoices',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerExistingInvoices /> : <Navigate to="/" />
      },

      {
        path: 'recycler/create/invoices-ticket:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerCreateInvoice /> : <Navigate to="/" />
      },

      {
        path: 'recycler/invoices',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerInvoices /> : <Navigate to="/" />
      },
      {
        path: 'recycler/invoices/create',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerInvoicesAdd /> : <Navigate to="/" />
      },
      {
        path: 'recycler/invoices/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RecyclerInvoicesEdit /> : <Navigate to="/" />
      },
      {
        path: 'enterprise',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseList /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseAddPage /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseEditPage /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/existing/invoices',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseExistingInvoice /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/user',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserList /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/user/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserAdd /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/user/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserEdit /> : <Navigate to="/" />
      },

      {
        path: 'enterprise/location',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserLocationList /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/location/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseLocationAdd /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/location/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseLocationEdit /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/tickets',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserTicketList /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/tickets/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseUserTicketAdd /> : <Navigate to="/" />
      },

      {
        path: 'retailer',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerList /> : <Navigate to="/" />
      },
      {
        path: 'retailer/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerAddPage /> : <Navigate to="/" />
      },
      {
        path: 'retailer/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerEditPage /> : <Navigate to="/" />
      },

      // {
      //   path: 'retailer/user',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserList /> : <Navigate to="/" />
      // },
      // {
      //   path: 'retailer/user/add',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserAdd /> : <Navigate to="/" />
      // },

      // {
      //   path: 'retailer/user/edit:id',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserEdit /> : <Navigate to="/" />
      // },
      {
        path: 'retailer/locations',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserLocationsList /> : <Navigate to="/" />
      },
      {
        path: 'retailer/locations/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserLocationsAdd /> : <Navigate to="/" />
      },
      {
        path: 'retailer/locations/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserLocationsEdit /> : <Navigate to="/" />
      },
      {
        path: 'retailer/machines',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserMachineList /> : <Navigate to="/" />
      },
      {
        path: 'retailer/jobs',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserJobsList /> : <Navigate to="/" />
      },
      {
        path: 'retailer/jobs/view-details:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserJobView /> : <Navigate to="/" />
      },
      {
        path: 'retailer/tickets',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserTicketsList /> : <Navigate to="/" />
      },
      {
        path: 'retailer/tickets/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserTicketsAdd /> : <Navigate to="/" />
      },
      {
        path: 'retailer/existing/invoices',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <RetailerUserInvoice /> : <Navigate to="/" />
      },
      {
        path: 'invoices',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <InvoiceList /> : <Navigate to="/" />
      },
      // {
      //   path: 'invoices/create',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <InvoiceCreate /> : <Navigate to="/" />
      // },
      {
        path: 'invoices/create',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <InvoiceAdd /> : <Navigate to="/" />
      },
      {
        path: 'invoices/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <InvoiceEdit /> : <Navigate to="/" />
      },

      ///reseller
      {
        path: 'reseller',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerList /> : <Navigate to="/" />
      },
      {
        path: 'reseller/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerAdd /> : <Navigate to="/" />
      },
      {
        path: 'reseller/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerEdit /> : <Navigate to="/" />
      },

      {
        path: 'reseller/machines',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerMachines /> : <Navigate to="/" />
      },
      {
        path: 'reseller/enterprise',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerEnterpriseList /> : <Navigate to="/" />
      },

      {
        path: 'reseller/enterprise/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerAddEnterprise /> : <Navigate to="/" />
      },
      {
        path: 'reseller/enterprise/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerEditEnterprise /> : <Navigate to="/" />
      },

      {
        path: 'reseller/recycler',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerRecyclerList /> : <Navigate to="/" />
      },
      {
        path: 'reseller/recycler/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerAddRecycler /> : <Navigate to="/" />
      },
      {
        path: 'reseller/recycler/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerEditRecycler /> : <Navigate to="/" />
      },

      {
        path: 'reseller/retailer',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerRetailerList /> : <Navigate to="/" />
      },
      {
        path: 'reseller/retailer/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerAddRetailer /> : <Navigate to="/" />
      },

      {
        path: 'reseller/retailer/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerEditRetailer /> : <Navigate to="/" />
      },
      {
        path: 'reseller/location',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerLocationList /> : <Navigate to="/" />
      },
      {
        path: 'reseller/location/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerAddLocation /> : <Navigate to="/" />
      },

      {
        path: 'reseller/location/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResellerEditLocation /> : <Navigate to="/" />
      },

      {
        path: 'profile',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ProfilePage /> : <Navigate to="/" />
      },
      {
        path: 'profile/change-password',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ChangePasswordPage /> : <Navigate to="/" />
      },
      {
        path: 'profile/recycler/organization/details',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UpdateOrganization /> : <Navigate to="/" />
      },
      {
        path: 'profile/retailer/organization/details',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UpdateRetailerOrganization /> : <Navigate to="/" />
      },
      {
        path: 'profile/enterprise/organization/details',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <UpdateEnterpriseOrganization /> : <Navigate to="/" />
      },

      // {
      //   path: 'profile/reset-password',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ResetPasswordPage /> : <Navigate to="/" />
      // },
      {
        path: 'location',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <Location /> : <Navigate to="/" />
      },

      {
        path: 'location/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <LocationAdd /> : <Navigate to="/" />
      },

      {
        path: 'location/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <LocationEdit /> : <Navigate to="/" />
      },

      // {
      //   path: orgType === 'enterprise' ? 'enterprise/job' : 'job',
      //   element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <JobListPage /> : <Navigate to="/" />
      // },

      {
        path: 'job',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <JobListPage /> : <Navigate to="/" />
      },
      {
        path: 'job/view-details:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <JobEditPage /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/job',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseJobList /> : <Navigate to="/" />
      },
      {
        path: 'enterprise/job/view-details:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EnterpriseJobView /> : <Navigate to="/" />
      },

      {
        path: 'ticket',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <Ticket /> : <Navigate to="/" />
      },
      {
        path: 'ticket/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <TicketAdd /> : <Navigate to="/" />
      },

      {
        path: 'ticket/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <TicketEdit /> : <Navigate to="/" />
      },

      {
        path: 'settings',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <SuperAdminSettings /> : <Navigate to="/" />
      },
      ///////////organizations-new///////

      {
        path: 'organizations',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <OrganizationNewList /> : <Navigate to="/" />
      },
      {
        path: 'organizations/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <OrganizationsNewEdit /> : <Navigate to="/" />
      },
      {
        path: 'organizations/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <OrganizationNewAdd /> : <Navigate to="/" />
      },
      {
        path: 'employees',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EmployeeList /> : <Navigate to="/" />
      },
      {
        path: 'employees/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EmployeeAdd /> : <Navigate to="/" />
      },
      {
        path: 'employees/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <EmployeeEdit /> : <Navigate to="/" />
      },
      {
        path: 'get-support',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <GetSupport /> : <Navigate to="/" />
      },
      // public faq/super
      {
        path: 'faqs',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <Faq /> : <Navigate to="/" />
      },
      {
        path: 'faq/super-admin',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <SuperAdminFaq /> : <Navigate to="/" />
      },
      {
        path: 'faq/super-admin/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <FaqAdd /> : <Navigate to="/" />
      },
      {
        path: 'faq/super-admin/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <FaqEdit /> : <Navigate to="/" />
      },
      {
        path: 'subscriptions',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <Subscriptions /> : <Navigate to="/" />
      },
      {
        path: 'subscriptions/edit:id',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <SubscriptionsEdit /> : <Navigate to="/" />
      },
      {
        path: 'subscriptions/add',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <SubscriptionsAdd /> : <Navigate to="/" />
      },
      {
        path: 'control-panel',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <ControlPanel /> : <Navigate to="/" />
      },
      {
        path: 'support-ticket',
        element: userRoot.isAuthenticated || localStorage.getItem('userJwtToken') ? <SupportTicketList /> : <Navigate to="/" />
      }
    ]
  }
];
export default MainRoutes;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
import { endpoints } from 'endpoints/endpoints';

export const useLocationAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.location}/save`, data);
  return res;
};
//List Api

export const useGetLocationList = ({ limit, sortOrder, sortField, page, location_name, location_status, type = null }) => {
  return useQuery({
    queryKey: ['getLocationData', limit, sortOrder, sortField, page, location_name, location_status, type],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.location}/${page}/${limit}`, {
          // previous: previous,
          // current: current,
          // limit: limit,
          // first: first,
          // last: last,
          // field: sortField,
          // order: sortOrder,
          // type: type,
          filters: {
            name: location_name || null,
            enabled: location_status ? location_status : null
          },
          sort: {
            sortField: sortField,
            sortOrder: sortOrder
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    staleTime: 0,
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount

    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//for Editing
export const useGetLocationId = ({ id }) => {
  return useQuery({
    queryKey: ['getLocationDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.location}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Submitted Edited List

export const useLocationUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        // .patch(`${endpoints.locations}/${data.id}`, data)
        .put(`${endpoints.location}/${data.id}`, data)
        .then((res) => {
          console.log('useLocationUpdate res', res);
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },

    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getLocationDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

/////Status

export const useLocationStatusUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.location}/${data?.id}`, { status: data?.status })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries('getLocationData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

export const useGetPermissionsLocationsList = ({ organizationType, usersType, setLocationToggledButton, updateToggleButton = false }) => {
  return useQuery({
    queryKey: ['getPermissionsLocation', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Locations`)
        .then((res) => {
          if (res?.status == 200) {
            if (
              updateToggleButton &&
              res?.data?.data?.view?.filters?.filter((filter) => filter?.id === 'showOwnOtherTab' && filter?.show == true).length == 1
            ) {
              setLocationToggledButton('Own Location');
            }
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType) && Boolean(usersType),
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

import React from 'react';
// import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import SubscriptionsFilterForm from './SubscriptionsFilterForm';
const SubscriptionsFilter = ({ setFilterObj, filterObj, isExistingFilter, permissionsList }) => {
  const orgFilters = permissionsList?.view?.filters ?? [];

  const initials = {
    title: filterObj?.title || '',
    price: filterObj?.price || '',
    isPriceLess: filterObj?.isPriceLess || '==',
    interval: filterObj?.interval || '',
    localUser: filterObj?.localUser || '',
    globalUser: filterObj?.globalUser || ''
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().notRequired(),
    price: Yup.number().typeError('Price must be a valid number').positive('Price must be positive').notRequired(),
    interval: Yup.string().oneOf(['1 Month', '4 Months', '6 Months'], 'Invalid Interval selected').notRequired(),
    localUser: Yup.number()
      .typeError('Machine Specific User Count must be an integer')
      .integer('Machine Specific User Count must be an integer')
      .min(1, 'Machine Specific User Count must be at least 1')
      .notRequired(),
    globalUser: Yup.number()
      .typeError('Global User Count must be an integer')
      .integer('Global User Count must be an integer')
      .min(1, 'Global User Count must be at least 1')
      .notRequired(),
    isPriceLess: Yup.string().notRequired()
  });

  return (
    <>
      <Formik
        initialValues={initials}
        validationSchema={validationSchema}
        onSubmit={(value) => {
          console.log('filter form submit value', value);

          setFilterObj(value);

          // handleCloseFilter();
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
          <Form>
            <SubscriptionsFilterForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              resetForm={resetForm}
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              isExistingFilter={isExistingFilter}
              permissionsList={permissionsList}
              orgFilters={orgFilters}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SubscriptionsFilter;

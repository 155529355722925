import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';

const TicketReasonFilterForm = ({
  errors,
  //   handleBlur,
  handleChange,
  // handleSubmit,
  touched,
  values,
  setFieldValue,
  // setTouched,
  resetForm,
  filterObj,
  handleCloseFilter,
  setFilterObj
}) => {
  useEffect(() => {
    if (filterObj) {
      // console.log('🚀 ~ useEffect ~ filterObj:', filterObj);
      setFieldValue('ticketreason', filterObj?.ticketreason);
      setFieldValue('ticketstatus', filterObj?.ticketstatus);
    }
  }, [filterObj, setFieldValue]);
  return (
    <>
      <DialogContent className="choose_option_modal">
        <Grid container spacing={gridSpacing} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={12} className="select-machine-id inv-number select-machine">
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Ticket Reason
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              // error={Boolean(touched.status && errors.status)}
            >
              <TextField
                label="Ticket Reason"
                variant="outlined"
                type="text"
                fullWidth
                name="ticketreason"
                value={values?.ticketreason}
                onChange={handleChange}
                error={Boolean(touched.ticketreason && errors.ticketreason)}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                // onKeyDown={(e) => {
                //   exceptThisSymbols.includes(e.key) && e.preventDefault();
                // }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="select-job-enterprise ">
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Status
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              // error={Boolean(touched.status && errors.status)}
            >
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={values?.ticketstatus}
                onChange={handleChange}
                name="ticketstatus"
                inputProps={{ 'aria-label': 'Without label' }}
                labelId="retailer-primary-machine-label"
              >
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              ticketreason: ''
            });
            handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <Grid item xs={12}>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              //   disabled={isLoadingUpdateInvoiceStatus}
            >
              Apply filter
            </Button>
          </Box>
        </Grid>
      </DialogActions>
    </>
  );
};

export default TicketReasonFilterForm;

import React from 'react';

const ResellerMachines = () => {
  return (
    <>
      <div>ResellerMachines</div>
    </>
  );
};

export default ResellerMachines;

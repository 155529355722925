import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { Form } from 'formik';
import React from 'react';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DocumentUploadEmployee from '../files/uploader';
// import { FileUpload } from '@mui/icons-material';
const EmployeeForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  touched,
  values,
  setFieldValue
  //   setValues,
  //   setTouched
}) => {
  const theme = useTheme();
  const assignOrgData = ['Organization A', 'Organization B', 'Organization C', 'Organization D', 'Organization E'];
  const assignMachineData = ['M-101', 'M-102'];
  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit}
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   handleSubmit(e);
        // }}
      >
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Employee Details
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.prefix && errors.prefix)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-prefix" id="retailer-primary-title">
                    {' '}
                    Choose Prefix *
                  </InputLabel>

                  <Select
                    label="Choose  Prefix *"
                    placeholder="e.g. Mr. / Mrs."
                    value={values.prefix}
                    onChange={handleChange}
                    name="prefix"
                    inputProps={{ 'aria-label': 'Without label' }}
                    labelId="retailer-primary-title-label"
                  >
                    <MenuItem value={'Mr.'}>Mr.</MenuItem>
                    <MenuItem value={'Mrs.'}>Mrs.</MenuItem>
                  </Select>

                  {touched.prefix && errors.prefix && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.prefix}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.firstName && errors.firstName)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login"> First Name *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.firstName}
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" First Name*"
                    placeholder="John"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.firstName && errors.firstName && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.firstName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.lastName && errors.lastName)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login"> Last Name *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.lastName}
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" Last Name*"
                    placeholder="Doe"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.lastName && errors.lastName && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.lastName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.title && errors.title)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login"> Title *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.title}
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Title *"
                    placeholder="manager"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.title && errors.title && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.title}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login"> Email *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Email *"
                    placeholder="gh@yopmail.com"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.email && errors.email && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.phone && errors.phone)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login"> Phone *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.phone}
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Phone *"
                    placeholder="8976655678"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.phone && errors.phone && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.phone}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.address && errors.address)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login"> Address </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.address}
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Address"
                    // placeholder="8976655678"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.address && errors.address && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.address}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.rfid && errors.rfid)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login"> RFID *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.rfid}
                    name="rfid"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="RFID *"
                    placeholder="8976655678"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.rfid && errors.rfid && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.rfid}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.authPin && errors.authPin)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login"> Auth Pin *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.authPin}
                    name="authPin"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Auth Pin"
                    placeholder="9887"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.authPin && errors.authPin && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.authPin}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid>
                  <div style={{ padding: '10px 15px 15px' }} className="field-input-all">
                    <Typography variant="h5" component={'span'} className="font-medium">
                      Add Image
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        marginTop: '15px'
                      }}
                    >
                      <DocumentUploadEmployee
                        {...{
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                          //   imageDetails
                        }}
                      />
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              {/* Assign Organization */}

              <Grid item xs={12} sm={12} md={6} lg={6} className="select-job-enterprise select-job-enterprise-type">
                <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                  Assign Organization
                </Typography>
                <Autocomplete
                  multiple // Enable multiple selection
                  options={assignOrgData} // Use static options
                  value={values?.assign_org || []} // Ensure default is an array
                  onChange={(event, newValue) => handleChange({ target: { name: 'assign_org', value: newValue } })}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { onDelete, ...tagProps } = getTagProps({ index });
                      return (
                        <span
                          key={option}
                          {...tagProps}
                          style={{
                            backgroundColor: '#6fd74b',
                            color: '#fff',
                            padding: '2px 8px',
                            borderRadius: '4px',
                            marginRight: '5px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {option}
                          <button
                            onClick={onDelete}
                            style={{
                              marginLeft: '5px',
                              background: 'none',
                              border: 'none',
                              color: '#fff',
                              cursor: 'pointer'
                            }}
                          >
                            ✖
                          </button>
                        </span>
                      );
                    })
                  }
                  renderInput={(params) => <TextField {...params} placeholder="Search and Select Organizations" variant="outlined" />}
                  PaperComponent={(props) => (
                    <Paper
                      {...props}
                      style={{
                        maxHeight: 200,
                        overflowY: 'auto'
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} className="select-job-enterprise select-job-enterprise-type">
                <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                  Assign Machines
                </Typography>
                <Autocomplete
                  multiple // Enable multiple selection
                  options={assignMachineData} // Use static options
                  value={values?.assign_machine || []} // Ensure default is an array
                  onChange={(event, newValue) => handleChange({ target: { name: 'assign_machine', value: newValue } })}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      const { onDelete, ...tagProps } = getTagProps({ index });
                      return (
                        <span
                          key={option}
                          {...tagProps}
                          style={{
                            backgroundColor: '#6fd74b',
                            color: '#fff',
                            padding: '2px 8px',
                            borderRadius: '4px',
                            marginRight: '5px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {option}
                          <button
                            onClick={onDelete}
                            style={{
                              marginLeft: '5px',
                              background: 'none',
                              border: 'none',
                              color: '#fff',
                              cursor: 'pointer'
                            }}
                          >
                            ✖
                          </button>
                        </span>
                      );
                    })
                  }
                  renderInput={(params) => <TextField {...params} placeholder="Search and Select Machines" variant="outlined" />}
                  PaperComponent={(props) => (
                    <Paper
                      {...props}
                      style={{
                        maxHeight: 200,
                        overflowY: 'auto'
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel control={<Switch defaultChecked />} label="Make this employee admin" />
              </Grid>
            </Grid>
          </Grid>

          {/* submit */}

          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    // disabled={isLoadingRecycle}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {/* {isLoadingRecycle ? (
                      <>
                        <CircularProgres
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `${btnName} Recycler`
                    )} */}
                    Save
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default EmployeeForm;

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';
import { Form } from 'formik';
import React from 'react';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

const UpdateOrganizationForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,

  touched,
  values,
  setFieldValue,

  recycleDetails,
  isLoadingOrgUpdate,
  btnName
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (recycleDetails) {
      setFieldValue('RecyclerName', recycleDetails?.name ? recycleDetails?.name : '');
      setFieldValue('RecyclerWeb', recycleDetails?.website ? recycleDetails?.website : '');
      setFieldValue('recycler_radius', recycleDetails?.serviceRadius?.value ? recycleDetails?.serviceRadius?.value : '');
    }
  }, [recycleDetails, setFieldValue]);

  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Organization Details
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.RecyclerName && errors.RecyclerName)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-email-login"> Recycler Name *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.RecyclerName}
                    // value={userData?.recycler?.name}
                    name="RecyclerName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" Recycler Name *"
                    placeholder="e.g. Example Pvt. Ltd."
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.RecyclerName && errors.RecyclerName && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.RecyclerName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.RecyclerWeb && errors.RecyclerWeb)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login"> Recycler Website Address</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.RecyclerWeb}
                    // value={userData?.recycler?.website}
                    name="RecyclerWeb"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" Recycler Web"
                    inputProps={{}}
                    autoComplete="off"
                    placeholder="e.g. https://www.fosub.org"
                  />
                  {touched.RecyclerWeb && errors.RecyclerWeb && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.RecyclerWeb}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.recycler_radius && errors.recycler_radius)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-service-radius"> Recycler Service Radius *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-service-radius"
                    type="number"
                    value={values.recycler_radius}
                    // value={userData?.recycler?.serviceRadius?.value}
                    name="recycler_radius"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" Recycler Service Radius *"
                    placeholder="e.g. 123"
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onKeyDown={(e) => {
                      exceptThisSymbols.includes(e.key) && e.preventDefault();
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{
                          fontSize: '10px'
                        }}
                      >
                        Miles
                      </InputAdornment>
                    }
                    autoComplete="off"
                    // sx={{
                    //   '& input[type=number]': {
                    //     '-webkit-appearance': 'none',
                    //     '-moz-appearance': 'textfield'
                    //   }
                    // }}
                  />
                  {touched.recycler_radius && errors.recycler_radius && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.recycler_radius}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isLoadingOrgUpdate}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingOrgUpdate ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `${btnName}`
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default UpdateOrganizationForm;

import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import FormView from 'ui-component/cards/Skeleton/FormView';

import MainCard from 'ui-component/cards/MainCard';
import { Formik } from 'formik';
import * as Yup from 'yup';
import UpdateEnterpriseOrganizationForm from './form';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import { useGetEnterpriseById } from 'hooks/useEnterpriseHooks';
import { useOrganizationUpdate } from 'hooks/useOrganizationUpdateHooks';

const UpdateEnterpriseOrganization = () => {
  const [isLoading, setLoading] = useState(true);
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { data: enterpriseDetails } = useGetEnterpriseById({
    id: userData.enterprise?.id
  });
  const { mutate: updatedOrg, isPending: isLoadingOrgUpdate } = useOrganizationUpdate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      <>
        {isLoading ? (
          <>
            <FormView />
          </>
        ) : (
          <>
            <BreadcrumbsForPage name=" Organization" obj={{ title: ' Organization', title2: '', url: '' }} />
            <MainCard>
              <Formik
                initialValues={{
                  EnterpriseName: '',
                  EnterpriseWeb: ''
                }}
                validationSchema={Yup.object().shape({
                  EnterpriseName: Yup.string()
                    .max(50, 'Enterprise Name must be at most 50 characters')
                    .required('Enterprise Name is required')
                    .trim()
                    .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                    .matches(/^[^!@#$%^&*()_+\-=\\[\]{}:';"\\|,.<>\\?]*$/, 'Enterprise Name should not be in any special character'),

                  EnterpriseWeb: Yup.string().url('Please enter a valid Enterprise Website Address')
                })}
                onSubmit={async (value) => {
                  const data = {
                    organizationId: userData?.enterprise?.id,
                    type: userData?.organizationType,
                    details: {
                      name: value?.EnterpriseName,
                      website: value?.EnterpriseWeb
                    }
                  };
                  updatedOrg(data);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                  setValues,
                  setTouched
                }) => (
                  <UpdateEnterpriseOrganizationForm
                    btnName="Update Organization"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setTouched={setTouched}
                    setValues={setValues}
                    enterpriseDetails={enterpriseDetails}
                    isLoadingOrgUpdate={isLoadingOrgUpdate}
                  />
                )}
              </Formik>
            </MainCard>
          </>
        )}
      </>
    </>
  );
};

export default UpdateEnterpriseOrganization;

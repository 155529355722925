import { useState } from 'react';

import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';

// MUI Imports
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import FormHelperText from '@mui/material/FormHelperText';
import DescriptionIcon from '@mui/icons-material/Description';
// Third-party Imports
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

// import AppReactDropzone from '@/libs/styles/AppReactDropzone';

// import DeleteDialog from '@/components/dialogs/delete-dialog';
// import { useDeleteFileDelete } from '@/queryHooks/service/useVendorHooks';
import DeleteDialog from 'ui-component/delete-dialog';
import AppReactDropzone from 'ui-component/app-react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import { useTicketFileDelete } from 'hooks/useTickets';
import { Box } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { useFileUpload } from 'hooks/useCommonHooks';
// const Img = styled('img')(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     marginRight: theme.spacing(15.75)
//   },
//   [theme.breakpoints.down('md')]: {
//     marginBottom: theme.spacing(4)
//   },
//   [theme.breakpoints.down('sm')]: {
//     width: 160
//   }
// }));

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}));

const ImagePicker = ({ files, saveImage, fileListArr, setFieldValue }) => {
  console.log('🚀 ~ ImagePicker ~ files:', fileListArr);
  const { isPending: isDeleteLoading } = useTicketFileDelete();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [delIndex, setDelIndex] = useState('');
  const [delFile, setDelFile] = useState({});
  const config = {
    // maxFiles: 10,
    // maxSize: 2000000,
    // accept: {
    // 	'image/*': ['.png', '.jpg', '.jpeg', '.gif']
    // },
    // accept: {
    //   'image/*': ['.png', '.jpg', '.jpeg', '.gif'],
    //   'application/pdf': ['.pdf']
    // },
    accept: {
      '*': []
    },
    onDrop: (acceptedFiles) => saveImage(acceptedFiles),
    onDropRejected: (errors) => {
      errors.map((error) => {
        let { file, errors } = error;

        errors.map((err) =>
          toast.error(err.code === 'file-invalid-type' ? err.message : `${file.path} file Size should be less than 2MB.`, {
            autoClose: 3000
          })
        );
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone(config);

  const renderFilePreview = (file) => {
    if (!!file.type && file.type.startsWith('image')) {
      return <img width={38} height={38} alt={file.name} src={URL.createObjectURL(file)} />;
    } else if (!!file.mimetype && file.mimetype.startsWith('image')) {
      return <img width={38} height={38} alt={file.id} src={file?.url} />;
    } else {
      return <DescriptionIcon />;
    }
  };

  // const renderFilePreviewList = (file) => {
  //   return <img width={38} height={38} alt={file.name} src={file?.url} />;
  // };

  const fileList = files?.map((file, index) => (
    <ListItem key={file?.name}>
      <div className="file-details">
        <div className="file-preview">{renderFilePreview(file)}</div>
        <div>
          <Typography className="file-name">{file.name}</Typography>
          <Typography className="file-size" variant="body2">
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>
      <IconButton
        onClick={() => {
          deleteOpenModal(file, index);
          // removeImage(file, index);
        }}
        color="error"
      >
        <CloseIcon />
      </IconButton>
    </ListItem>
  ));

  const fileListArrFunc = fileListArr?.map((file, index) => (
    <ListItem key={file?.name}>
      <div className="file-details">
        <div className="file-preview">{renderFilePreview(file)}</div>
        <div>
          <Typography className="file-name">{file.name}</Typography>
          <Typography className="file-size" variant="body2">
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>
      <IconButton
        onClick={() => {
          deleteOpenModal(file, index);
        }}
      >
        <CloseIcon />
      </IconButton>
    </ListItem>
  ));

  const deleteOpenModal = (file, index) => {
    if (file?.mimetype) {
      setDelFile(file);
      setDelIndex(index);
      setOpenDeleteModal(true);
    } else {
      removeImage(file, index);
    }
  };

  const removeImage = (file, index) => {
    let data = files.filter((_, inx) => inx !== index);

    setFieldValue('files', data);
  };

  // const removeFileFromList = () => {
  //   if (delFile?.mimetype) {
  //     deleteFile(
  //       {
  //         url: delFile?.url
  //       },
  //       {
  //         onSuccess: () => {
  //           removeImage(delFile, delIndex);
  //         },
  //         onError: (error) => {
  //           console.error('Mutation failed:', error);
  //         }
  //       }
  //     );
  //   }

  //   setOpenDeleteModal(false);
  // };
  const removeFileFromList = () => {
    // Remove the selected file locally
    removeImage(delFile, delIndex);

    // Close the delete confirmation dialog
    setOpenDeleteModal(false);

    // Notify the user about the deletion
    toast.success(`${delFile?.name || 'File'} removed successfully.`);
  };
  return (
    <>
      {' '}
      <AppReactDropzone>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="flex items-center flex-row ">
            <img
              alt="Upload img"
              src="https://demos.themeselection.com/marketplace/materio-mui-nextjs-admin-template/documentation/images/misc/file-upload.png"
              style={{
                height: '100px',
                width: '100px',
                margin: '0 auto 15px',
                display: 'block'
              }}
              // className="max-bs-[120px] max-is-full bs-full"
            />
            <div className="flex flex-col text-center">
              <HeadingTypography variant="h5">Drop files here or click to upload.</HeadingTypography>
              {/* <Typography>Allowed *.jpeg, *.jpg, *.png, *.gif</Typography>
                          <Typography>Maximum limit 10 files and each file size should be less than 2 MB.</Typography> */}
            </div>
          </div>
        </div>
        {files?.length ? (
          <>
            <List>{fileList}</List>
            {/* <div className='buttons'>
                        <Button color='error' variant='outlined' onClick={handleRemoveAllFiles}>
                          Remove All
                        </Button>
                        <Button variant='contained'>Upload Files</Button>
                      </div> */}
          </>
        ) : fileListArr?.length ? (
          <>
            <List>{fileListArrFunc}</List>
          </>
        ) : null}
      </AppReactDropzone>
      <DeleteDialog
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        title={'Delete File'}
        subTitle={'Are you want to delete this file?'}
        // moduleName={'File'}
        handleSubmit={() => removeFileFromList()}
        isDeleteLoading={isDeleteLoading}
      />
    </>
  );
};

const DocumentUpload = ({
  values,
  errors,
  touched,

  setFieldValue
  // ticketDetails
}) => {
  const { mutate: uploadMutate, isPending: isUploadingFile } = useFileUpload();

  const uploadFiles = (acceptedFiles) => {
    let fileData = new FormData();

    acceptedFiles.forEach((file, i) => {
      fileData.append(`file_${i}`, file);
    });

    fileData?.append('event', 'get_support');
    uploadMutate(fileData, {
      onSuccess: (resp) => {
        console.log('🚀 ~ uploadFiles ~ resp:', resp);
        const data = values?.files?.concat(resp?.data?.data);
        setFieldValue('files', data);
      },
      onError: (error) => {
        console.error('Mutation failed:', error);
      }
    });
  };

  console.log(values?.files);

  return (
    <>
      <Box sx={{ m: 1, position: 'relative' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <ImagePicker
                files={values.files}
                saveImage={(acceptedFiles) => uploadFiles(acceptedFiles)}
                fileListArr={values?.files}
                setFieldValue={setFieldValue}
              />
              <FormHelperText sx={{ marginLeft: 4 }}>
                {touched.files && errors.files ? <span style={{ color: 'red' }}>{errors.files}</span> : null}
              </FormHelperText>
            </Grid>
          </Grid>
        </CardContent>
        {isUploadingFile && (
          <CircularProgress
            size={24}
            sx={{
              color: '#16B1FF',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px'
            }}
          />
        )}
      </Box>
    </>
  );
};

export default DocumentUpload;

import React from 'react';
import { Formik } from 'formik';

import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';

import { useParams } from 'react-router-dom';
import FormView from 'ui-component/cards/Skeleton/FormView';
import { useState, useEffect } from 'react';

import { useGetRetailerById, useGetRetailerEditedList } from 'hooks/useRetailerHooks';
import ResellerRetailerForm from '../form';

const ResellerEditRetailer = () => {
  let { id } = useParams();

  const { data: retailerDetails } = useGetRetailerById({
    id: id.slice(1)
  });
  const { mutate: updatedRetailer, isPending: isLoadingUpdatedRetailer } = useGetRetailerEditedList();
  const phoneRegex = RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <FormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name={`Edit Retailer Organization`}
            obj={{ title: 'All Retailer Organizations', title2: 'Edit Retailer Organization', url: '/dashboard/reseller/retailer' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                associateEmpEmail: '',
                associateEmpPhone: '',
                // associateEmpOfficePhone: '',
                associateEmpAdd: '',

                EmpFullAddress: '',
                EmpCity: '',
                EmpState: '',
                EmpCountry: '',
                EmpPostal_Code: '',

                // SecondaryEmpName: '',
                SecondaryEmpEmail: '',
                SecondaryEmpPhone: '',
                // SecondaryEmpOfficePhone: '',
                SecondaryEmpAdd: '',

                RetailerName: '',
                // Retailercode: '',
                RetailerWeb: '',
                // RetailerNotes: '',

                isChecked: false,
                submit: null,

                title: '',
                firstName: '',
                lastName: '',
                designation: '',

                secondaryTitle: '',
                secondaryFirstName: '',
                secondaryLastName: '',
                secondaryDesignation: '',
                primaryId: '',
                SecondaryId: '',
                addressId: '',
                locationPickUp: [],
                placeId: '',
                latitude: '',
                longitude: ''
              }}
              validationSchema={Yup.object().shape({
                RetailerName: Yup.string()
                  .max(50, 'Retailer Name must be at most 50 characters')
                  .required('Retailer Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Retailer Name should not be in any special character'),
                // Retailercode: Yup.string().max(30, 'Must be of 30 characters').trim().required('Retailer Code is required'),
                // RetailerPhone: Yup.string() // Validates for numerical value
                //   .phone('US', true, 'Must be a valid phone number. ex.: +1 212-456-7890'),

                RetailerWeb: Yup.string().url('Please enter a valid Retailer Website Address'),
                // RetailerNotes: Yup.string().max(150, 'Must be of 150 characters').trim(),
                // associateEmpName: Yup.string().max(30, ' Must be of 50 characters').trim().required('Primary contact name is required '),
                associateEmpEmail: Yup.string().email('Invalid email address').required('Employee Email is required'),

                associateEmpPhone: Yup.string()
                  .required('Employee Phone is required')

                  .matches(phoneRegex, 'Employee Phone is not valid')
                  .max(15, 'Employee Phone maximum number should be of 15 characters')
                  .trim(),
                // associateEmpOfficePhone: Yup.string()
                //   .phone('US', true, 'Must be a valid phone number. ex.: +1 212-456-7890')
                //   .required('Primary contact number is required'),

                associateEmpAdd: Yup.string().max(150, 'Must be of 150 characters').trim().required('Employee Address is required'),

                // EmpFullAddress: Yup.string().max(150, 'Must be of 150 characters').trim().required('Address is required'),
                // EmpCity: Yup.string().max(50, 'Must be of 50 characters').trim().required('City is required'),
                // EmpState: Yup.string().max(50, 'Must be of 50 characters').trim(),
                // EmpCountry: Yup.string().max(50, 'Must be of 50 characters').trim(),
                // EmpPostal_Code: Yup.string().trim(),

                // SecondaryEmpName: Yup.string().max(30, ' Must be of 50 characters').trim(),

                // SecondaryEmpPhone: Yup.string() // Validates for numerical value
                //   .phone('US', false, 'Must be a valid phone number. ex.: +1 212-456-7890')
                //   .notRequired(),

                SecondaryEmpPhone: Yup.string()
                  .required('Employee Phone is required')

                  .matches(phoneRegex, 'Employee Phone is not valid')
                  .max(15, 'Employee Phone maximum number should be of 15 characters')
                  .trim(),

                // test('not-negative', 'Secondary Phone number cannot be negative', (value) => {
                //   if (value && typeof value === 'string') {
                //     return !value.startsWith('-');
                //     //  return !/^-\d*|-\d+$/.test(value);
                //   }
                //   return true;
                // })

                // SecondaryEmpOfficePhone: Yup.string().test('not-negative', 'Secondary Phone number cannot be negative', (value) => {
                //   if (value && typeof value === 'string') {
                //     return !value.startsWith('-');
                //     //  return !/^-\d*|-\d+$/.test(value);
                //   }
                //   return true;
                // }),
                SecondaryEmpAdd: Yup.string().required('Employee Address is required').max(150, 'Must be of 30 characters').trim(),
                SecondaryEmpEmail: Yup.string().required('Employee Email is required').email('Invalid email address'),

                title: Yup.string().max(5, 'Must be of 5 characters').required('Title is required').trim(),
                firstName: Yup.string()
                  .max(50, 'First Name must be at most 50 characters')
                  .required('First Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'First Name should not be in any special character'),

                lastName: Yup.string()
                  .max(50, 'Last Name must be at most 50 characters')
                  .required('Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Last Name should not be in any special character'),

                designation: Yup.string()
                  .max(50, 'Must be of 50 characters')
                  .required('Designation is required')

                  .trim(),

                secondaryTitle: Yup.string().required('Prefix is required').trim(),
                secondaryFirstName: Yup.string()
                  .max(50, 'First Name must be at most 50 characters')
                  .required('Employee First Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'First Name should not be in any special character'),

                secondaryLastName: Yup.string()
                  .required('Employee Last Name is required')
                  .max(50, 'Last Name must be at most 50 characters')
                  // .required('Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Last Name should not be in any special character'),

                secondaryDesignation: Yup.string().required('Employee Title is required').max(50, 'Must be of 50 characters').trim(),
                locationPickUp: Yup.array().min(1, 'At least one location is required').required('Select Location is required ')
              })}
              onSubmit={async (value) => {
                const _location = value.locationPickUp
                  .filter((item) => item?.id !== '')
                  .map((item) => ({
                    value: item.id,
                    isPrimary: item?.isPrimary ? item?.isPrimary : false // Mark the first location as primary, adjust this logic if needed
                    // You can include other properties from `item` if necessary
                  }));
                const submitData = {
                  name: value?.RetailerName.trim(),
                  website: value?.RetailerWeb,
                  isChecked: value?.isChecked,
                  id: id.slice(1),
                  mainPOC: {
                    firstName: value?.firstName.trim(),
                    lastName: value?.lastName.trim(),
                    title: value?.designation,
                    prefix: value?.title,
                    email: value?.associateEmpEmail.trim(),
                    address: value?.associateEmpAdd.trim(),
                    phone: value?.associateEmpPhone.trim(),
                    id: value?.primaryId
                  },
                  secondaryPOC: {
                    firstName: value?.secondaryFirstName.trim(),
                    lastName: value?.secondaryLastName.trim(),
                    title: value?.secondaryDesignation,
                    prefix: value?.secondaryTitle,
                    email: value?.SecondaryEmpEmail.trim(),
                    address: value?.SecondaryEmpAdd.trim(),
                    phone: value?.SecondaryEmpPhone.trim(),
                    id: value?.SecondaryId
                  },
                  locations: _location
                };
                updatedRetailer(submitData);

                // updatedOrg(submitData);
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched }) => (
                <ResellerRetailerForm
                  btnName="Update"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  retailerDetails={retailerDetails}
                  isLoadingRetailer={isLoadingUpdatedRetailer}
                  id={id}
                  setTouched={setTouched}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default ResellerEditRetailer;

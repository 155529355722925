/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Card,
  CardContent,
  // CardHeader,
  Divider,
  // Typography,
  Box,
  IconButton,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Skeleton

  // Typography,
  // Autocomplete,
  // TextField,
  // Paper,
  // FormHelperText
  // Typography
} from '@mui/material';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { useNavigate } from 'react-router';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import FilterListIcon from '@mui/icons-material/FilterList';
import OrganizationFilter from 'ui-component/organization-filter';
import MachineFilter from 'ui-component/machine-filter';
import EmployeeFilterForm from 'ui-component/employee-filter/EmployeeFilterForm';
import EmployeeFilter from 'ui-component/employee-filter';
import SubscriptionsFilter from 'ui-component/subscriptions-filter';
import FilterSkeleton from './Skeleton/FilterSkeleton';
import FaqFilter from 'ui-component/faq-filter';
import LocationFilter from 'ui-component/location-filter';
import GetSupportFilter from 'ui-component/get-support-filter';
import SupportTicketFilter from 'ui-component/support-ticket-filter';
// import { gridSpacing } from 'store/constant';
// import { useState } from 'react';

// import { gridSpacing } from 'store/constant';
// import { useGetEnterpriseAllList } from 'hooks/useEnterpriseHooks';

// constant
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} arrow />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      // darkTitle,
      // secondary,
      shadow,
      sx = {},
      title,
      tt_title = '',
      filter_title = '',
      isButton,
      isFilter,
      isOrgFilter,
      isSubscriptionsFilter,
      isEmployeeFilter,
      isFaqFilter,
      isGetSupportFilter,
      isSupportTicketFilter,
      url,
      isEnable,

      // New props for Select
      showSelect,
      showMachineSelect,
      showMachineSelectEnterprise,
      isTicketFilter,
      selectLabel,
      // selectOrgStatusOptions,
      selectOptionMachines,
      // selectOptionFilter,

      onSelectChange,

      onSelectChangeMachine,
      onSelectChangeFilterValue,
      // onSelectClick,
      selectedValue,
      selectOptions,
      selectLabelMachine,
      selectedValueMachine,
      selectedFilterValue,
      userDetailsType,
      selectLabelMachineEnterprise,
      selectedValueMachineEnterprise,
      onSelectChangeMachineEnterprise,
      selectOptionMachinesEnterprise,
      openFilterAllModal,
      setOpenFilterModal,
      openFilterModal,
      isLocationFilter,
      openAddModal,
      setOpenAddModal,
      isModalAddEnabled,
      setFilterObj,
      filterObj,
      isExistingFilter,
      isOnPageFilter,
      isOpenOnPageFilter,
      setIsOpenOnPageFilter,
      permissionsList,
      isLoadingPermissionsList,
      locationToggledButton = '',
      ...others
    },
    ref
  ) => {
    // const userData = JSON.parse(localStorage.getItem('userData'));
    const theme = useTheme();
    const navigate = useNavigate();
    // const [openFilterAllInvoiceModal, setOpenFilterAllInvoiceModal] = useState(false);
    // const { data: allEnterpriseList } = useGetEnterpriseAllList();

    return (
      <Card
        className="jobs-new-card"
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.primary[200] + 25,
          ':hover': {
            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
          },
          position: 'relative',
          ...sx
        }}
      >
        {/* card header and action */}
        <Box
        // sx={{
        //   display: 'flex',
        //   alignItems: 'center',
        //   justifyContent: 'space-between',
        //   position: 'absolute',
        //   right: '-25px',
        //   zIndex: '11',
        //   top: '25px'
        // }}
        >
          {title == '' && <CardHeader sx={headerSX} title={''} />}
          {isEnable && isButton && (
            <HtmlTooltip
              title={tt_title ? tt_title : 'Add' + ' ' + title + ` ${locationToggledButton ? 'to (' + locationToggledButton + ')' : ''}`}
            >
              <IconButton
                sx={{
                  // mr: 5,
                  // position: 'absolute',
                  // right: '25px',
                  zIndex: 11,
                  position: 'absolute',
                  right: 20,
                  top: 10
                }}
                color="secondary"
                onClick={() => {
                  if (permissionsList?.showAdd) {
                    navigate(url, {
                      state: { permissionsList: permissionsList?.add, locationToggledButton: locationToggledButton }
                    });
                  } else {
                    navigate(url);
                  }
                }}
              >
                <NoteAddOutlinedIcon />
              </IconButton>
            </HtmlTooltip>
          )}
          {isModalAddEnabled && (
            <HtmlTooltip title={tt_title ? tt_title : 'Add' + ' ' + title}>
              <IconButton
                sx={{
                  // mr: 5,
                  // position: 'absolute',
                  // right: '25px',
                  zIndex: 11,
                  position: 'absolute',
                  right: 20,
                  top: 10
                }}
                color="secondary"
                onClick={() => {
                  setOpenAddModal(!openAddModal);
                }}
              >
                <NoteAddOutlinedIcon />
              </IconButton>
            </HtmlTooltip>
          )}

          {isFilter && (
            <HtmlTooltip title={filter_title ? filter_title : 'Filter' + ' ' + title}>
              <IconButton
                sx={{
                  // mr: 5,
                  // position: 'absolute',
                  // right: '25px',
                  zIndex: 11,
                  position: 'absolute',
                  right: 60,
                  top: 10
                }}
                color="secondary"
                onClick={() => {
                  setOpenFilterModal((prev) => !prev);
                }}
              >
                <FilterListIcon />
              </IconButton>
            </HtmlTooltip>
          )}
          {/* Select Filter modal */}
          {/* Select Status of Ticket */}
          <Grid container justifyContent="flex-end">
            {isTicketFilter && (
              <Grid item lg={3} md={6} sm={6} xs={12} className="select-job-enterprise">
                <Box sx={{ p: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>{selectFilterLabel}</InputLabel>
                    <Select
                      value={selectedFilterValue}
                      onChange={onSelectChangeFilterValue}
                      label={selectFilterLabel}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {/* {[
                        ...new Set(
                          selectOptionFilter
                            ?.filter(
                              (item) =>
                                item.status === 'In Review' ||
                                item.status === 'Rejected' ||
                                item.status === 'Requested' ||
                                item.status === 'Accepted' ||
                                item.status === 'Resolved'
                            )
                            ?.map((item) => item.status)
                        )
                      ]?.map((filteredValue) => (
                        <MenuItem key={filteredValue} value={filteredValue}>
                          {filteredValue}
                        </MenuItem>
                      ))} */}

                      <MenuItem value={'Resolved'}>Resolved</MenuItem>
                      <MenuItem value={'Accepted'}>Accepted</MenuItem>
                      <MenuItem value={'In Review'}>In Review</MenuItem>
                      <MenuItem value={'Rejected'}>Rejected</MenuItem>
                      <MenuItem value={'Requested'}>Requested</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            )}
          </Grid>
          {/* userData?.organizationType */}
          {userDetailsType == 'enterprise' || userDetailsType == 'recycler' ? (
            <>
              <Grid container justifyContent="flex-end">
                {showMachineSelectEnterprise && (
                  <Grid item lg={3} md={6} sm={6} xs={12} className="select-job-enterprise">
                    <Box sx={{ p: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>{selectLabelMachineEnterprise}</InputLabel>
                        <Select
                          value={selectedValueMachineEnterprise}
                          onChange={onSelectChangeMachineEnterprise}
                          label={selectLabelMachineEnterprise}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">None</MenuItem>
                          {selectOptionMachinesEnterprise?.map((machines) => (
                            <MenuItem key={machines.machineId} value={machines.machineId}>
                              {machines?.machineId}
                            </MenuItem>
                          ))}
                          {/* {selectOptionMachines?.map((machine) => (
                            <MenuItem key={machine.id} value={machine.id}>
                              {machine?.machine?.machineId}
                            </MenuItem>
                          ))} */}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid container justifyContent="flex-end">
                {showSelect && (
                  <Grid item lg={3} md={6} sm={6} xs={12} className="select-job-enterprise">
                    <Box sx={{ p: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>{selectLabel}</InputLabel>
                        <Select
                          value={selectedValue}
                          onChange={onSelectChange}
                          label={selectLabel}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">None</MenuItem>
                          {selectOptions
                            ?.filter((item) => item.enabled == true)
                            ?.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}

                {showMachineSelect && (
                  <Grid item lg={3} md={6} sm={6} xs={12} className="select-job-enterprise">
                    <Box sx={{ p: 2 }}>
                      <FormControl fullWidth>
                        <InputLabel>{selectLabelMachine}</InputLabel>
                        <Select
                          value={selectedValueMachine}
                          onChange={onSelectChangeMachine}
                          label={selectLabelMachine}
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">None</MenuItem>
                          {selectOptionMachines?.map((machine) => (
                            <MenuItem key={machine.machineId} value={machine.machineId}>
                              {machine?.machineId}
                            </MenuItem>
                          ))}
                          {/* {selectOptionMachines?.map((machine) => (
                            <MenuItem key={machine.id} value={machine.id}>
                              {machine?.machine?.machineId}
                            </MenuItem>
                          ))} */}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </>
          )}
          {isOrgFilter && isFilter && (
            <>
              {openFilterAllModal && (
                <>
                  {isLoadingPermissionsList ? (
                    <>
                      <FilterSkeleton />
                    </>
                  ) : (
                    <OrganizationFilter
                      permissionsList={permissionsList}
                      setFilterObj={setFilterObj}
                      filterObj={filterObj}
                      isExistingFilter={isExistingFilter}
                    />
                  )}
                </>
              )}
            </>
          )}
          {isSubscriptionsFilter && isFilter && (
            <>
              {openFilterAllModal && (
                <SubscriptionsFilter
                  permissionsList={permissionsList}
                  setFilterObj={setFilterObj}
                  filterObj={filterObj}
                  isExistingFilter={isExistingFilter}
                />
              )}
            </>
          )}
          {isLocationFilter && isFilter && (
            <>
              {openFilterAllModal && (
                <LocationFilter
                  permissionsList={permissionsList}
                  setFilterObj={setFilterObj}
                  filterObj={filterObj}
                  isExistingFilter={isExistingFilter}
                />
              )}
            </>
          )}

          {isOnPageFilter && isFilter && (
            <>
              {openFilterAllModal && (
                <>
                  {isLoadingPermissionsList ? (
                    <FilterSkeleton />
                  ) : (
                    <>
                      {permissionsList?.view?.filters !== undefined && (
                        <MachineFilter
                          setFilterObj={setFilterObj}
                          filterObj={filterObj}
                          isExistingFilter={isExistingFilter}
                          permissionsList={permissionsList}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}

          {isEmployeeFilter && isFilter && (
            <>
              {openFilterAllModal && (
                <EmployeeFilter setFilterObj={setFilterObj} filterObj={filterObj} isExistingFilter={isExistingFilter} />
              )}
            </>
          )}

          {isFaqFilter && isFilter && (
            <>
              {openFilterAllModal && (
                <>
                  {isLoadingPermissionsList ? (
                    <FilterSkeleton />
                  ) : (
                    <>
                      <FaqFilter
                        setFilterObj={setFilterObj}
                        filterObj={filterObj}
                        isExistingFilter={isExistingFilter}
                        permissionsList={permissionsList}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}

          {isGetSupportFilter && isFilter && (
            <>
              {openFilterAllModal && (
                <>
                  {isLoadingPermissionsList ? (
                    <FilterSkeleton />
                  ) : (
                    <>
                      <GetSupportFilter
                        setFilterObj={setFilterObj}
                        filterObj={filterObj}
                        isExistingFilter={isExistingFilter}
                        permissionList={permissionsList}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}

          {isSupportTicketFilter && isFilter && (
            <>
              {openFilterAllModal && (
                <>
                  {isLoadingPermissionsList ? (
                    <FilterSkeleton />
                  ) : (
                    <>
                      <SupportTicketFilter
                        setFilterObj={setFilterObj}
                        filterObj={filterObj}
                        isExistingFilter={isExistingFilter}
                        permissionList={permissionsList}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
        {/* content & header divider */}
        {title == '' && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  tt_title: PropTypes.string,
  isButton: PropTypes.bool,
  isFilter: PropTypes.bool,
  url: PropTypes.string,

  // New PropTypes for Select
  showSelect: PropTypes.bool,
  showOrganizationNameSelect: PropTypes.bool,
  showOrganizationStatusSelect: PropTypes.bool,
  showOrganizationTypesSelect: PropTypes.bool,
  showMachineSelect: PropTypes.bool,
  showMachineSelectEnterprise: PropTypes.bool,
  isTicketFilter: PropTypes.bool,
  iSButtonOrganization: PropTypes.bool,
  selectLabel: PropTypes.string,
  selectOrgName: PropTypes.string,
  selectOrgTypes: PropTypes.string,
  selectOrgStatus: PropTypes.string,
  selectLabelMachine: PropTypes.string,
  selectLabelMachineEnterprise: PropTypes.string,
  selectFilterLabel: PropTypes.string,

  selectOptions: PropTypes.array,
  selectOrgNameOptions: PropTypes.array,
  selectOrgTypesOptions: PropTypes.array,
  selectOrgStatusOptions: PropTypes.array,
  selectOptionMachines: PropTypes.array,
  selectOptionMachinesEnterprise: PropTypes.array,
  selectOptionFilter: PropTypes.array,

  onSelectChange: PropTypes.func,
  onSelectOrgChange: PropTypes.func,
  onSelectOrgStatusChange: PropTypes.func,
  onSelectOrgTypesChange: PropTypes.func,
  onSelectChangeMachine: PropTypes.func,
  onSelectChangeMachineEnterprise: PropTypes.func,
  onSelectChangeFilterValue: PropTypes.func,
  onSelectAllFilterInvoice: PropTypes.func,
  setFilterObj: PropTypes.func,
  selectedValue: PropTypes.any,
  selectedOrgName: PropTypes.any,
  selectedOrgTypes: PropTypes.any,
  selectedOrgStatus: PropTypes.any,
  selectedValueMachine: PropTypes.any,
  selectedValueMachineEnterprise: PropTypes.any,
  selectedFilterValue: PropTypes.any,

  isEnable: PropTypes.bool,

  userDetailsType: PropTypes.string
};

export default MainCard;

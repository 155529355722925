import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import SuperAdminFaqForm from '../form';
import { UseFaqAdd } from 'hooks/useSuperAdminFaqHooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
const FaqAdd = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const queryClient = useQueryClient();
  const { mutate: addFaq, isPending: isLoadingFaq } = useMutation({
    mutationFn: UseFaqAdd
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <OrganizationFormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add FAQs"
            obj={{
              title: 'All FAQs',
              title2: 'Add FAQs',
              url: '/dashboard/faq/super-admin'
            }}
          />
          <MainCard>
            <Formik
              initialValues={{
                ques: '',
                ans: ''
              }}
              validationSchema={Yup.object().shape({
                ques: Yup.string().required('Question is required').trim(),
                ans: Yup.string().required('Answer is required').trim()
              })}
              onSubmit={async (value) => {
                const submitData = {
                  question: value?.ques,
                  answer: value?.ans
                };

                addFaq(submitData, {
                  onSuccess: (res) => {
                    // console.log('🚀 ~ onSubmit={ ~ res:', res);
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.message);
                      navigate(-1);

                      queryClient.refetchQueries({ queryKey: ['getSuperAdminFaqData'], type: 'active' });
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
                // console.log('🚀 ~ onSubmit={ ~ submitData:', submitData);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                setValues,
                setTouched
              }) => (
                <>
                  <SuperAdminFaqForm
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                    setTouched={setTouched}
                    isLoadingFaq={isLoadingFaq}
                  />
                </>
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default FaqAdd;

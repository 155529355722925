import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {
  Card
  // CardContent
} from '@mui/material';
import SidePanelControl from './tabs/side-panel-control';
import UserPermissionControl from './tabs/user-permission-control';
import ScreenMasterControl from './tabs/screen-master-control';
import { useSelector } from 'react-redux';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function PanelTabs() {
  const [value, setValue] = React.useState(0);
  const { routeList, userTypes, orgTypes } = useSelector((state) => state?.controlPanelReducer);
  // console.log('routeList, userTypes, orgTypes', routeList, userTypes, orgTypes);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
    >
      {/* <CardContent
        sx={{
          flex: '1 1 auto',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}
      > */}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', overflowX: 'auto' }}>
          <Tabs value={value} onChange={handleChange} aria-label="scrollable tabs example" variant="scrollable" scrollButtons="auto">
            <Tab label="Route Config & Control" {...a11yProps(0)} />
            <Tab label="Permissions & Access Control" {...a11yProps(1)} />
            <Tab label="On Screen View Control" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <Box sx={{ flex: '1 1 auto', overflowY: 'auto', py: 2 }}>
          <CustomTabPanel value={value} index={0}>
            <SidePanelControl userTypes={userTypes} orgTypes={orgTypes} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <UserPermissionControl routeList={routeList} userTypes={userTypes} orgTypes={orgTypes} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ScreenMasterControl routeList={routeList} />
          </CustomTabPanel>
        </Box>
      </Box>
      {/* </CardContent> */}
    </Card>
  );
}

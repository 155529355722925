import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import 'yup-phone';
import SubscriptionForm from '../subscription-form';

import FormView from 'ui-component/cards/Skeleton/FormView';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSubscriptionsById, useGetSubscriptionsEditedList } from 'hooks/useSubscriptionsHooks';
// import { setToast, toastConfig } from 'utils/commonUtil';

const OrganizationEdit = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const { data: subscriptionDetails, isLoading } = useGetSubscriptionsById({
    id: id.slice(1)
  });

  const { mutate: updatedSubscription, isPending: isSubscriptionsLoading } = useGetSubscriptionsEditedList();

  const initials = {
    title: subscriptionDetails?.title ?? '',
    price: subscriptionDetails?.price?.amount ?? '',
    interval: subscriptionDetails?.price?.interval ?? '',
    description: subscriptionDetails?.description ?? '',
    localUser: subscriptionDetails?.noUsers?.machineSpecificUser ?? '',
    globalUser: subscriptionDetails?.noUsers?.globalUser ?? '',
    features: subscriptionDetails?.features ?? '',
    isPublic: subscriptionDetails?.isPublic ?? false,
    planColor: subscriptionDetails?.colorCode ?? '',
    noOfAds: subscriptionDetails?.noOfAdvertisements ?? ''
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    price: Yup.number().typeError('Price must be a valid number').positive('Price must be positive').required('Price is required'),
    interval: Yup.string().oneOf(['1 Month', '4 Months', '6 Months'], 'Invalid interval selected').required('Interval is required'),
    description: Yup.string().required('Description is required'),
    localUser: Yup.number()
      .typeError('Local user count must be an integer')
      .integer('Local user count must be an integer')
      .min(1, 'Machine Specific User Count must be at least 1')
      .required('Local user count is required'),
    globalUser: Yup.number()
      .typeError('Global user count must be an integer')
      .integer('Global user count must be an integer')
      .min(1, 'Global user count must be at least 1')
      .required('Global user count is required'),
    noOfAds: Yup.number()
      .typeError('Number of Ads must be an integer')
      .integer('Number of Ads cannot be integer')
      .min(1, 'Number of Ads must be at least 1')
      .required('Number of Ads is required'),
    features: Yup.array().of(Yup.string().required('Feature cannot be empty')).min(1, 'At least one feature is required')
  });

  return (
    <>
      {isLoading ? (
        <FormView />
      ) : (
        <>
          <BreadcrumbsForPage
            name="Edit Subscription"
            obj={{ title: 'Edit Subscriptions', title2: 'Edit Subscriptions', url: '/dashboard/subscriptions' }}
          />
          <MainCard>
            <Formik
              initialValues={initials}
              validationSchema={validationSchema}
              onSubmit={(value, { resetForm }) => {
                const submitData = {
                  title: value?.title,
                  description: value?.description,
                  isPublic: value?.isPublic,
                  noUsers: {
                    machineSpecificUser: value?.localUser,
                    globalUser: value?.globalUser
                  },
                  price: {
                    amount: value?.price,
                    interval: value?.interval
                  },
                  features: value?.features,
                  colorCode: value?.planColor,
                  id: id.slice(1),
                  noOfAdvertisements: value?.noOfAds ?? 0
                };

                updatedSubscription(submitData, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      // toastConfig.type = 'success';
                      // setToast(toastConfig, res?.data.message);
                      // // queryClient.refetchQueries('getEnterpriseData');
                      resetForm({});
                      navigate(-1);
                    } else {
                      // toastConfig.type = 'error';
                      // setToast(toastConfig, error?.response?.data?.error);
                    }
                  }
                  // onError: (error) => {
                  //   // toastConfig.type = 'error';
                  //   // setToast(toastConfig, error?.response?.data?.error);
                  // }
                });
              }}
            >
              {(args) => <SubscriptionForm {...args} isSubscriptionsLoading={isSubscriptionsLoading} />}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default OrganizationEdit;

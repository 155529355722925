import * as type from '../type/headerUserType';
const initialState = {
  organizationType: '',
  userType: ''
};
const headerUserTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_ORG_TYPE:
      return {
        ...state,
        organizationType: action.payload
      };
    case type.RESET_ORG_TYPE:
      return {
        ...state,
        organizationType: ''
      };
    case type.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload
      };
    case type.RESET_USER_TYPE:
      return {
        ...state,
        userType: ''
      };

    default:
      return state;
  }
};

export default headerUserTypeReducer;

import { FormControl, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { gridSpacing } from 'store/constant';

const SettingsView = () => {
  return (
    <>
      <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
            <Skeleton animation="wave" width={200} />
          </Typography>
          <Grid container spacing={gridSpacing}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography component="h5" variant="h4" sx={{ mb: 2 }}>
                <Skeleton animation="wave" />
              </Typography>
              <Grid container className="settings-row-page">
                {[1, 2, 3, 4].map((i) => (
                  <Box
                    key={i}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      my: 1
                    }}
                  >
                    <FormControl fullWidth>
                      <Skeleton variant="rounded" width={170} height={50} />
                    </FormControl>

                    <IconButton>
                      <Skeleton variant="circular" width={30} height={30} />
                    </IconButton>
                  </Box>
                ))}

                <div>
                  <IconButton className="settings-addbtn">
                    <Skeleton variant="circular" width={30} height={30} />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ mt: 5, justifyContent: 'center', textAlign: 'center' }}>
          <Skeleton
            variant="rectangular"
            width={50}
            height={50}
            size="large"
            // type="submit"
            // variant="contained"
            color="secondary"
            sx={{
              color: 'white',
              minWidth: '200px',
              margin: '0px auto',
              bgcolor: 'rgb(111, 215, 75)',
              borderRadius: '5px'
            }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default SettingsView;

import React from 'react';
import { Formik } from 'formik';

import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';

import { useParams } from 'react-router-dom';
import FormView from 'ui-component/cards/Skeleton/FormView';
import { useState, useEffect } from 'react';
// import { useGetOrgById, useGetOrgEditedList } from 'hooks/useOrganizationHooks';

import { useGetEnterpriseById, useGetEnterpriseEditedList } from 'hooks/useEnterpriseHooks';
import ResellerEnterpriseForm from '../form';
const ResellerEditEnterprise = () => {
  let { id } = useParams();

  const { data: enterpriseDetails } = useGetEnterpriseById({
    id: id.slice(1)
  });

  const { mutate: updatedEnterprise, isPending: isLoadingEnterprise } = useGetEnterpriseEditedList();
  const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <FormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name={`Edit Enterprise Organization`}
            obj={{ title: 'All Enterprise Organizations', title2: `Edit Enterprise Organization ${id}`, url: '/dashboard/enterprise' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                //    associateEmpName: '',
                associateEmpEmail: '',
                associateEmpPhone: '',
                // associateEmpOfficePhone: '',
                associateEmpAdd: '',

                EmpFullAddress: '',
                EmpCity: '',
                EmpState: '',
                EmpCountry: '',
                EmpPostal_Code: '',

                //  SecondaryEmpName: '',
                SecondaryEmpEmail: '',
                SecondaryEmpPhone: '',
                //SecondaryEmpOfficePhone: '',
                SecondaryEmpAdd: '',

                EnterpriseName: '',
                //EnterpriseCode: '',
                EnterpriseWeb: '',

                // EnterpriseNotes: '',

                title: '',
                firstName: '',
                lastName: '',
                designation: '',

                secondaryTitle: '',
                secondaryFirstName: '',
                secondaryLastName: '',
                secondaryDesignation: '',
                users: [
                  { user_firstname: '', user_email: '', rfid_code: '', user_lastname: '' },
                  { user_firstname: '', user_email: '', rfid_code: '', user_lastname: '' },
                  { user_firstname: '', user_email: '', rfid_code: '', user_lastname: '' },
                  { user_firstname: '', user_email: '', rfid_code: '', user_lastname: '' }
                ],
                isChecked: false,
                primaryId: '',
                SecondaryId: '',
                // addressId: '',
                locationPickUp: [],
                placeId: '',
                latitude: '',
                longitude: '',
                rfIdCodePrimary: '',
                rfIdCodeSecondary: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                EnterpriseName: Yup.string()
                  .max(50, 'Enterprise Name must be at most 50 characters')
                  .required('Enterprise Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Enterprise Name should not be in any special character'),
                // EnterpriseCode: Yup.string().max(30, 'Must be of 30 characters').trim().required('Enterprise Code is required'),

                EnterpriseWeb: Yup.string().url('Please enter a valid Enterprise Website Address'),

                associateEmpEmail: Yup.string().email('Invalid email address').required('Employee Email is required'),

                associateEmpPhone: Yup.string() // Validates for numerical value
                  .required('Employee Phone is required')
                  .matches(phoneRegex, 'Employee Phone is not valid'),
                // .phone('US', true, 'Must be a valid Employee Phone Number. ex.: +1 212-456-7890'),

                associateEmpAdd: Yup.string()
                  .max(150, ' Employee Address must be of 30 characters')
                  .trim()
                  .required('Employee Address is required'),

                // EmpFullAddress: Yup.string().max(150, 'Must be of 150 characters').trim().required(' Full Address is required'),
                // EmpCity: Yup.string().max(50, 'Must be of 50 characters').trim().required('City is required'),
                // EmpState: Yup.string().max(50, 'Must be of 50 characters').trim(),
                // EmpCountry: Yup.string().max(50, 'Must be of 50 characters').trim(),
                // EmpPostal_Code: Yup.string().trim(),

                title: Yup.string().required('Prefix is required').trim(),
                firstName: Yup.string()
                  .max(50, 'Employee First Name  must be at most 50 characters')
                  .required('Employee First Name  is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name  should not be in any special character'),

                lastName: Yup.string()
                  .max(50, 'Employee Last Name must be at most 50 characters')
                  .required('Employee Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not be in any special character'),

                designation: Yup.string()
                  .max(50, 'Employee Title must be of 50 characters')
                  .required('Employee Title is required')

                  .trim(),
                secondaryTitle: Yup.string().required('Prefix is required').trim(),
                secondaryFirstName: Yup.string()
                  .required('Employee First Name  is required')
                  .max(50, 'Employee First Name must be at most 50 characters')
                  // .required('Employee First Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not be in any special character'),

                secondaryLastName: Yup.string()
                  .max(50, 'Employee Last Name must be at most 50 characters')
                  .required('Employee Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not be in any special character'),

                secondaryDesignation: Yup.string()
                  .required('Employee Title is required')
                  .max(50, ' Employee Title must be of 50 characters')
                  .trim(),

                SecondaryEmpPhone: Yup.string()
                  .required('Employee Phone is required')

                  .matches(phoneRegex, 'Employee Phone is not valid')
                  .max(15, 'Employee Phone maximum number should be of 15 characters'),
                // .phone('US', true, 'Must be a valid Employee Phone Number. ex.: +1 212-456-7890'),

                SecondaryEmpEmail: Yup.string().required('Employee Email is required').email('Invalid email address'),

                SecondaryEmpAdd: Yup.string()
                  .required('Employee Address is required')
                  .max(150, 'Employee Address must be of 30 characters')
                  .trim(),
                locationPickUp: Yup.array().min(1, 'At least one location is required').required('Select Location is required ')
              })}
              onSubmit={async (value) => {
                const _location = value.locationPickUp
                  .filter((item) => item?.id !== '')
                  .map((item) => ({
                    value: item.id,
                    isPrimary: item?.isPrimary ? item?.isPrimary : false // Mark the first location as primary, adjust this logic if needed
                    // You can include other properties from `item` if necessary
                  }));
                const submitData = {
                  name: value?.EnterpriseName.trim(),
                  website: value?.EnterpriseWeb,
                  isChecked: value?.isChecked,
                  id: id.slice(1),
                  mainPOC: {
                    firstName: value?.firstName.trim(),
                    lastName: value?.lastName.trim(),
                    title: value?.designation,
                    prefix: value?.title,
                    email: value?.associateEmpEmail.trim(),
                    address: value?.associateEmpAdd.trim(),
                    phone: value?.associateEmpPhone,
                    id: value?.primaryId,
                    rfidCode: value?.rfIdCodePrimary.trim()
                  },
                  secondaryPOC: {
                    firstName: value?.secondaryFirstName.trim(),
                    lastName: value?.secondaryLastName.trim(),
                    title: value?.secondaryDesignation,
                    prefix: value?.secondaryTitle,
                    email: value?.SecondaryEmpEmail.trim(),
                    address: value?.SecondaryEmpAdd.trim(),
                    phone: value?.SecondaryEmpPhone,
                    id: value?.SecondaryId,
                    rfidCode: value?.rfIdCodeSecondary.trim()
                  },
                  locations: _location
                };
                updatedEnterprise(submitData);
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched }) => (
                <ResellerEnterpriseForm
                  btnName="Update"
                  errors={errors}
                  setTouched={setTouched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  enterpriseDetails={enterpriseDetails}
                  isLoadingEnterprise={isLoadingEnterprise}
                  id={id}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default ResellerEditEnterprise;

import React from 'react';
// import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

import { Form, Formik } from 'formik';
// import * as Yup from 'yup';

import MachineFilterForm from './MachineFilterForm';
const MachineFilter = ({ setFilterObj, filterObj, isExistingFilter , permissionsList }) => {
  return (
    <>
      <Formik
        initialValues={{
          buyer: '',
          seller: '',
          status: [],
          type: 'all',
          nickname: '',
          model: '',
          softwareVersion: [],
          client: '',
          rental: '',
          rented:''
        }}
        onSubmit={async (value) => {
          // console.log('dsd', value);

          setFilterObj({
            /*status: value?.status,
            orgName: value?.orgName || null,
            orgTypes: value?.orgTypes || null*/
            buyer: value?.buyer?.id || null,
            seller: value?.seller?.id || null,
            status: value?.status || null,
            type: (value?.type === 'all' ? null : value?.type) || null,
            nickname: value?.nickname || null,
            model: value?.model || null,
            softwareVersions: value?.softwareVersion || null,
            client: value?.client?.id || null,
            rental: value?.rent?.id || null,
            rented: value?.rented?.id || null,
          });

          // handleCloseFilter();
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
          <Form>
            <MachineFilterForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              resetForm={resetForm}
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              isExistingFilter={isExistingFilter}
              permissionsList={permissionsList}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default MachineFilter;

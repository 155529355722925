import React from 'react';
import {
  useSelector
  // useDispatch
} from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function GlobalLoader() {
  // const dispatch = useDispatch();

  // Access the isLoadingGlobalLoader state from the Redux store
  const isLoadingGlobalLoader = useSelector((state) => state.loadingReducer.isLoadingGlobalLoader);

  // const handleClose = () => {
  //   dispatch({ type: 'SET_LOADING_STATUS', payload: false });
  // };
  const isLogin = localStorage.getItem('userJwtToken');
  // console.log('isLogin', isLogin);
  return (
    <>
      {isLogin && (
        <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={isLoadingGlobalLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
}

export default GlobalLoader;

import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';

import * as Yup from 'yup';
import 'yup-phone';

import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import OrganizationsNewForm from '../form';
import {
  useGetOrganizationNewId,
  useGetPermissionsOrganizationNewList,
  useOrganizationNewNullCheck,
  useOrganizationNewUpdate
} from 'hooks/useOrganizationNewHooks';

import { useNavigate, useParams } from 'react-router-dom';
// import { setToast, toastConfig } from 'utils/commonUtil';
// import DeleteDialog from 'ui-component/delete-dialog';
import ConfirmDialogOrg from 'ui-component/confirm-edit-dialog';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import { Lock } from '@mui/icons-material';

const OrganizationsNewEdit = () => {
  const [isLoading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [storeEditObj, setStoreEditObj] = useState({});
  const navigate = useNavigate();
  // const phoneRegex = RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);

  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const {
    data: permissionsList
    // isLoading: isLoadingPermissionsList
  } = useGetPermissionsOrganizationNewList({
    organizationType:
      organizationType === 'Service Provider' ? 'ServiceProvider' : organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
    usersType: usersType === 'Main POC' ? 'POC' : 'POC'
  });
  // Access the state object

  let { id } = useParams();
  const { data: organizationNewDetails } = useGetOrganizationNewId({
    id: id.slice(1)
  });
  const editPermissionsList = permissionsList?.edit || [];
  let buttonPermissions = [];
  let mainPOPermissions = [];
  let secondPOPermissions = [];
  let otherPOPermissions = [];
  editPermissionsList?.forEach((permission) => {
    if (permission?.id?.indexOf('Option') != -1) {
      buttonPermissions.push(permission);
    } else if (permission?.id?.indexOf('main') != -1) {
      mainPOPermissions.push(permission);
    } else if (permission?.id?.indexOf('secondary') != -1) {
      secondPOPermissions.push(permission);
    } else {
      otherPOPermissions.push(permission);
    }
  });
  const editPermissionsShowEdit = permissionsList?.showEdit;
  const { mutate: updateOrganizationNew, isPending: isLoadingOrganizationNew } = useOrganizationNewUpdate();
  const { mutate: organizationNullCheck, isPending: isLoadingOrganizationNullCheck } = useOrganizationNewNullCheck();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const initials = {
    associateEmpEmail: '',
    associateEmpPhone: null,
    associateEmpAdd: '',
    EmpFullAddress: '',
    EmpCity: '',
    EmpState: '',
    EmpCountry: '',
    EmpPostal_Code: '',
    SecondaryEmpEmail: '',
    SecondaryEmpPhone: null,
    SecondaryEmpAdd: '',
    OrgName: '',
    OrgWeb: '',
    isChecked: false,
    submit: null,
    title: '',
    firstName: '',
    lastName: '',
    designation: '',

    secondaryTitle: '',
    secondaryFirstName: '',
    secondaryLastName: '',
    secondaryDesignation: '',
    locationPickUp: [],
    placeId: '',
    latitude: '',
    longitude: '',
    orgSelection: [],
    ///recycler//
    service_radius: '',
    serviceRadiusUnit: 'Miles',
    rfIdCodePrimary: '',
    authPinPrimary: null,
    authPinSecondary: null,
    rfIdCodeSecondary: ''
  };

  const dynamicalidationSchema = Yup.object(
    editPermissionsList.slice(5, 27).reduce((schema, filter) => {
      if (filter?.show && filter?.isRequired && !filter?.isStatic) {
        switch (filter.id) {
          case 'mainPOCPrefix':
            schema['title'] = Yup.string().trim();
            if (filter?.isRequired && !filter?.isStatic) schema['title'] = schema['title'].required('Prefix is required');
            break;
          case 'mainPOCTitle':
            schema['designation'] = Yup.string().max(50, 'Employee Title must be of 50 characters').trim();
            if (filter?.isRequired && !filter?.isStatic)
              schema['designation'] = schema['designation'].required('Employee Title is required');
            break;
          case 'mainPOCFirstName':
            schema['firstName'] = Yup.string()
              .max(50, 'Employee First Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not include special characters');
            if (filter?.isRequired && !filter?.isStatic)
              schema['firstName'] = schema['firstName'].required('Employee First Name is required');
            break;
          case 'mainPOCLastName':
            schema['lastName'] = Yup.string()
              .max(50, 'Employee Last Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not include special characters');
            if (filter?.isRequired && !filter?.isStatic) schema['lastName'] = schema['lastName'].required('Employee Last Name is required');
            break;
          case 'mainPOCEmail':
            schema['associateEmpEmail'] = Yup.string().email('Invalid email address');
            if (filter?.isRequired && !filter?.isStatic)
              schema['associateEmpEmail'] = schema['associateEmpEmail'].required('Employee Email is required');
            break;
          case 'mainPOCPhone':
            schema['associateEmpPhone'] = Yup.number()
              .min(0)
              .positive('Employee Phone should be positive number')
              .integer('Employee Phone should be integer number')
              .typeError('Employee Phone be a number');
            if (filter?.isRequired && !filter?.isStatic) {
              schema['associateEmpPhone'] = schema['associateEmpPhone'].required('Employee Phone is required');
            }
            break;

          case 'mainPOCAddress':
            schema['associateEmpAdd'] = Yup.string().max(150, 'Must be at most 150 characters').trim();
            if (filter?.isRequired && !filter?.isStatic)
              schema['associateEmpAdd'] = schema['associateEmpAdd'].required('Employee Address is required');
            break;
          case 'secondaryPOCPrefix':
            schema['secondaryTitle'] = Yup.string().trim();
            if (filter?.isRequired && !filter?.isStatic) schema['secondaryTitle'] = schema['secondaryTitle'].required('Prefix is required');
            break;
          case 'secondaryPOCTitle':
            schema['secondaryDesignation'] = Yup.string().max(50, 'Employee Title must be at most 50 characters').trim();
            if (filter?.isRequired && !filter?.isStatic)
              schema['secondaryDesignation'] = schema['secondaryDesignation'].required('Employee Title is required');
            break;
          case 'secondaryPOCFirstName':
            schema['secondaryFirstName'] = Yup.string()
              .max(50, 'Employee First Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not include special characters');
            if (filter?.isRequired && !filter?.isStatic)
              schema['secondaryFirstName'] = schema['secondaryFirstName'].required('Employee First Name is required');
            break;
          case 'secondaryPOCLastName':
            schema['secondaryLastName'] = Yup.string()
              .max(50, 'Employee Last Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not include special characters');
            if (filter?.isRequired && !filter?.isStatic)
              schema['secondaryLastName'] = schema['secondaryLastName'].required('Employee Last Name is required');
            break;
          case 'secondaryPOCEmail':
            schema['SecondaryEmpEmail'] = Yup.string().email('Invalid email address');
            if (filter?.isRequired && !filter?.isStatic)
              schema['SecondaryEmpEmail'] = schema['SecondaryEmpEmail'].required('Employee Email is required');
            break;
          case 'secondaryPOCPhone':
            schema['SecondaryEmpPhone'] = Yup.number()
              .min(0)
              .positive('Employee Phone should be positive number')
              .integer('Employee Phone should be integer number')
              .typeError('Employee Phone be a number');
            if (filter?.isRequired && !filter?.isStatic) {
              schema['SecondaryEmpPhone'] = schema['SecondaryEmpPhone'].required('Employee Phone is required');
            }
            break;

          case 'secondaryPOCAddress':
            schema['SecondaryEmpAdd'] = Yup.string().max(150, 'Employee Address must be at most 150 characters').trim();
            if (filter?.isRequired && !filter?.isStatic)
              schema['SecondaryEmpAdd'] = schema['SecondaryEmpAdd'].required('Employee Address is required');
            break;
          case 'mainPOCRFID':
            schema['rfIdCodePrimary'] = Yup.string().when('orgSelection', {
              is: (orgSelection) => orgSelection.includes('Enterprise') && filter.isRequired && !filter?.isStatic,
              then: Yup.string().required('RFID Code is required'),
              otherwise: Yup.string().nullable()
            });
            break;
          case 'mainPOCAuthPIN':
            schema['authPinPrimary'] = Yup.number()
              .integer('Auth PIN must be an integer')
              .min(0, 'Auth PIN cannot be negative')
              .typeError('Auth PIN must be a valid number');
            if (filter?.isRequired && !filter?.isStatic) {
              schema['authPinPrimary'] = schema['authPinPrimary'].required('Auth PIN is required');
            }
            break;
          case 'secondaryPOCRFID':
            schema['rfIdCodeSecondary'] = Yup.string().when('orgSelection', {
              is: (orgSelection) => orgSelection.includes('Enterprise') && filter.isRequired && !filter?.isStatic,
              then: Yup.string().required('RFID Code is required'),
              otherwise: Yup.string().nullable()
            });
            break;
          case 'secondaryPOCAuthPIN':
            schema['authPinSecondary'] = Yup.number()
              .integer('Auth PIN must be an integer')
              .min(0, 'Auth PIN cannot be negative')
              .typeError('Auth PIN must be a valid number');
            if (filter?.isRequired && !filter?.isStatic) {
              schema['authPinSecondary'] = schema['authPinSecondary'].required('Auth PIN is required');
            }

            break;
          case 'serviceRadius':
            schema['service_radius'] = Yup.number()
              .min(0, 'Service Radius cannot be negative')
              .typeError('Service Radius must be a valid number')
              .when('orgSelection', {
                is: (orgSelection) =>
                  (orgSelection.includes('Recycler') || orgSelection.includes('Service Provider')) &&
                  filter.isRequired &&
                  !filter?.isStatic,
                then: Yup.string().required('Service Radius is required'),
                otherwise: Yup.string().nullable()
              });
            break;
          case 'orgName':
            schema['OrgName'] = Yup.string()
              .max(50, 'Organization Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[a-zA-Z0-9 .]*$/, 'Organization Name can only include letters, numbers, spaces, and dots');
            if (filter?.isRequired && !filter?.isStatic) schema['OrgName'] = schema['OrgName'].required('Organization Name is required');
            break;
          case 'website':
            schema['OrgWeb'] = Yup.string().url('Please enter a valid Organization Website Address');
            if (filter?.isRequired && !filter?.isStatic) schema['OrgWeb'] = schema['OrgWeb'].required('Website Address is required');
            break;
          case 'address':
            schema['locationPickUp'] = Yup.array().min(1, 'At least one location is required');
            if (filter?.isRequired && !filter?.isStatic)
              schema['locationPickUp'] = schema['locationPickUp'].required('Select Location is required');
            break;
          default:
            break;
        }
      }
      return schema;
    }, {})
  );

  const staticValidationSchema = Yup.object().shape({
    orgSelection: Yup.array().min(1, 'At least one organization type must be selected').required('Organization selection is required')
  });

  // Merge static and dynamic validation schemas
  const validationSchema = staticValidationSchema.concat(dynamicalidationSchema);

  return (
    <>
      {isLoading ? (
        <>
          <OrganizationFormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name="Edit Organization"
            obj={{ title: 'All Organizations', title2: 'Edit Organizations', url: '/dashboard/organizations' }}
          />
          <MainCard>
            <Formik
              initialValues={initials}
              validationSchema={validationSchema}
              onSubmit={async (value) => {
                const _location = value.locationPickUp
                  .filter((item) => item?.id !== '')
                  .map((item) => ({
                    details: item.id,
                    isPrimary: item?.isPrimary ? item?.isPrimary : false
                  }));
                // const _usr = value.users.filter((i) => i.firstName != '');
                // let secondaryPocId = value?.SecondarPocId;

                let secondaryPOCId = value?.SecondarPocId;

                // Handle the case where isBothPOCsSame is true and isChecked is false
                if (organizationNewDetails?.isBothPOCsSame === true && value?.isChecked === false) {
                  secondaryPOCId = null;

                  // Call check API and handle modal logic
                  organizationNullCheck(prepareSubmitData(), {
                    onSuccess: (res) => {
                      if (res?.status === 200 && res?.data?.data?.showModal == true) {
                        setOpenModal(!openModal);
                        setStoreEditObj(prepareSubmitData());
                      } else {
                        // Call org update API with the final submitData
                        updateOrganizationNew(prepareSubmitData());
                        navigate(-1);
                      }
                    }
                  });
                } else {
                  // For other cases, update secondaryPOCId if needed
                  if (organizationNewDetails?.isBothPOCsSame === false && value?.isChecked === true) {
                    secondaryPOCId = value?.mainPocId;
                  }

                  // Prepare and call the update API
                  updateOrganizationNew(prepareSubmitData());
                  navigate(-1);
                }

                // Helper function to prepare submitData
                function prepareSubmitData() {
                  return {
                    id: id.slice(1),
                    isBothPOCsSame: value?.isChecked,
                    name: value?.OrgName,
                    website: value?.OrgWeb,
                    serviceRadius:
                      value?.orgSelection?.includes('Recycler') || value?.orgSelection?.includes('Service Provider')
                        ? {
                            unit: value?.serviceRadiusUnit,
                            value: value?.service_radius
                          }
                        : null,
                    mainPOC: {
                      id: value?.mainPocId,
                      prefix: value?.title,
                      title: value?.designation,
                      firstName: value?.firstName,
                      lastName: value?.lastName,
                      email: value?.associateEmpEmail,
                      phone: value?.associateEmpPhone,
                      address: value?.associateEmpAdd,
                      authenticationInfo: {
                        rfidCode: value?.orgSelection?.includes('Enterprise') ? value?.rfIdCodePrimary : '',
                        rfidPIN: value?.orgSelection?.includes('Enterprise') ? value?.authPinPrimary : ''
                      },
                      types: value?.isChecked ? ['Main POC', 'Secondary POC'] : ['Main POC'],
                      organizationTypes: value?.orgSelection
                    },
                    secondaryPOC: {
                      id: secondaryPOCId,
                      prefix: value?.secondaryTitle,
                      title: value?.secondaryDesignation,
                      firstName: value?.secondaryFirstName,
                      lastName: value?.secondaryLastName,
                      email: value?.SecondaryEmpEmail,
                      phone: value?.SecondaryEmpPhone,
                      address: value?.SecondaryEmpAdd,
                      authenticationInfo: {
                        rfidCode: value?.orgSelection?.includes('Enterprise') ? value?.rfIdCodeSecondary : '',
                        rfidPIN: value?.orgSelection?.includes('Enterprise') ? value?.authPinSecondary : ''
                      },
                      types: ['Secondary POC'],
                      organizationTypes: value?.orgSelection
                    },
                    locations: _location,
                    types: value?.orgSelection
                  };
                }
              }}
              validateOnBlur={true}
              validateOnChange={true}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                setTouched,
                setValues
              }) => (
                <>
                  {editPermissionsShowEdit ? (
                    <>
                      <OrganizationsNewForm
                        btnName="Edit"
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        // id={id}
                        setTouched={setTouched}
                        setValues={setValues}
                        isLoadingOrganizationNew={isLoadingOrganizationNew || isLoadingOrganizationNullCheck}
                        organizationNewDetails={organizationNewDetails}
                        buttonPermissions={buttonPermissions}
                        mainPOPermissions={mainPOPermissions}
                        secondPOPermissions={secondPOPermissions}
                        otherPOPermissions={otherPOPermissions}
                        isEditable={true}
                      />
                    </>
                  ) : (
                    <>
                      <Alert variant="outlined" severity="error" icon={<Lock />}>
                        <AlertTitle>Permission Denied</AlertTitle>
                        You cannot edit this Organization due to your access permissions.
                      </Alert>
                    </>
                  )}
                </>
              )}
            </Formik>
          </MainCard>
          {storeEditObj && (
            <>
              <ConfirmDialogOrg
                open={openModal}
                setOpen={setOpenModal}
                title={'Confirmation !'}
                subTitle={'An user already exists in your organization with this email. Do you want to add this user as secondary POC?'}
                handleSubmit={() => {
                  updateOrganizationNew(storeEditObj);
                }}
                isDeleteLoading={isLoadingOrganizationNew}
              />
            </>
          )}

          {/* <ConfirmDialogOrg
            open={true}
            setOpen={setOpenModal}
            title={'Confirmation !'}
            subTitle={'An user already exists in your organization with this email. Do you want to add this user as secondary POC?'}
            handleSubmit={() => {
              updateOrganizationNew(storeEditObj);
            }}
            isDeleteLoading={isLoadingOrganizationNew}
          /> */}
        </>
      )}
    </>
  );
};

export default OrganizationsNewEdit;

import { useMutation, useQuery } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

/**
 * The function `useGetEnterpriseMachineAllList` fetches a list of machines belonging to a specific
 * enterprise using a POST request.
 * @returns The `useGetEnterpriseMachineAllList` function is returning a custom hook that uses
 * `useQuery` from a library like React Query. This hook makes a POST request to a specific endpoint to
 * fetch a list of machines belonging to a particular enterprise. If the request is successful (status
 * code 200), it returns the data received from the API response. If there is an error or the response
 * status
 */
export const useGetEnterpriseMachineAllList = ({ enterpriseID }) => {
  return useQuery({
    queryKey: ['getEnterpriseMachineList', enterpriseID],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machines}`, {
          enterprise: enterpriseID
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useGetTicketReasonDataList` is a custom hook that fetches ticket reason data list
 * using axios and returns the data.
 * @returns The `useGetTicketReasonDataList` function returns a query object that makes a POST request
 * to fetch ticket reasons data from the API endpoint `v1/ticketReasons`. If the request is successful
 * (status code 200), it returns the data received from the response. If there is an error or the
 * response status is not 200, it returns an empty array. The `onSuccess
 */
export const useGetTicketReasonDataList = () => {
  return useQuery({
    queryKey: ['getTicketReasonDataList'],
    queryFn: async () => {
      return await axiosInstance
        .post(`v1/ticketReasons`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useTicketFileUpload` is a custom hook that handles file uploads using axios and
 * provides callbacks for success, error, and settled states.
 * @returns The `useTicketFileUpload` function returns a custom hook that uses a mutation function to
 * upload a file using axios. The function handles success, error, and settled states of the mutation
 * and sets toast messages accordingly.
 */
export const useTicketFileUpload = () => {
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .post(`v1/files/upload`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data?.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, 'Something went wrong!');
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useTicketFileDelete` is a custom hook that handles deleting files using a mutation
 * function with error and success handling.
 * @returns The `useTicketFileDelete` function is returning a custom hook that uses the `useMutation`
 * hook from an external library. This custom hook is responsible for making an asynchronous POST
 * request to delete a file using the `axiosInstance`. It handles success and error responses by
 * setting a toast message accordingly. The `onSuccess`, `onError`, and `onSettled` callbacks are
 * defined to
 */
export const useTicketFileDelete = () => {
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .post(`v1/files/delete`, data)

        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data?.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, 'Something went wrong!');
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

/**
 * The useAddEnterpriseTicket function is an asynchronous function that sends a POST request to create
 * a new ticket using the provided data.
 * @param data - The `data` parameter in the `useAddEnterpriseTicket` function likely contains the
 * information needed to create a new ticket for an enterprise. This data could include details such as
 * the ticket title, description, priority, assignee, due date, and any other relevant information
 * required for creating a ticket in
 * @returns The function `useAddEnterpriseTicket` is returning the response object `res` from the POST
 * request made to the specified endpoint `${endpoints.ticket}/create` with the provided `data`.
 */
export const useAddEnterpriseTicket = async (data) => {
  const res = await axiosInstance.post(`${endpoints.ticket}/create`, data);
  return res;
};

/**
 * The function `useGetEnterpriseTicketDataList` is a custom hook that fetches enterprise ticket data
 * based on specified parameters using axios and returns the data.
 * @returns The `useGetEnterpriseTicketDataList` function returns a custom hook that uses `useQuery`
 * from a library like React Query. This hook fetches enterprise ticket data based on the provided
 * parameters such as limit, sortOrder, sortField, current, previous, first, last, and enterpriseId.
 * The function makes a POST request to a specific endpoint using axios, passing the parameters in the
 * request body
 */
export const useGetEnterpriseTicketDataList = ({ limit, sortOrder, sortField, current, previous, first, last, enterpriseId, status }) => {
  return useQuery({
    queryKey: ['getEnterpriseTicketDataList', limit, sortOrder, sortField, current, previous, first, last, enterpriseId, status],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.ticket}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          enterprise: enterpriseId,
          status: status
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useGetEnterpriseTicketChatList` fetches a list of chat messages related to a specific
 * ticket ID using axios and returns the data.
 * @returns The `useGetEnterpriseTicketChatList` function returns a custom hook that uses `useQuery`
 * from a library like React Query to fetch a list of chat messages related to a specific ticket ID in
 * an enterprise system. The function makes a POST request to the server endpoint for fetching chats
 * associated with the provided `ticketID`. If the request is successful (status code 200), it returns
 * the data
 */
export const useGetEnterpriseTicketChatList = ({ ticketID }) => {
  return useQuery({
    queryKey: ['getEnterpriseTicketChatList', ticketID],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.ticket}/${ticketID}/chats`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(ticketID),
    refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The useAddEnterpriseTicketChatCreate function sends a POST request to create a chat for a specific
 * ticket in an enterprise system.
 * @param data - The `data` parameter is an object that contains information needed to create a new
 * chat for an enterprise ticket. It likely includes details such as the `ticketId` of the ticket for
 * which the chat is being created and any other relevant data required for the chat creation process.
 * @returns The function `useAddEnterpriseTicketChatCreate` is returning the response from the POST
 * request made to the specified endpoint with the provided data.
 */
export const useAddEnterpriseTicketChatCreate = async (data) => {
  const res = await axiosInstance.post(`${endpoints.ticket}/${data?.ticketId}/chats/create`, data);
  return res;
};

/**
 * The function `useGetEnterpriseTicketById` is a custom hook that fetches enterprise ticket details by
 * ID using axios and returns the data.
 * @returns The `useGetEnterpriseTicketById` function is returning a custom hook that uses `useQuery`
 * from a library like React Query. This hook fetches enterprise ticket details by ID using an HTTP GET
 * request to a specific endpoint. If the request is successful (status code 200), it returns the data
 * from the response. If there is an error or the response status is not 200, it
 */
export const useGetEnterpriseTicketById = ({ id }) => {
  return useQuery({
    queryKey: ['getEnterpriseTicketDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.ticket}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// export const useEnterpriseTicketStatusUpdate = () => {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: async (data) => {
//       return await axiosInstance
//         .patch(`${endpoints.ticket}/${data?.id}/status`, { enabled: data?.enabled })
//         .then((res) => {
//           if (res?.status == 200) {
//             toastConfig.type = 'success';
//             setToast(toastConfig, res?.data.success);
//           } else {
//             toastConfig.type = 'error';
//             setToast(toastConfig, error?.response?.data?.error);
//           }

//           return res;
//         })
//         .catch((error) => {
//           toastConfig.type = 'error';
//           setToast(toastConfig, error?.response?.data?.error);
//         });
//     },
//     onSuccess: ({ data }) => {
//       queryClient.refetchQueries('getRecycleData');
//       return data;
//     },
//     onError: ({ error }) => {
//       return error;
//     },
//     onSettled: ({ data }) => {
//       return data;
//     }
//   });
// };

/**
 * The function `useEnterpriseTicketStatusUpdate` updates the status of an enterprise ticket using a
 * PATCH request.
 * @param data - The `data` parameter in the `useEnterpriseTicketStatusUpdate` function likely contains
 * the following properties:
 * @returns The function `useEnterpriseTicketStatusUpdate` is returning the response from the PATCH
 * request made using axiosInstance to update the ticket status.
 */
export const useEnterpriseTicketStatusUpdate = async (data) => {
  const res = await axiosInstance.patch(`${endpoints.ticket}/${data?.ticketID}/status`, {
    status: data?.status,
    isEnterprise: data?.isEnterprise
  });
  return res;
};

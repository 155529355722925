import PropTypes from 'prop-types';
import React, { useState } from 'react';

// Material-UI Components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  IconButton,
  Button,
  List,
  ListItem,
  CircularProgress,
  Typography,
  Chip,
  Box,
  Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// Icons
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import PaletteIcon from '@mui/icons-material/Palette';
// import DeleteIcon from '@mui/icons-material/Delete';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
// UI Components
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import { useNavigate } from 'react-router-dom';
import { useGetSubscriptionsList, useSubscriptionsStatusUpdate } from 'hooks/useSubscriptionsHooks';
import SkeletonTableView from 'ui-component/cards/Skeleton/TableView';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
// import { useSelector } from 'react-redux';
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  })
);
// Sorting Functions
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

// Table Head Component
function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {[
          'Title',
          'Description',
          'Price',
          'Interval',
          'Features',
          'Machine Specific User(s)',
          'Global User(s)',
          'Plan Color',
          'Show',
          'Status'
        ].map((label, index) => (
          <TableCell key={index} align="center">
            <TableSortLabel active={orderBy === label} direction={orderBy === label ? order : 'asc'} onClick={createSortHandler(label)}>
              {label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="center" width="10%">
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

// Collapsible Row Component
function CollapsibleRow({ row, navigate, updatedSubscriptionStatus, isLoadingSubscriptionStatus }) {
  return (
    <TableRow hover>
      <HtmlTooltip title={row.title}>
        <TableCell align="center">{row?.title?.length > 24 ? row?.title?.slice(0, 24)?.trim() + '...' : row?.title}</TableCell>
      </HtmlTooltip>
      <HtmlTooltip title={row.description}>
        <TableCell align="left">
          {row?.description?.length > 24 ? row?.description?.slice(0, 24)?.trim() + '...' : row?.description}
        </TableCell>
      </HtmlTooltip>
      <TableCell align="center">${row?.price?.amount}</TableCell>
      <TableCell align="center">{row?.price?.interval}</TableCell>
      <HtmlTooltip
        title={
          <List>
            {row?.features?.length > 0 &&
              row?.features?.map((feature, i) => (
                <ListItem key={i}>
                  {i + 1}. {feature}
                </ListItem>
              ))}
          </List>
        }
      >
        <TableCell align="center">{row?.features?.length} Feature(s)</TableCell>
      </HtmlTooltip>
      <TableCell align="center">{row?.noUsers?.machineSpecificUser}</TableCell>
      <TableCell align="center">{row?.noUsers?.globalUser}</TableCell>
      <TableCell align="center">
        <Button style={{ color: row?.colorCode?.hex }} variant="text" startIcon={<PaletteIcon style={{ color: row?.colorCode?.hex }} />}>
          {`${row?.colorCode?.hex}`}
        </Button>
      </TableCell>
      <TableCell align="center">
        <Chip
          label={row?.isPublic ? 'Public' : 'Private'}
          color={row?.isPublic ? 'info' : 'warning'}
          style={{
            color: row?.isPublic ? '#fff' : 'brown'
          }}
          size="small"
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          paddingLeft: 0,
          // paddingRight: 2,
          alignItems: 'center'
        }}
      >
        <HtmlTooltip
          title={
            <>
              <Typography
                subtitle1="h2"
                component={'p'}
                sx={{
                  alignItems: 'center'
                }}
              >
                <p style={{ textAlign: 'center' }}>
                  Are you sure want to {!row?.enabled === true ? 'activate' : 'deactivate'} the {row?.title}?
                </p>
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  mt: 1,
                  mb: 1,
                  justifyContent: 'center'
                }}
              >
                <Button
                  variant="outlined"
                  style={{ padding: '2px 10px' }}
                  onClick={() => {
                    updatedSubscriptionStatus({
                      id: row?.id,
                      // enabled: !row?.enabled
                      status: !row?.enabled
                    });
                  }}
                >
                  {isLoadingSubscriptionStatus ? (
                    <>
                      <CircularProgress
                        sx={{
                          color: '#6fd74b',
                          height: 5,
                          width: 5
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <DoneOutlinedIcon /> Yes
                    </>
                  )}
                </Button>
              </Stack>
            </>
          }
        >
          <Chip
            // label={row.status === 'on' ? 'Active' : 'Inactive'}
            // color={row.status === 'on' ? 'success' : 'error'}
            label={row?.enabled === true ? 'Active' : 'Inactive'}
            // color={row?.enabled === true ? 'success' : 'error'}
            style={{
              color: '#fff',
              backgroundColor: row?.enabled === true ? '#6fd74b' : '#f44336'
            }}
            size="small"
          />
        </HtmlTooltip>
      </TableCell>
      <TableCell align="center">
        <IconButton
          onClick={() => {
            navigate(`/dashboard/subscriptions/edit-${row?.id}`);
          }}
        >
          <ModeEditOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

// Main Component
const Subscriptions = () => {
  const [order, setOrder] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  // const [order, setOrder] = useState('asc');
  // const [orderBy, setOrderBy] = useState('title');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filterObj, setFilterObj] = useState({
    title: '',
    price: '',
    isPriceLess: '==',
    interval: '',
    localUser: '',
    globalUser: ''
  });
  const [openFilterModal, setOpenFilterModal] = useState(true);
  // const organizationType = useSelector((state) => state.customization.orgType);
  // const usersType = useSelector((state) => state.customization.userType);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  const { mutate: updatedSubscriptionStatus, isPending: isLoadingSubscriptionStatus } = useSubscriptionsStatusUpdate();
  const { data: allSubscriptionList, isLoading: isSubscriptionLoading } = useGetSubscriptionsList({
    limit: rowsPerPage,
    page: currentPage ? currentPage : previousPage,
    // orgName: filterObj?.orgName || '',
    // status: filterObj?.status || '',
    // previous: previousPage,
    // current: currentPage,
    // first: firstPageVal,
    // last: lastPageVal

    filters: {
      title: filterObj?.title,
      price: filterObj?.price
        ? {
            amount: filterObj?.price,
            type: filterObj?.isPriceLess
          }
        : null,
      // enabled: true,
      enabled: null,
      machineSpecificUser: filterObj?.localUser,
      globalUser: filterObj?.globalUser,
      interval: filterObj?.interval
    },

    sort: {
      sortField: orderBy,
      sortOrder: order
    }
  });
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setFirstPageVal(allEnterList?.data?.first);
    // setLastPageVal(allEnterList?.data?.last);
    setCurrentPage(newPage + 1);
    setPreviousPage(currentPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {isSubscriptionLoading ? (
        <>
          <SkeletonTableView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage name="All Subscriptions" obj={{ title: 'Subscriptions', title2: '', url: '' }} />
          <MainCard
            title=""
            isButton={true}
            url="/dashboard/subscriptions/add"
            isEnable={true}
            isFilter={true}
            isSubscriptionsFilter={true}
            openFilterAllModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
            setFilterObj={setFilterObj}
            filterObj={filterObj}
            permissionsList={[]}
          >
            <TableContainer>
              {allSubscriptionList?.data?.subscriptions === undefined || allSubscriptionList?.data?.subscriptions?.length == 0 ? (
                <StyledGridOverlay style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%' }}>
                  <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                    <g fill="none" fillRule="evenodd">
                      <g transform="translate(24 31.67)">
                        <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                        <path
                          className="ant-empty-img-1"
                          d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                          className="ant-empty-img-2"
                          d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                          className="ant-empty-img-3"
                          d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                      </g>
                      <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                      />
                      <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                      </g>
                    </g>
                  </svg>
                  <Box sx={{ mt: 1 }}>No data found!</Box>
                </StyledGridOverlay>
              ) : (
                <Table stickyHeader>
                  <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                  <TableBody>
                    {stableSort(allSubscriptionList?.data?.subscriptions, getComparator(order, orderBy))?.map((row, i) => (
                      <CollapsibleRow
                        key={i}
                        row={row}
                        navigate={navigate}
                        updatedSubscriptionStatus={updatedSubscriptionStatus}
                        isLoadingSubscriptionStatus={isLoadingSubscriptionStatus}
                      />
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={allSubscriptionList?.data?.rowPerPage}
              component="div"
              count={allSubscriptionList?.data?.totalCount ? allSubscriptionList?.data?.totalCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // getItemAriaLabel={getItemAriaLabel}
            />
          </MainCard>
        </>
      )}
    </>
  );
};

// Prop Types
EnhancedTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func.isRequired
};

CollapsibleRow.propTypes = {
  row: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  updatedSubscriptionStatus: PropTypes.func.isRequired,
  isLoadingSubscriptionStatus: PropTypes.bool.isRequired
};

export default Subscriptions;

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  // InputLabel,
  // MenuItem,
  Paper,
  // Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { ErrorMessage, Field, FieldArray, Form } from 'formik';
import React from 'react';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useDeleteMachineSettings, useGetAllMachinePrice, useGetAllMachineSettings, useGetAllMachineVersion } from 'hooks/useSettingsHooks';
import { gridSpacing } from 'store/constant';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DeleteDialog from 'ui-component/delete-dialog';
import { useState } from 'react';
import { setToast, toastConfig } from 'utils/commonUtil';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
const MachineAllSettings = ({
  errors,
  setFieldValue,
  // handleSubmit,
  // handleBlur,
  // handleChange,
  values,
  touched,
  isLoadingMachineSettings
}) => {
  // console.log('🚀 ~ values:', values);
  // console.log('🚀 ~ MachineAllSettings ~ errors:', errors);
  const theme = useTheme();

  const { data: machineVersionList } = useGetAllMachineVersion({
    isActive: true
  });
  const { data: machineSettingsList, isPending: isMachineSettingsListLoading } = useGetAllMachineSettings({
    isActive: true
  });
  const { data: addMachinePriceDetails } = useGetAllMachinePrice({
    id: '6751558ae312a8253a026097'
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [machineSettingsId, setMachineSettingsId] = useState('');
  const { mutate: deleteMachineSettings, isPending: isDeleteMachineSettings } = useMutation({
    mutationFn: useDeleteMachineSettings
  });
  const queryClient = useQueryClient();
  useEffect(() => {
    if (machineSettingsList) {
      const rows = machineSettingsList.map((element, index) => ({
        id: element.id,
        item: `${index + 1}`,
        model: element.model,
        version: element.versions || [],
        machineType: element.types || [],
        basePrice: element.basePrice,
        perDriveCost: element.perDriveCost
      }));

      setFieldValue('rows', rows);
    }
  }, [machineSettingsList, setFieldValue]);

  return (
    <>
      {isMachineSettingsListLoading ? (
        <>
          <OrganizationFormView />
        </>
      ) : (
        <Form>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Lorem Ipsum
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography component="h5" variant="h4" sx={{ mb: 2 }}>
                  Lorem Ipsum details.
                </Typography>
                <Grid container className="settings-row-page">
                  <TableContainer component={Paper} className="machine-settings-table">
                    <Table
                      sx={{ minWidth: 700, marginTop: '20px!important' }}
                      aria-label="spanning table"
                      size="small"
                      className="new-table-classs new-table-classs2 "
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" style={{ width: '40px' }}>
                            Item
                          </TableCell>
                          <TableCell align="center" style={{ width: '80px' }}>
                            Model *
                          </TableCell>
                          <TableCell align="center" style={{ width: '100px' }}>
                            Version *
                          </TableCell>{' '}
                          {/* Increased width for description */}
                          <TableCell align="center" style={{ width: '90px' }}>
                            Type(s)*
                          </TableCell>
                          <TableCell align="center" style={{ width: '90px' }}>
                            Base Price *
                          </TableCell>
                          <TableCell align="center" style={{ width: '90px' }}>
                            Per Drive Cost *
                          </TableCell>
                          <TableCell style={{ width: '90px' }}></TableCell> {/* Reduced space for icons */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <FieldArray name="rows">
                          {({ insert }) => (
                            <>
                              {values.rows.map((row, index) => (
                                <TableRow key={row.id}>
                                  <TableCell>{row.item}</TableCell>
                                  <TableCell>
                                    <Field
                                      placeholder="Model"
                                      name={`rows.${index}.model`}
                                      as={TextField}
                                      fullWidth
                                      error={touched.rows?.[index]?.model && !!errors.rows?.[index]?.model}
                                      helperText={<ErrorMessage name={`rows.${index}.model`} />}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <FormControl
                                      fullWidth
                                      sx={{ ...theme.typography.customInput }}
                                      error={touched.rows?.[index]?.version && !!errors.rows?.[index]?.version}
                                      className="enterprise-location"
                                    >
                                      <Field name={`rows.${index}.version`}>
                                        {({ field, form }) => (
                                          <Autocomplete
                                            multiple
                                            id={`rows.${index}.version`}
                                            options={(machineVersionList || []).map((item) => item?.version)} // Map to extract "version"
                                            getOptionLabel={(option) => option} // Each option is a string
                                            isOptionEqualToValue={(option, value) => option === value} // Compare strings directly
                                            value={field.value || []} // Use Formik's field value
                                            onChange={(event, newValue) => {
                                              console.log('🚀 ~ newValue:', newValue);
                                              form.setFieldValue(`rows.${index}.version`, newValue); // Set the field value
                                            }}
                                            renderTags={(selected, getTagProps) =>
                                              selected.map((option, tagIndex) => (
                                                <Chip key={tagIndex} label={option} {...getTagProps({ index: tagIndex })} />
                                              ))
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Select Versions"
                                                variant="outlined"
                                                error={touched.rows?.[index]?.version && !!errors.rows?.[index]?.version}
                                                helperText={<ErrorMessage name={`rows.${index}.version`} />}
                                                onBlur={() => form.setFieldTouched(`rows.${index}.version`, true)} // Mark as touched
                                              />
                                            )}
                                          />
                                        )}
                                      </Field>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell>
                                    <FormControl
                                      fullWidth
                                      error={touched.rows?.[index]?.machineType && !!errors.rows?.[index]?.machineType}
                                      className="enterprise-location"
                                    >
                                      <Field name={`rows.${index}.machineType`}>
                                        {({ field, form }) => (
                                          <Autocomplete
                                            multiple
                                            options={['E', 'R']} // Add your options here
                                            value={field.value || []}
                                            onChange={(event, value) => {
                                              form.setFieldValue(`rows.${index}.machineType`, value);
                                            }}
                                            renderTags={(tagValue, getTagProps) =>
                                              tagValue.map((option, idx) => (
                                                <Chip key={option} label={option} {...getTagProps({ index: idx })} />
                                              ))
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label="Machine Type"
                                                error={touched.rows?.[index]?.machineType && !!errors.rows?.[index]?.machineType}
                                                helperText={touched.rows?.[index]?.machineType && errors.rows?.[index]?.machineType}
                                              />
                                            )}
                                            // sx={{ ...theme.typography.customInput, textAlign: 'left' }}
                                          />
                                        )}
                                      </Field>
                                    </FormControl>
                                  </TableCell>
                                  <TableCell>
                                    <Field
                                      placeholder="Base Price"
                                      name={`rows.${index}.basePrice`}
                                      type="number"
                                      as={TextField}
                                      fullWidth
                                      error={touched.rows?.[index]?.basePrice && !!errors.rows?.[index]?.basePrice}
                                      id="outlined-start-adornment"
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                      }}
                                      helperText={<ErrorMessage name={`rows.${index}.basePrice`} />}
                                    />
                                  </TableCell>

                                  <TableCell>
                                    <Field
                                      placeholder="Per Drive Cost"
                                      name={`rows.${index}.perDriveCost`}
                                      type="number"
                                      as={TextField}
                                      fullWidth
                                      error={touched.rows?.[index]?.perDriveCost && !!errors.rows?.[index]?.perDriveCost}
                                      id="outlined-start-adornment"
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                      }}
                                      helperText={<ErrorMessage name={`rows.${index}.perDriveCost`} />}
                                    />
                                  </TableCell>

                                  <TableCell>
                                    {index === 0 ? (
                                      <>
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            insert(values.rows.length, {
                                              // id: values.rows.length + 1,
                                              item: `${values.rows.length + 1}`,
                                              model: '',
                                              version: [],
                                              basePrice: addMachinePriceDetails?.enterpriseBasePrice,
                                              // basePrice: 0,
                                              perDriveCost: 0,
                                              machineType: []
                                            })
                                          }
                                          style={{ padding: '5px' }} // Reduce icon button size
                                        >
                                          <AddIcon />
                                        </IconButton>
                                        <IconButton
                                          disabled
                                          color="secondary"
                                          style={{ padding: '5px' }} // Reduce icon button size
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </>
                                    ) : (
                                      // </div>
                                      <IconButton
                                        color="secondary"
                                        onClick={() => {
                                          if (row.id) {
                                            setOpenDeleteDialog(!openDeleteDialog);
                                            setMachineSettingsId(row.id);
                                          }
                                        }}
                                        style={{ padding: '5px', color: 'red' }} // Reduce icon button size
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </FieldArray>
                      </TableBody>
                    </Table>

                    <Table size="small" className="new-tableborder">
                      <TableBody>
                        <TableRow className="border-none-table">
                          <TableCell rowSpan={4} style={{ width: '228px' }} />

                          {/* <TableCell rowSpan={3} style={{ width: '300px' }} /> */}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isLoadingMachineSettings}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingMachineSettings ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `Save`
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Form>
      )}

      <DeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title={'Confirmation !'}
        subTitle={'Are you sure to delete the machine settings?'}
        handleSubmit={() => {
          deleteMachineSettings(machineSettingsId, {
            onSuccess: (res) => {
              if (res?.status == 200) {
                toastConfig.type = 'success';
                setToast(toastConfig, res?.data.message);
                queryClient.refetchQueries({
                  queryKey: ['getallMachineSettings'],
                  type: 'active'
                });
              } else {
                toastConfig.type = 'error';
                setToast(toastConfig, 'Something went wrong!');
              }
            },
            onError: (error) => {
              toastConfig.type = 'error';
              setToast(toastConfig, error?.response?.data?.error);
            }
          });
        }}
        isDeleteLoading={isDeleteMachineSettings}
      />
    </>
  );
};

export default MachineAllSettings;

// import React from 'react'

// const EnterpriseUserEdit = () => {
//   return (
//     <div>EnterpriseUserEdit</div>
//   )
// }

// export default EnterpriseUserEdit

import React from 'react';
import { Formik } from 'formik';

import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';

import { useParams } from 'react-router-dom';
import FormView from 'ui-component/cards/Skeleton/FormView';
import { useState, useEffect } from 'react';
import { useGetEnterpriseUserById, useGetEnterpriseUserUpdate } from 'hooks/useEnterpriseHooks';
import EnterpriseUserForm from '../enterprise-user-form';
// import { useGetProfileById } from 'hooks/useProfileHooks';

const EnterpriseUserEdit = () => {
  let { id } = useParams();
  const { data: enterUserdetails } = useGetEnterpriseUserById({
    id: id.slice(1)
  });

  // const { data: profileDetails } = useGetProfileById();
  // console.log('Profile', profileDetails, 'id', id, id.slice(1));

  const { mutate: updatedEnterUser, isPending: isLoadingEnterprise } = useGetEnterpriseUserUpdate();

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <FormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage name={`Edit User`} obj={{ title: 'All  Users ', title2: 'Edit  User', url: '/dashboard/enterprise/user' }} />
          <MainCard>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                rfidCode: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .max(50, ' First Name must be at most 50 characters')
                  .required(' First Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\[\]{}:';"\\|,.<>\\?]*$/, ' First Name should not be in any special character'),

                lastName: Yup.string()
                  .max(50, ' Last Name must be at most 50 characters')
                  .required(' Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\[\]{}:';"\\|,.<>\\?]*$/, ' Last Name should not be in any special character'),
                email: Yup.string().email('Invalid email address').required(' Email is required')
                // rfidCode: Yup.string().max(30, 'Must be of 30 characters').required('RFID Code is required')
              })}
              onSubmit={async (value) => {
                // let { enterprise } = JSON.parse(localStorage.getItem('userData'));
                // console.log('enID', enterprise);

                // if (!enterprise?.id) {
                //   toastConfig.type = 'error';
                //   setToast(toastConfig, 'Enterprice ID not found!');
                //   return;
                // }
                // const submitData2 = {
                //   firstName: value?.firstName.trim(),
                //   lastName: value?.lastName.trim(),
                //   email: value?.email.trim(),
                //   rfidCode: value?.rfidCode.trim(),
                //   enterprise: enterprise?.id
                // };

                const submitData = {
                  firstName: value?.firstName.trim(),
                  lastName: value?.lastName.trim(),
                  email: value?.email.trim(),
                  rfidCode: value?.rfidCode.trim(),
                  id: id.slice(1)
                  // enterprise: profileDetails?.enterprise?.id
                  // enterprise: enterprise?.id
                };
                // console.log('Prrr', profileDetails);
                // console.log('ss', submitData);

                updatedEnterUser({ data: submitData, id: id.slice(1) });
                //  resetForm({});
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                setValues,
                setFieldError
              }) => (
                <EnterpriseUserForm
                  btnName="Update"
                  errors={errors}
                  setError={setFieldError}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  isLoadingEnterprise={isLoadingEnterprise}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  enterUserdetails={enterUserdetails}
                  id={id}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default EnterpriseUserEdit;

import { useQuery } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';

/**
 * The function `useRecyclerMachines` fetches data for recycler machines based on specified parameters
 * using axios and returns the data.
 * @returns The `useRecyclerMachines` function returns a custom hook that uses `useQuery` from an
 * unspecified library. This hook fetches data by making a POST request to a specified endpoint
 * (`endpoints.machines`) with the provided parameters such as `limit`, `sortOrder`, `sortField`,
 * `current`, `previous`, `first`, `last`, and `recycler`.
 */
export const useRecyclerMachines = ({ limit, sortOrder, sortField, current, previous, first, last, recycler }) => {
  return useQuery({
    queryKey: ['getRecyclerMachinesDataList', limit, sortOrder, sortField, current, previous, first, last, recycler],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machines}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          recycler: recycler
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log('Error to load user data from recycler', e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField),
    refetchOnMount: false,
    staleTime: 0,

    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * The function `useGetJobRecyclerList` is a custom hook that fetches job data based on specified
 * parameters using axios and returns the data.
 * @returns The `useGetJobRecyclerList` function returns a custom hook that uses `useQuery` from a
 * library like React Query. This hook makes a POST request to a specific endpoint (`endpoints.jobs`)
 * with the provided parameters such as `limit`, `sortOrder`, `sortField`, `current`, `previous`,
 * `first`, `last`, `recycler`, and `machineId`.
 */
export const useGetJobRecyclerList = ({ limit, sortOrder, sortField, current, previous, first, last, recycler, machineId }) => {
  return useQuery({
    queryKey: ['getJobRecylerDataList', limit, sortOrder, sortField, current, previous, first, last, recycler, machineId],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.jobs}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          machineId: machineId,
          recycler: recycler
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    },
    enabled: Boolean(limit) && Boolean(sortField),
    refetchOnMount: false,
    staleTime: 0
  });
};

/**
 * The function `useGetAllMachineRecyclerList` fetches a list of machines associated with a specific
 * recycler using a POST request.
 * @returns The `useGetAllMachineRecyclerList` function is returning a custom hook that uses `useQuery`
 * from a library like React Query. This hook fetches data from an API endpoint using a POST request to
 * retrieve a list of machines associated with a specific recycler. The function returns the data
 * fetched from the API if the response status is 200, otherwise it returns an empty array. The
 */
export const useGetAllMachineRecyclerList = ({ recycler }) => {
  return useQuery({
    queryKey: ['getAllMachineListRecyclerData', recycler],
    queryFn: async () => {
      return await axiosInstance

        .post(`${endpoints.machines}`, {
          recycler: recycler
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    },
    enabled: Boolean(recycler),
    refetchOnMount: false,
    staleTime: 0
  });
};

/**
 * The function `useGetRecyclerTicketDataList` is a custom hook that fetches recycler ticket data based
 * on specified parameters using axios and react-query.
 * @returns The `useGetRecyclerTicketDataList` function returns a custom hook that uses `useQuery` from
 * a library like React Query to fetch recycler ticket data based on the provided parameters such as
 * limit, sortOrder, sortField, current, previous, first, last, and recycler. The function makes a POST
 * request to a specific endpoint using axios, passing the parameters in the request body.
 */
export const useGetRecyclerTicketDataList = ({ limit, sortOrder, sortField, current, previous, first, last, recycler, status }) => {
  return useQuery({
    queryKey: ['getRecyclerTicketDataList', limit, sortOrder, sortField, current, previous, first, last, recycler, status],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.ticket}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder,
          recycler: recycler,
          status: status
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(limit) && Boolean(sortField),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    },
    refetchOnMount: false,
    staleTime: 0
  });
};

/**
 * The function `useGetRecyclerTicketById` is a custom hook in JavaScript that fetches details of a
 * recycler ticket by its ID using axios and returns the data.
 * @returns The `useGetRecyclerTicketById` function returns a query object that fetches details of a
 * recycler ticket by its ID using an asynchronous axios GET request. The function includes a query
 * key, query function that fetches data from the API endpoint, an enabled flag based on the presence
 * of the ID, and an onSuccess callback that returns the data fetched from the API.
 */
export const useGetRecyclerTicketById = ({ id }) => {
  return useQuery({
    queryKey: ['getRecyclerTicketDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.ticket}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/**
 * This function sends a POST request to create a new chat for a specific ticket using axios.
 * @param data - The `data` parameter in the `useAddRecyclerTicketChatCreate` function is an object
 * that contains information needed to create a new chat for a specific ticket. It likely includes the
 * `ticketId` of the ticket for which the chat is being created, as well as any other relevant data
 * @returns The function `useAddRecyclerTicketChatCreate` is returning the response from the POST
 * request made to the specified endpoint with the provided data.
 */
export const useAddRecyclerTicketChatCreate = async (data) => {
  const res = await axiosInstance.post(`${endpoints.ticket}/${data?.ticketId}/chats/create`, data);
  return res;
};

/**
 * The function `useGetRecyclerTicketChatList` fetches a list of chat messages related to a specific
 * ticket ID using axios and returns the data.
 * @returns The `useGetRecyclerTicketChatList` function returns the result of a query made to fetch a
 * list of chat messages related to a specific ticket ID. The function uses the `useQuery` hook from an
 * unspecified library to handle the data fetching. The query is performed by making a POST request to
 * a specific endpoint with the ticket ID provided. If the request is successful (status code 200
 */
export const useGetRecyclerTicketChatList = ({ ticketID }) => {
  return useQuery({
    queryKey: ['getRecyclerTicketChatList', ticketID],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.ticket}/${ticketID}/chats`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(ticketID),
    refetchOnMount: false, // Avoid unnecessary refetching on component mount
    staleTime: 0,

    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useRecyclerTicketStatusUpdate = async (data) => {
  const res = await axiosInstance.patch(`${endpoints.ticket}/${data?.ticketID}/status`, {
    status: data?.status,
    isRecycler: data?.isRecycler
  });
  return res;
};

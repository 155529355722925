import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Form } from 'formik';
import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useEffect } from 'react';

const UpdateEnterpriseOrganizationForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,

  touched,
  values,

  setFieldValue,

  btnName,

  enterpriseDetails,
  isLoadingOrgUpdate
}) => {
  const theme = useTheme();
  useEffect(() => {
    if (enterpriseDetails) {
      setFieldValue('EnterpriseName', enterpriseDetails.name ? enterpriseDetails.name : '');
      setFieldValue('EnterpriseWeb', enterpriseDetails.website ? enterpriseDetails.website : '');
    }
  }, [enterpriseDetails, setFieldValue]);
  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Organization Details
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.EnterpriseName && errors.EnterpriseName)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-email-login"> Enterprise Name *</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.EnterpriseName}
                    name="EnterpriseName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" Enterprise Name *"
                    placeholder="e.g. Example Pvt. Ltd."
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.EnterpriseName && errors.EnterpriseName && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.EnterpriseName}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.EnterpriseWeb && errors.EnterpriseWeb)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-email-login"> Enterprise Website Address</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email-login"
                    type="text"
                    value={values.EnterpriseWeb}
                    name="EnterpriseWeb"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label=" Enterprise Web"
                    inputProps={{}}
                    autoComplete="off"
                    placeholder="e.g. https://www.fosub.org"
                  />
                  {touched.EnterpriseWeb && errors.EnterpriseWeb && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.EnterpriseWeb}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isLoadingOrgUpdate}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingOrgUpdate ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `${btnName}`
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default UpdateEnterpriseOrganizationForm;

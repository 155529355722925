import { useQuery } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';

export const useGetJobUrlId = ({ id }) => {
  return useQuery({
    queryKey: ['getJobByUrlId', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.jobs}/findByJobId/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

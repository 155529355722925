import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { useTheme } from '@mui/system';
import { Form } from 'formik';
import React from 'react';
import { gridSpacing } from 'store/constant';
import DocumentUpload from 'ui-component/file-upload';
import { getSupportType } from 'utils/commonUtil';

const SupportForm = ({ errors, touched, handleChange, values, handleSubmit, setFieldValue, isLoadingGetSupport, handleClose }) => {
  const theme = useTheme();

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={6} md={6} sm={6} xs={12} className="select-status-filter">
                <InputLabel id="support-type-label">Select Support Type *</InputLabel>
                <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={Boolean(touched.supportType && errors.supportType)}>
                  <Select
                    labelId="support-type-label"
                    value={values?.supportType || ''}
                    onChange={handleChange}
                    label="Select Support Type *"
                    name="supportType"
                  >
                    <MenuItem value="Select Support Type" disabled>
                      Select Support Type
                    </MenuItem>
                    {getSupportType.map((type) => (
                      <MenuItem key={type.id} value={type.value}>
                        {type.supportType}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.supportType && errors.supportType && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.supportType}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12} className="select-machine-id inv-number select-machine">
                <InputLabel htmlFor="outlined-adornment-prefix"> Subject *</InputLabel>
                <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={Boolean(touched.subject && errors.subject)}>
                  <TextField
                    // placeholder="Subject"
                    variant="outlined"
                    type="text"
                    fullWidth
                    name="subject"
                    value={values?.subject}
                    onChange={handleChange}
                    // label="Subject *"
                    error={Boolean(touched.subject && errors.subject)}
                  />
                  {touched.subject && errors.subject && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.subject}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputLabel htmlFor="outlined-adornment-prefix">Message *</InputLabel>
                <FormControl
                  fullWidth
                  sx={{ ...theme.typography.customInput }}
                  // className="ticket_detailstextarea"
                  error={Boolean(touched.message && errors.message)}
                >
                  <OutlinedInput
                    multiline
                    rows={3}
                    fullWidth
                    variant="outlined"
                    name="message"
                    label="Message *"
                    value={values?.message}
                    onChange={handleChange}
                    // placeholder="Write your message"
                  />
                  {touched.message && errors.message && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* <Grid spacing={gridSpacing} sx={{ alignContent: 'center' }}> */}
                <DocumentUpload
                  {...{
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    setFieldValue
                  }}
                />
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              disabled={isLoadingGetSupport}
            >
              Send
              {isLoadingGetSupport && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#16B1FF',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </Button>
          </Box>
          <Button variant="outlined" type="reset" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Form>
    </>
  );
};

export default SupportForm;

import axiosInstance from 'utils/axiosInstance';
import { endpoints } from 'endpoints/endpoints';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { setToast, toastConfig } from 'utils/commonUtil';
export const UseOrganizationNewAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.organizations}/save`, data);
  return res;
};

/////List Api

export const useGetOrganizationNewList = ({ limit, sortOrder, sortField, page, orgName, status, orgTypes }) => {
  const organizationType = localStorage.getItem('orgType');
  const usersType = localStorage.getItem('userType');

  return useQuery({
    queryKey: ['getOrganizationNewData', limit, sortOrder, sortField, page, orgName, status, orgTypes, organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.organizations}/${page}/${limit}`, {
          // previous: previous,
          // current: current,
          // limit: limit,
          // first: first,
          // last: last,
          // field: sortField,
          // order: sortOrder
          //   searchParams: {
          //     enabled: status ? status : null,
          //     name: orgName || null
          //   },
          //   sortParams: {
          //     sortField: sortField,
          //     sortOrder: sortOrder
          //   }

          filters: {
            enabled: status ? status : null,
            name: orgName || null,
            types: orgTypes || null
          },
          sort: {
            sortField: sortField,
            sortOrder: sortOrder
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    staleTime: 0,
    enabled: Boolean(organizationType) && Boolean(usersType) && Boolean(localStorage.getItem('userJwtToken')),
    // enabled: Boolean(limit) && Boolean(sortField),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

////
//for Get By Details
export const useGetOrganizationNewId = ({ id }) => {
  return useQuery({
    queryKey: ['getOrganizationNewDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.organizations}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};
///
export const useOrganizationNewUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        // .patch(`${endpoints.locations}/${data.id}`, data)
        .put(`${endpoints.organizations}/${data.id}`, data)
        .then((res) => {
          // console.log('useLocationUpdate res', res);
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },

    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getOrganizationNewDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// export const UseOrganizationNewAdd = async (data) => {
//   const res = await axiosInstance.post(`${endpoints.organizations}/save`, data);
//   return res;
// };
///////Organizations Edit Null Check

export const useOrganizationNewNullCheck = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance.post(`${endpoints.organizations}/${data.id}/POC/check`, data);
      // .then((res) => {
      //   console.log('🚀 ~ .then ~ res:', res);
      //   // console.log('useLocationUpdate res', res);
      //   if (res?.status == 200) {
      //     toastConfig.type = 'success';
      //     setToast(toastConfig, res?.data.message);
      //   } else {
      //     toastConfig.type = 'error';
      //     setToast(toastConfig, error?.response?.data?.error);
      //   }

      //   return res;
      // })
      // .catch((error) => {
      //   toastConfig.type = 'error';
      //   setToast(toastConfig, error?.response?.data?.error);
      // });
    },

    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getOrganizationNewDetailsById'], type: 'active' });

      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// export const useOrganizationNewNullCheck = async (data) => {
//   const res = await axiosInstance.post(`${endpoints.organizations}/${data.id}/POC/check`, data);
//   return res;
// };
///// Status Update
export const useOrganizationNewStatusUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.organizations}/${data?.id}`, { status: data?.status })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getOrganizationNewData');
      queryClient.refetchQueries({
        queryKey: ['getOrganizationNewData'],
        type: 'active'
      });
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

export const useGetPermissionsOrganizationNewList = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getPermissionsOrg', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Organizations`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType) && Boolean(usersType),
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

export const useGetOrganizationNewAllList = () => {
  return useQuery({
    queryKey: ['getOrganizationNewAllListData'],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.organizations}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/* eslint-disable no-unused-vars */
// material-ui
// import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ routingList }) => {
  // Log menu items for debugging

  // Always include the dashboard group
  const dashboardGroup = menuItem?.items?.find((item) => item?.id === 'dashboard');

  // Filter other groups and their children based on routingList
  const filteredGroups = menuItem?.items
    ?.filter((item) => item.id !== 'dashboard') // Exclude the dashboard group (handled separately)
    ?.filter((item) => item.type === 'group') // Only include groups
    .map((group) => {
      // Filter children of the group based on checkId
      const filteredChildren = group?.children?.filter((child) => routingList?.includes(child.checkId)) || [];

      // Return the group only if it has valid children
      if (filteredChildren.length > 0) {
        return { ...group, children: filteredChildren };
      }

      // Exclude the group if it has no valid children
      return null;
    })
    .filter(Boolean); // Remove null groups

  // Combine the dashboard group and other filtered groups
  const finalMenuItems = dashboardGroup ? [dashboardGroup, ...filteredGroups] : filteredGroups;

  // Render the groups and their filtered children
  const navItems = finalMenuItems.map((item) => <NavGroup key={item.id} item={item} />);

  return <>{navItems}</>;
};

export default MenuList;

import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, FormControl, Grid } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import OrganizationSection from './OrganizationSection';
import UserSection from './UserSection';
import { useGetProfileById } from 'hooks/useProfileHooks';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const { data: profileDetails, isLoading: isLoadingProfile } = useGetProfileById();

  return (
    <>
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <Grid container spacing={1} justifyContent="flex-end" className="flex-start-new-btn">
        <Grid item lg={8} md={12} sm={12} xs={12} display={'flex'} justifyContent="flex-end">
          {/* , color: '#3f51b5' */}
          <div className="org-type-header">
            <div className="org-type-header-middle">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                <OrganizationSection className="fg" data={profileDetails?.organizationTypes} isLoading={isLoadingProfile} />
              </FormControl>

              {/* , color: '#3f51b5' */}
              <FormControl variant="standard" sx={{ m: 1, minWidth: 170 }}>
                <UserSection data={profileDetails?.types} isLoading={isLoadingProfile} />
              </FormControl>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* notification & profile */}
      <NotificationSection />
      <ProfileSection profileDetails={profileDetails} />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func
};

export default Header;

import { useQuery } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
// import { setToast, toastConfig } from 'utils/commonUtil';

/**
 * The useMachineModelAdd function sends a POST request to save data to a specific endpoint using
 * axiosInstance.
 * @param data - The `data` parameter in the `useMachineModelAdd` function likely contains the
 * information needed to save a machine model. This could include details such as the model name,
 * specifications, manufacturer information, and any other relevant data required to add a new machine
 * model.
 * @returns The function `useMachineModelAdd` is returning the response from the POST request made to
 * the `${endpoints.machineModal}/save` endpoint using `axiosInstance`.
 */
export const useMachineModelAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.machineModal}/save`, data);
  return res;
};

/**
 * The function useGetAllMachineModel fetches all machine models from an API based on the isActive
 * parameter.
 * @returns The `useGetAllMachineModel` function is returning a custom hook that uses the `useQuery`
 * hook from a library like React Query. This custom hook makes a GET request to a specific endpoint
 * (`endpoints.machineModal`) to fetch all machine models. If the request is successful (status code
 * 200), it returns the data received from the API response. If there is an error or the
 */
export const useGetAllMachineModel = ({ isActive }) => {
  return useQuery({
    queryKey: ['getallMachineModel'],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.machineModal}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(isActive),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

////
export const useDeleteMachineModel = async (id) => {
  const res = await axiosInstance.delete(`${endpoints.machineModal}/${id}`);
  return res;
};

/////machine Versions////

export const useMachineVersionAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.machineVersion}/save`, data);
  return res;
};

export const useGetAllMachineVersion = ({ isActive }) => {
  return useQuery({
    queryKey: ['getallMachineVersion'],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.machineVersion}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(isActive),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useDeleteMachineVersion = async (id) => {
  const res = await axiosInstance.delete(`${endpoints.machineVersion}/${id}`);
  return res;
};

//////// Settings Row//////
export const useRowSettingAdd = async (data) => {
  const res = await axiosInstance.post(`v1/rowSettings/save`, data);
  return res;
};

///Get All Row Listing

export const useGetAllRow = ({ isActive }) => {
  return useQuery({
    queryKey: ['getallRow'],
    queryFn: async () => {
      return await axiosInstance
        .get(`v1/rowSettings`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(isActive),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/// Delete Row

export const useDeleteRow = async (id) => {
  const res = await axiosInstance.delete(`v1/rowSettings/${id}`);
  return res;
};

///// machine All Settings
export const useMachineSettingsAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.machineSettings}/save`, data);
  return res;
};

export const useGetAllMachineSettings = ({ isActive }) => {
  return useQuery({
    queryKey: ['getallMachineSettings'],
    queryFn: async () => {
      return await axiosInstance
        .get(`v1/machines/settings`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(isActive),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useDeleteMachineSettings = async (id) => {
  const res = await axiosInstance.delete(`v1/machines/settings/${id}`);
  return res;
};

//////Machines Price
/// Update Machine Price
export const useMachinePriceUpdate = async (data) => {
  const res = await axiosInstance.put(`${endpoints.machinePrice}`, data);
  return res;
};

///Machines Price Details

export const useGetAllMachinePrice = ({ id }) => {
  return useQuery({
    queryKey: ['getallMachinePrice'],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints?.machinePrice}/${id}`)
        .then((res) => {
          // console.log('🚀 ~ .then ~ res:', res);
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

/////Update Price details

// export const useMachinePriceUpdate = () => {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: async (data) => {
//       return await axiosInstance
//         .put(`${endpoints.machinePrice}`, data)
//         .then((res) => {
//           if (res?.status == 200) {
//             toastConfig.type = 'success';
//             setToast(toastConfig, res?.data.message);
//           } else {
//             toastConfig.type = 'error';
//             setToast(toastConfig, error?.response?.data?.error);
//           }

//           return res;
//         })
//         .catch((error) => {
//           toastConfig.type = 'error';
//           setToast(toastConfig, error?.response?.data?.error);
//         });
//     },

//     onSuccess: ({ data }) => {
//       queryClient.refetchQueries({ queryKey: ['getMachinePriceById'], type: 'active' });
//       // queryClient.refetchQueries('getLocationDetailsById');
//       return data;
//     },
//     onError: ({ error }) => {
//       return error;
//     },
//     onSettled: ({ data }) => {
//       return data;
//     }
//   });
// };

// React Imports
import { forwardRef, useEffect, useRef, useState } from 'react';

// MUI Imports
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { Backdrop, Box, Chip, CircularProgress, Divider, FormControl, Grid, IconButton, Paper, Skeleton, Typography } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { useAddEnterpriseTicketChatCreate, useGetEnterpriseTicketById, useGetEnterpriseTicketChatList } from 'hooks/useTickets';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setToast, toastConfig } from 'utils/commonUtil';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  })
);
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const linkifyText = (text) => {
  // Regular expression to match URLs
  const urlPattern = /(https?:\/\/[^\s]+)/g;

  // Replace URLs with anchor tags
  return text.split(urlPattern).map((part, index) =>
    urlPattern.test(part) ? (
      <a key={index} href={part} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : (
      part
    )
  );
};

const MessageBubble = ({ message, userData }) => {
  const [messageShowMoreLess, setMessageShowMoreLess] = useState(80);
  const isOwnMessage = message?.userId === userData?.id;

  const toggleShowMoreMessage = () => {
    if (messageShowMoreLess === 80) {
      // Show all items
      setMessageShowMoreLess(message?.message?.length);
    } else {
      // Show only the initial items
      setMessageShowMoreLess(80);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isOwnMessage ? 'flex-end' : 'flex-start',
        marginBottom: 2,
        alignItems: 'flex-end'
      }}
    >
      {/* <Avatar alt={message.senderName} src={message.avatar} className="top-imgticket" sx={{ marginRight: 2 }} /> */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: isOwnMessage ? 'flex-end' : 'flex-start' }}>
        <Paper
          elevation={2}
          className="ticketchattxt"
          sx={{
            maxWidth: '60%',
            padding: 1,
            backgroundColor: isOwnMessage ? 'primary.main' : 'grey.300',
            color: isOwnMessage ? 'white' : 'black'
          }}
        >
          <Typography variant="body1">
            {/* {message?.message?.length > 80 ? message?.message.slice(0, messageShowMoreLess) : message.message} */}
            {message?.message?.length > 80 ? linkifyText(message?.message.slice(0, messageShowMoreLess)) : linkifyText(message.message)}
            {message?.message?.length > 80 && (
              <Typography
                component={'span'}
                variant="h6"
                onClick={toggleShowMoreMessage}
                sx={{
                  cursor: 'pointer'
                }}
              >
                {messageShowMoreLess === 80 ? '... Show More' : ' Show Less'}
              </Typography>
            )}
          </Typography>
        </Paper>
        <Typography variant="caption" sx={{ marginTop: 0.5 }}>
          {message?.userName} | {moment(message?.timestamp).format('DD-MM-yyyy , HH:mm')}
        </Typography>{' '}
      </Box>
    </Box>
  );
};
function useChatScroll(dep) {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep]);
  return ref;
}
const DialogsTransition = ({ open, setOpen, details }) => {
  const queryClient = useQueryClient();
  const [comments, setComments] = useState('');

  let userData = JSON.parse(localStorage.getItem('userData'));
  const location = useLocation();
  // Use URLSearchParams to extract the query parameters from the URL
  const queryParams = new URLSearchParams(location?.search);
  const chatTicketID = queryParams?.get('chatTicketID');
  details = {
    ...details,
    id: details.id ? details.id : chatTicketID
  };
  const { data: ticketDetails, isPending: isLoadingTicketDetails } = useGetEnterpriseTicketById({
    id: details?.id
  });
  const {
    data: allEnterpriseTicketChatList,
    isLoading: isAllTicketDataChatLoading,
    refetch
  } = useGetEnterpriseTicketChatList({
    ticketID: details?.id
  });
  const { mutate: addTicketChat, isPending: isLoadingTicketChat } = useMutation({
    mutationFn: useAddEnterpriseTicketChatCreate
  });

  const ref = useChatScroll(allEnterpriseTicketChatList?.data);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (ticketDetails?.id) {
      setComments('');
    }
  }, [ticketDetails?.id]);
  const handleSaveComment = () => {
    const data = {
      ticketId: ticketDetails?.id,
      message: comments,
      userName: userData?.firstName,
      userId: userData?.id,
      isEnterprise: userData?.organizationType === 'enterprise' ? true : false
    };
    addTicketChat(data, {
      onSuccess: (res) => {
        if (res?.status == 200) {
          queryClient.refetchQueries({ queryKey: ['getEnterpriseTicketChatList'], type: 'active' });
          setComments('');
        } else {
          toastConfig.type = 'error';
          setToast(toastConfig, 'Something went wrong!');
        }
      },
      onError: () => {
        // console.log('🚀 ~ handleSaveComment ~ error:', error);
        toastConfig.type = 'error';
        setToast(toastConfig, 'Something went wrong!');
      }
    });
  };

  return (
    <Dialog
      className="ticket_maindiglog"
      PaperProps={{
        sx: {
          height: '100vh',
          width: '380px',
          margin: '30px'
        }
      }}
      sx={{ display: 'flex', justifyContent: 'flex-end' }}
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      keepMounted
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0
        }}
        open={isAllTicketDataChatLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <DialogTitle id="alert-dialog-slide-title">
        <Grid container spacing={5} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            {isLoadingTicketDetails ? (
              <>
                <Skeleton variant="text" width={300} />
              </>
            ) : (
              <>
                <Typography
                  variant="h5"
                  component={'span'}
                  sx={{ marginTop: '12px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  Ticket : {ticketDetails?.id}{' '}
                  <Chip
                    size="small"
                    label={
                      ticketDetails?.status === 'In Review'
                        ? 'In Review'
                        : ticketDetails?.status === 'Rejected'
                        ? 'Rejected'
                        : ticketDetails?.status === 'Requested'
                        ? 'Requested'
                        : ticketDetails.status === 'Accepted'
                        ? 'Accepted'
                        : 'Resolved'
                    }
                    style={{
                      color: '#fff',
                      backgroundColor:
                        ticketDetails?.status === 'In Review'
                          ? '#dbc115'
                          : ticketDetails?.status === 'Rejected'
                          ? '#ff1744'
                          : ticketDetails?.status === 'Requested'
                          ? '#3d5afe'
                          : ticketDetails.status === 'Accepted'
                          ? '#ae30c2'
                          : '#30c42b'
                    }}
                  />
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <IconButton onClick={handleClose} color="error" className="ticket_closebtn">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className="tickets-modalnew" ref={ref}>
        {allEnterpriseTicketChatList?.data === undefined || allEnterpriseTicketChatList?.data?.length == 0 ? (
          <>
            <StyledGridOverlay>
              <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(24 31.67)">
                    <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                    <path
                      className="ant-empty-img-1"
                      d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                    />
                    <path
                      className="ant-empty-img-2"
                      d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                    />
                    <path
                      className="ant-empty-img-3"
                      d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                    />
                  </g>
                  <path
                    className="ant-empty-img-3"
                    d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                  />
                  <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                    <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                    <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                  </g>
                </g>
              </svg>
              <Box sx={{ mt: 1 }}>No data found!</Box>
            </StyledGridOverlay>
          </>
        ) : (
          <>
            {allEnterpriseTicketChatList?.data?.map((message, i) => (
              <MessageBubble message={message} key={i} userData={userData} />
            ))}
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions className="chat-refreashbtn">
        <HtmlTooltip title={'Refresh Chats'}>
          <IconButton position="end" color="primary" className="main-rfrshbtn" onClick={() => refetch()} title="Refresh Chat">
            <RefreshIcon />
          </IconButton>
        </HtmlTooltip>
        <FormControl fullWidth>
          <OutlinedInput
            autoFocus
            onKeyDown={(ev) => {
              if (ev.key === 'Enter') {
                handleSaveComment();
              }
            }}
            // multiline={comments?.length > 50 ? true : false}
            // minRows={comments?.length > 50 ? 5 : 2}
            multiline
            maxRows={4}
            placeholder="Comment"
            sx={{ borderRadius: 12, paddingLeft: 4, paddingRight: 4 }}
            fullWidth
            value={comments}
            id="icons-adornment-weight"
            onChange={(text) => setComments(text.target.value)}
            aria-describedby="icons-weight-helper-text"
            disabled={ticketDetails?.status === 'Resolved' ? true : ticketDetails?.status === 'Rejected' ? true : isLoadingTicketChat}
            endAdornment={
              <>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <IconButton
                    position="end"
                    color="primary"
                    onClick={handleSaveComment}
                    disabled={
                      ticketDetails?.status === 'Resolved' ? true : ticketDetails?.status === 'Rejected' ? true : isLoadingTicketChat
                    }
                  >
                    <SendOutlinedIcon />
                  </IconButton>
                  {isLoadingTicketChat && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: '#16B1FF',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                      }}
                    />
                  )}
                </Box>
              </>
            }
            inputProps={{
              'aria-label': 'weight'
            }}
          />
        </FormControl>
      </DialogActions>
    </Dialog>
  );
};

export default DialogsTransition;

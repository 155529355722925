import { Box, DialogActions, FormControl, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
// import { useTheme } from '@mui/material/styles';

const ViewTicketSkeleton = () => {
  //   const theme = useTheme();
  return (
    <>
      <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
        <Grid item xs={12}>
          <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
            <Skeleton variant="text" width={265} />
          </Typography>

          <Grid container spacing={gridSpacing}>
            {[1, 2, 3, 4].map((i) => (
              <Grid item lg={6} md={6} sm={6} xs={12} key={i}>
                <FormControl fullWidth>
                  <Skeleton
                    variant="text"
                    sx={{
                      width: {
                        xs: '70%',
                        sm: '60%',
                        md: '50%'
                      },
                      height: 20
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: '100%',
                      height: {
                        xs: 40,
                        sm: 50,
                        md: 60
                      },
                      borderRadius: 1
                    }}
                  />
                </FormControl>
              </Grid>
            ))}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormControl fullWidth>
                <Skeleton
                  variant="text"
                  sx={{
                    //    width: '100%',
                    height: 100
                  }}
                />
              </FormControl>
            </Grid>

            <Grid container item xs={12} sm={12}>
              <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
                <div style={{ padding: '10px 15px 15px' }} className="field-input-all">
                  <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
                    <Skeleton variant="text" width={265} />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
            <DialogActions>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Skeleton variant="text" width={150} height={90} />
              </Box>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Skeleton variant="text" width={150} height={90} />
              </Box>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Skeleton variant="text" width={150} height={90} />
              </Box>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewTicketSkeleton;

import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import 'yup-phone';
import SubscriptionForm from '../subscription-form';
import { useState, useEffect } from 'react';
import FormView from 'ui-component/cards/Skeleton/FormView';
import * as Yup from 'yup';
import { useSubscriptionsAdd } from 'hooks/useSubscriptionsHooks';
import { useMutation } from '@tanstack/react-query';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useNavigate } from 'react-router-dom';
const SubscriptionAdd = () => {
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();

  const { mutate: addSubscription, isPending: isSubscriptionsLoading } = useMutation({
    mutationFn: useSubscriptionsAdd
  });

  const initials = {
    title: '',
    price: '',
    interval: '1 Month',
    description: '',
    localUser: '',
    globalUser: '',
    features: [''],
    isPublic: false,
    planColor: {
      hex: '#3f10a1',
      rgb: {
        r: 62.905096778835826,
        g: 16.019502074688795,
        b: 160.8625,
        a: 1
      },
      hsv: {
        h: 259.4219653179191,
        s: 90.04149377593362,
        v: 63.083333333333336,
        a: 1
      }
    },
    noOfAds: ''
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    price: Yup.number().typeError('Price must be a valid number').positive('Price must be positive').required('Price is required'),
    interval: Yup.string().oneOf(['1 Month', '4 Months', '6 Months'], 'Invalid interval selected').required('Interval is required'),
    description: Yup.string().required('Description is required'),
    localUser: Yup.number()
      .typeError('Machine Specific User Count must be an integer')
      .integer('Machine Specific User Count must be an integer')
      .min(1, 'Machine Specific User Count must be at least 1')
      .required('Machine Specific User Count is required'),
    globalUser: Yup.number()
      .typeError('Global User Count must be an integer')
      .integer('Global User Count must be an integer')
      .min(1, 'Global User Count must be at least 1')
      .required('Global User Count is required'),
    features: Yup.array().of(Yup.string().required('Feature cannot be empty')).min(1, 'At least one feature is required')
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading ? (
        <FormView />
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add Subscription"
            obj={{ title: 'Add Subscription', title2: 'Add Subscription', url: '/dashboard/subscriptions' }}
          />
          <MainCard>
            <Formik
              initialValues={initials}
              validationSchema={validationSchema}
              onSubmit={(value, { resetForm }) => {
                const submitData = {
                  title: value?.title,
                  description: value?.description,
                  isPublic: value?.isPublic,
                  noUsers: {
                    machineSpecificUser: value?.localUser,
                    globalUser: value?.globalUser
                  },
                  price: {
                    amount: value?.price,
                    interval: value?.interval
                  },
                  features: value?.features,
                  colorCode: value?.planColor,
                  noOfAdvertisements: value?.noOfAds
                };

                addSubscription(submitData, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.message);
                      // queryClient.refetchQueries('getEnterpriseData');
                      resetForm({});
                      navigate(-1);
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {(args) => <SubscriptionForm {...args} isSubscriptionsLoading={isSubscriptionsLoading} />}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default SubscriptionAdd;

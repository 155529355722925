/* eslint-disable react-hooks/rules-of-hooks */

import { toast } from 'react-toastify';
import axios from 'axios';

export const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
  type: 'info',
  progress: undefined
};

export const setToast = (config, message) => {
  toast(message, config);
};
// export const roundOff = (num) => {
//   return +(Math.round(num + 'e+2') + 'e-2');
// };

// export const roundOffTwo = (num) => {
//   return +parseFloat(num).toFixed(2);
// };
export const roundOffTwo2 = (num) => {
  return parseFloat(num).toFixed(2);
};

// export const handleDownloadInvoice = ({ id, invoiceId }) => {
//   const link = document.createElement('a');
//   link.href = `https://shredbox.vercel.app/api/v1/invoices/pdf/${id}`;
//   //link.href = `https://firebasestorage.googleapis.com/v0/b/shredbox-52385.appspot.com/o/invoices%2FINV-000017-Raised%20RSg1lisvoUkWWqvaNc16.pdf?alt=media&token=bf3450eb-7a3b-4481-89ca-daea46685e0c`;
//   link.target = '__blank';
//   link.setAttribute('download', `invoice_${invoiceId}.pdf`);
//   document.body.appendChild(link);
//   link.click();
//   link.remove();
//   document.body.removeChild(link);
//   // const url = `https://shredbox.vercel.app/api/v1/invoices/pdf/${id}`;
//   // const fileName = `invoice_${invoiceId}.pdf`;

//   // fetch(url)
//   //   .then((response) => response.blob())
//   //   .then((blob) => {
//   //     const link = document.createElement('a');
//   //     const objectUrl = window.URL.createObjectURL(blob);
//   //     link.href = objectUrl;
//   //     link.target = '__blank';
//   //     link.setAttribute('download', fileName);
//   //     document.body.appendChild(link);
//   //     link.click();
//   //     link.remove();
//   //     window.URL.revokeObjectURL(objectUrl); // Cleanup
//   //   })
//   //   .catch((error) => console.error('Download failed:', error));
// };

export const handleDownloadInvoice = async ({ id }) => {
  try {
    await axios
      .get(`https://shredbox.vercel.app/api/v1/invoices/pdf/${id}`)
      .then((res) => {
        if (res.data.status === true) {
          window.open(res.data.data, '_blank');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  } catch (error) {
    console.error('Download failed:', error);
  }
};

// export const handleDownloadInvoice = async ({ id, invoiceId }) => {
//   try {
//     const response = await axios.get(`https://shredbox.vercel.app/api/v1/invoices/pdf/${id}`, {
//       responseType: 'blob'
//     });

//     // Create a Blob URL from the response data
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', `invoice_${invoiceId}.pdf`); // Specify the file name
//     link.target = '_blank'; // Ensure it opens in a new tab
//     document.body.appendChild(link);
//     link.click();

//     // Cleanup
//     link.remove();
//     window.URL.revokeObjectURL(url); // Free up memory
//   } catch (error) {
//     console.error('Download failed:', error);
//   }
// };
export const getDomainName = (url) => {
  try {
    const { host } = new URL(url);
    return host; // e.g., "localhost:3000"
  } catch {
    return url; // Fallback for invalid URLs
  }
};

/// Select Support Type
export const getSupportType = [
  {
    id: 1,
    supportType: 'Machine Related Issues',
    value: 'Machine Related Issues'
  },
  {
    id: 2,
    supportType: 'Subscription Package',
    value: 'Subscription Package'
  },
  {
    id: 3,
    supportType: 'Organization Related Issues',
    value: 'Organization Related Issues'
  },
  {
    id: 4,
    supportType: 'Invoice Related Issues',
    value: 'Invoice Related Issues'
  },
  {
    id: 5,
    supportType: 'Jobs Related Issues',
    value: 'Jobs Related Issues'
  },
  {
    id: 6,
    supportType: 'Ticket Related Issues',
    value: 'Ticket Related Issues'
  },
  {
    id: 7,
    supportType: 'Employees Related Issues',
    value: 'Employees Related Issues'
  },
  {
    id: 8,
    supportType: 'Others',
    value: 'Others'
  }
];

import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';

import * as Yup from 'yup';
import 'yup-phone';
import { useRetailerAdd } from 'hooks/useRetailerHooks';
import { useMutation } from '@tanstack/react-query';
import { setToast, toastConfig } from 'utils/commonUtil';
import ResellerRetailerForm from '../form';

const ResellerAddRetailer = () => {
  const [isLoading, setLoading] = useState(true);
  const { mutate: addRetailer, isPending: isLoadingRetailer } = useMutation({
    mutationFn: useRetailerAdd
  });
  const phoneRegex = RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
  // const queryClient = useQueryClient();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <>
          <FormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add Retailer Organization"
            obj={{ title: 'All Retailer Organizations', title2: 'Add Retailer Organizations', url: '/dashboard/reseller/retailer' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                // associateEmpName: '',
                associateEmpEmail: '',
                associateEmpPhone: '',
                // associateEmpOfficePhone: '',
                associateEmpAdd: '',

                EmpFullAddress: '',
                EmpCity: '',
                EmpState: '',
                EmpCountry: '',
                EmpPostal_Code: '',

                // SecondaryEmpName: '',
                SecondaryEmpEmail: '',
                SecondaryEmpPhone: '',
                // SecondaryEmpOfficePhone: '',
                SecondaryEmpAdd: '',

                RetailerName: '',
                // Retailercode: '',
                RetailerWeb: '',
                // RetailerNotes: '',

                isChecked: false,
                submit: null,

                title: '',
                firstName: '',
                lastName: '',
                designation: '',

                secondaryTitle: '',
                secondaryFirstName: '',
                secondaryLastName: '',
                secondaryDesignation: '',
                locationPickUp: [],
                placeId: '',
                latitude: '',
                longitude: ''
              }}
              validationSchema={Yup.object().shape({
                RetailerName: Yup.string()
                  .max(50, 'Retailer Name must be at most 50 characters')
                  .required('Retailer Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Retailer Name should not be in any special character'),
                // Retailercode: Yup.string().max(30, 'Must be of 30 characters').trim().required('Retailer Code is required'),
                // RetailerPhone: Yup.string() // Validates for numerical value
                //   .phone('US', true, 'Must be a valid phone number. ex.: +1 212-456-7890'),

                RetailerWeb: Yup.string().url('Please enter a valid Retailer Website Address'),

                associateEmpEmail: Yup.string().email('Invalid email address').required('Employee Email is required'),

                associateEmpPhone: Yup.string()
                  .required('Employee Phone is required')

                  .matches(phoneRegex, 'Employee Phone is not valid')
                  .max(15, 'Employee Phone maximum number should be of 15 characters')
                  .trim(),

                associateEmpAdd: Yup.string().max(150, 'Must be of 150 characters').trim().required('Employee Address is required'),

                SecondaryEmpPhone: Yup.string()
                  .required('Employee Phone is required')

                  .matches(phoneRegex, 'Employee Phone is not valid')
                  .max(15, 'Employee Phone maximum number should be of 15 characters')
                  .trim(),

                SecondaryEmpAdd: Yup.string()
                  .required('Employee Address is required')
                  .max(150, 'Employee Address must be of 30 characters')
                  .trim(),
                SecondaryEmpEmail: Yup.string().required('Employee Email is required').email('Invalid email address'),

                title: Yup.string().required('Prefix is required').trim(),
                firstName: Yup.string()
                  .max(50, 'Employee First Name must be at most 50 characters')
                  .required('Employee First Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not be in any special character'),

                lastName: Yup.string()
                  .max(50, 'Employee Last Name must be at most 50 characters')
                  .required('Employee Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not be in any special character'),

                designation: Yup.string()
                  .max(50, 'Employee Title must be of 50 characters')
                  .required('Employee Title is required')

                  .trim(),

                secondaryTitle: Yup.string().required('Prefix is required').trim(),
                secondaryFirstName: Yup.string()
                  .max(50, 'Employee First Name must be at most 50 characters')
                  .required('Employee First Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not be in any special character'),

                secondaryLastName: Yup.string()
                  .max(50, 'Employee Last Name must be at most 50 characters')
                  .required('Employee Last Name is required')
                  .trim()
                  .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')

                  .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not be in any special character'),

                secondaryDesignation: Yup.string()
                  .required('Employee Title is required')
                  .max(50, 'Employee Title must be of 50 characters')
                  .trim(),
                locationPickUp: Yup.array().min(1, 'At least one location is required').required('Select Location is required ')
              })}
              onSubmit={async (value, { resetForm }) => {
                const _location = value.locationPickUp
                  .filter((item) => item?.id !== '')
                  .map((item) => ({
                    value: item.id,
                    isPrimary: item?.isPrimary ? item?.isPrimary : false // Mark the first location as primary, adjust this logic if needed
                    // You can include other properties from `item` if necessary
                  }));
                const submitData = {
                  name: value?.RetailerName.trim(),
                  website: value?.RetailerWeb,
                  isChecked: value?.isChecked,
                  mainPOC: {
                    firstName: value?.firstName.trim(),
                    lastName: value?.lastName.trim(),
                    title: value?.designation,
                    prefix: value?.title,
                    email: value?.associateEmpEmail.trim(),
                    address: value?.associateEmpAdd.trim(),
                    phone: value?.associateEmpPhone.trim()
                  },
                  secondaryPOC: {
                    firstName: value?.secondaryFirstName.trim(),
                    lastName: value?.secondaryLastName.trim(),
                    title: value?.secondaryDesignation,
                    prefix: value?.secondaryTitle,
                    email: value?.SecondaryEmpEmail.trim(),
                    address: value?.SecondaryEmpAdd.trim(),
                    phone: value?.SecondaryEmpPhone.trim()
                  },
                  locations: _location
                };

                addRetailer(submitData, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.success);
                      // queryClient.refetchQueries('getRetailerData');
                      resetForm({});
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched }) => (
                <ResellerRetailerForm
                  btnName="Add"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  isLoadingRetailer={isLoadingRetailer}
                  setFieldValue={setFieldValue}
                  id={''}
                  setTouched={setTouched}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default ResellerAddRetailer;

import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, Skeleton } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { gridSpacing } from 'store/constant';
import { useLocation } from 'react-router';
import ViewTicketSkeleton from 'ui-component/cards/Skeleton/ViewTicketSkeleton';
import { useGetOrganizationNewId } from 'hooks/useOrganizationNewHooks';

const ViewOrganization = ({ open, setOpen, details }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const viewOrgID = queryParams?.get('viewOrgID');

  details = {
    ...details,
    id: details?.id ? details?.id : viewOrgID
  };

  const { data: orgDetails, isPending: isLoadingOrgDetails } = useGetOrganizationNewId({
    id: details?.id
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth={false}
      fullScreen={false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title" variant="span" sx={{ textTransform: 'capitalize', position: 'relative' }}>
        {isLoadingOrgDetails ? <Skeleton variant="text" width={200} /> : `View Organization: ${orgDetails?.name}`}
        {!isLoadingOrgDetails && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500]
            })}
          >
            <CloseIcon color="error" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>
        {isLoadingOrgDetails ? (
          <ViewTicketSkeleton />
        ) : (
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12} className="support_ticket_view_new">
              <Section
                title="Organization Details"
                content={[
                  { label: 'Organization Name', value: orgDetails?.name || 'N.A.' },
                  { label: 'Organization Website', value: orgDetails?.website || 'N.A.' },
                  orgDetails?.serviceRadius && {
                    label: 'Service Radius',
                    value: orgDetails?.serviceRadius?.value
                      ? `${orgDetails?.serviceRadius?.value} ${orgDetails?.serviceRadius?.unit}`
                      : 'N.A.'
                  }
                ]}
              />
              <Section
                title="Primary Contact"
                content={[
                  {
                    label: 'Name',
                    value:
                      orgDetails?.mainPOC?.firstName && orgDetails?.mainPOC?.lastName
                        ? `${orgDetails?.mainPOC?.prefix} ${orgDetails?.mainPOC?.firstName} ${orgDetails?.mainPOC?.lastName}`
                        : 'N.A.'
                  },
                  { label: 'Designation', value: orgDetails?.mainPOC?.title || 'N.A.' },
                  { label: 'Email', value: orgDetails?.mainPOC?.email || 'N.A.' },
                  { label: 'Phone', value: orgDetails?.mainPOC?.phone || 'N.A.' },
                  { label: 'Address', value: orgDetails?.mainPOC?.address || 'N.A.' },
                  { label: 'RFID Code', value: orgDetails?.mainPOC?.authenticationInfo?.rfidCode || 'N.A.' },
                  { label: 'RFID Pin', value: orgDetails?.mainPOC?.authenticationInfo?.rfidPIN || 'N.A.' }
                ]}
              />
              <Section
                title="Secondary Contact"
                content={[
                  {
                    label: 'Name',
                    value:
                      orgDetails?.secondaryPOC?.firstName && orgDetails?.secondaryPOC?.lastName
                        ? `${orgDetails?.secondaryPOC?.prefix} ${orgDetails?.secondaryPOC?.firstName} ${orgDetails?.secondaryPOC?.lastName}`
                        : 'N.A.'
                  },
                  { label: 'Designation', value: orgDetails?.secondaryPOC?.title || 'N.A.' },
                  { label: 'Email', value: orgDetails?.secondaryPOC?.email || 'N.A.' },
                  { label: 'Phone', value: orgDetails?.secondaryPOC?.phone || 'N.A.' },
                  { label: 'Address', value: orgDetails?.secondaryPOC?.address || 'N.A.' },
                  { label: 'RFID Code', value: orgDetails?.secondaryPOC?.authenticationInfo?.rfidCode || 'N.A.' },
                  { label: 'RFID Pin', value: orgDetails?.secondaryPOC?.authenticationInfo?.rfidPIN || 'N.A.' }
                ]}
              />
              <AddressSection title="Address" addresses={orgDetails?.locations || 'N.A.'} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

// Reusable Section Component
const Section = ({ title, content }) => (
  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px' }}>
    <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
        {title}
      </Typography>
    </div>
    <div style={{ padding: '10px 15px' }}>
      {content?.filter(Boolean).map(({ label, value }, index) => (
        <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
          <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
            {label}:
          </Typography>
          <span style={{ fontSize: '15px' }}>{value}</span>
        </div>
      ))}
    </div>
  </div>
);

// Reusable Address Section
const AddressSection = ({ title, addresses }) => (
  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px' }}>
    <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
        {title}
      </Typography>
    </div>
    <Grid container spacing={gridSpacing} sx={{ padding: '10px 15px' }}>
      {addresses?.map((item, index) => (
        <Grid item lg={6} key={index}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}>
            <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>Address Line {index + 1}</div>
            <div style={{ padding: '10px' }}>
              <Typography variant="h6" sx={{ margin: 0 }}>
                {item?.details?.name}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: '4px' }}>
                {item?.details?.address?.fullAddress}
              </Typography>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  </div>
);

export default ViewOrganization;

// This file consists all the endpoints
export const endpoints = {
  auth: '', /// authentication endpoint
  organizations: 'v1/organizations', // / categories endpoint /
  users: 'v1/users',
  roles: 'v1/roles',
  machines: 'v1/machines',
  recylers: 'v1/recyclers',
  enterprises: 'v1/enterprises',
  retailers: 'v1/retailers',
  locations: 'v1/locations',
  jobs: 'v1/jobs',
  ticket: 'v1/tickets',
  invoice: 'v1/invoices',
  ///v1
  machineModal: 'v1/machines/models',
  location: 'v1/locations',
  machineVersion: 'v1/machines/versions',
  enterprise: 'v1/enterprises',
  resellers: 'v1/resellers',
  recycler: 'v1/recyclers',
  machineSettings: 'v1/machines/settings',
  machinePrice: 'v1/machines/price',
  permissionsScreen: 'v1/permissions/screen',
  permissions: 'v1/permissions',
  subscriptions: 'v1/subscriptions',
  faqs: 'v1/faqs',
  getsupportTicket: 'v1/support/tickets'
};

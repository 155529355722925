import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Button, Chip, CircularProgress, Stack, Typography } from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  })
);
export const TooltipStatus = ({ title, label, color, onStatusUpdate, isLoading, backgroundColor }) => {
  return (
    <>
      <HtmlTooltip
        title={
          <>
            <Typography
              subtitle1="h2"
              component={'p'}
              sx={{
                alignItems: 'center'
              }}
            >
              <p style={{ textAlign: 'center' }}>{title}</p>
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                mt: 1,
                mb: 1,
                justifyContent: 'center'
              }}
            >
              <Button variant="outlined" style={{ padding: '2px 10px' }} onClick={onStatusUpdate}>
                {isLoading ? (
                  <>
                    <CircularProgress
                      sx={{
                        color: '#6fd74b',
                        height: 5,
                        width: 5
                      }}
                    />
                  </>
                ) : (
                  <>
                    <DoneOutlinedIcon /> Yes
                  </>
                )}
              </Button>
            </Stack>
          </>
        }
      >
        <Chip
          className="active-inactive-button"
          label={label}
          color={color}
          style={{
            color: '#fff',
            backgroundColor: backgroundColor
          }}
          size="small"
        />
      </HtmlTooltip>
    </>
  );
};

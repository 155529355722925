import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Divider } from '@mui/material';
import * as Yup from 'yup'; // Import Yup for validation
import { useSelector, useDispatch } from 'react-redux';
import { useGetPermissionData } from 'hooks/useControlPanelHooks';
import SearchPermisionForm from 'ui-component/search-permission-form';
import ModifyPermission from './modify-permission';

function UserPermissionControl({ routeList, userTypes, orgTypes }) {
  const { user, org, route } = useSelector((state) => state.controlPanelReducer); // Access Redux state
  const dispatch = useDispatch();

  const { data: permissionList, isLoading: isLoadingPermissionList } = useGetPermissionData({
    orgType: org === 'Service_Provider' ? 'ServiceProvider' : org !== 'Super_Admin' ? org : 'SuperAdmin',
    userType: user === 'Main POC' ? 'POC' : 'POC',
    screen: route
  });

  // Validation schema for form fields
  const validationSchema = Yup.object().shape({
    user: Yup.string().required('User Type is required'),
    org: Yup.string().required('Organization Type is required'),
    screen: Yup.string().required('Screen Type is required')
  });

  const handleFormSubmit = (values) => {
    dispatch({ type: 'SET_USER', payload: values?.user });
    dispatch({ type: 'SET_ORG', payload: values?.org });
    dispatch({ type: 'SET_ROUTE', payload: values?.screen });
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          user: user || '',
          org: org || '',
          screen: route || ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(args) => (
          <SearchPermisionForm
            {...args}
            showUserField={true}
            showOrgaizationField={true}
            showScreenField={true}
            routeList={routeList}
            orgTypes={orgTypes}
            userTypes={userTypes}
          />
        )}
      </Formik>

      <Divider sx={{ my: 2 }} />

      <ModifyPermission
        addPermissionList={permissionList?.add || []}
        editPermissionList={permissionList?.edit || []}
        filtersPermissionList={permissionList?.view?.filters || []}
        modalPermissionList={permissionList?.modal || []}
        tableHeadersPermissionList={permissionList?.view?.tableHeaders || []}
        showAdd={permissionList?.showAdd || false}
        showEdit={permissionList?.showEdit || false}
        showView={permissionList?.showView || false}
        isLoadingPermissionList={isLoadingPermissionList}
      />
    </Fragment>
  );
}

// Prop Validation
UserPermissionControl.propTypes = {
  routeList: PropTypes.arrayOf(PropTypes.string), // Array of route names as strings
  userTypes: PropTypes.arrayOf(PropTypes.string), // Array of user types as strings
  orgTypes: PropTypes.arrayOf(PropTypes.string) // Array of organization types as strings
};

// Default Props
UserPermissionControl.defaultProps = {
  routeList: [],
  userTypes: [],
  orgTypes: []
};

export default UserPermissionControl;
